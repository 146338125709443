import React, { useState, Fragment } from "react";
import { Form } from '@ant-design/compatible';

import { Row, Col, Radio, Checkbox, Button, DatePicker } from "antd";
import { TextBox } from "../../../general/TextBox";
//import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { TextArea } from "../../../general/TextAreaComponent";
import { CustomButton } from '../../../general/CustomButton';
import { Dropdown } from '../../../general/Dropdown';
import { getMomentDateForUIReadOnly, getCurrentDateForBackend, showToasterMessage, getMomentDateForUI } from '../../../../utils';
import { CONSTANTS } from '../../../../static/constants';
import ReactHtmlParser from 'react-html-parser';
import { LogoComponent } from '../../../general/LogoComponent';
import config from '../../../../config/environmentConfig';
import find from "lodash.find";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const Register = (props) => {
    const { intl, formSetting = {}, companyInfo } = props;
    const [gridApi, updateGridApi] = useState({});
    const onGridReady = (params) => {
        updateGridApi(params.api);
        params.api.sizeColumnsToFit();
    }

    let gradeArr = [];

    ((formSetting.gradeValidations && Object.keys(formSetting.gradeValidations).length) ?  Object.keys(formSetting.gradeValidations) : [1,2,3,4,5,6,7,8,9,10,11,12]).map((i) => {
        let d = {};
        d.gradeValidation = Number(i);
        d.gradefee = formSetting.gradeFees ? formSetting.gradeFees[d.gradeValidation] : 0;
        gradeArr.push(d);
        return gradeArr;
    });
    
    const getTotalGradeFees = ()=>{
        let total = 0;
        gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode)=>{
            let { data } = rowNode;
            if(!data.isInvalid && data.gradefee)
            total =  total + Number(data.gradefee);
        });
        return total;
    }
    const save = (e) => {
        e.preventDefault();
        let isRequiredFiledMissing = false;
        let isRequiredQuestionMissing = false;
        let studentList = [];
        let isInvalidGrade = false;
        gridApi && gridApi.forEachNode && gridApi.forEachNode((rowNode, index) => {
            let { data } = rowNode;

            if (data.invalidGrade) {
                isInvalidGrade = true
            }
            else if (props.registrationType === 'student' && (!data.firstName || !data.lastName || !data.grade || !data.dob || !data.allergies)) {
                isRequiredFiledMissing = true;
            } else {
                studentList.push({
                    relationshipId: props.relationshipId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    gender: data.gender,
                    grade: data.grade,
                    allergies: data.allergies,
                    dob: data.dob ? getMomentDateForUIReadOnly({ date: data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''

                });
            }
        });

        if (isInvalidGrade) {
            return showToasterMessage({
                messageType: 'error', description: 'Seats are full for the selected grade. Your student will be on the waitlist. Please fill out the form.'
            });
        }

        if ( props.formSetting.collectStudentInfo && props.registrationType === 'student' && !studentList.length) {
            return showToasterMessage({
                messageType: 'error', description: 'Please provide required student information'
            });
        }

        if (isRequiredFiledMissing) {
            return showToasterMessage({
                messageType: 'error', description: 'Student required field is missing'
            });
        }

        (props.formSetting.questionList || []).forEach((ques) => {
            if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
                isRequiredQuestionMissing = true;
            }
        })

        if (isRequiredQuestionMissing) {
            return showToasterMessage({
                messageType: 'error', description: 'Complete all required question(s)'
            });
        }

        if (props.addressRequired && (!props.streetAddress1 || !props.cityName || !props.stateName || !props.zipCode)) {
            return showToasterMessage({
                messageType: 'error', description: 'Address required field is missing'
            });
        }

        const modalData = {
            modalBody: 'Are you sure you want to save?',
            handleSubmit: () => {
                let contactList = [];
                let boLocationList = [];
                if (props.fatherFirstName) {
                    contactList.push({
                        firstName: props.fatherFirstName,
                        lastName: props.fatherLastName,
                        workPhone: props.fatherMobile,
                        emailAddress: props.fatherEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'male',
                        contactType: "Customer",
                        isPrimaryContact: 1
                    });
                }
                if (props.motherFirstName) {
                    contactList.push({
                        firstName: props.motherFirstName,
                        lastName: props.motherLastName,
                        workPhone: props.motherMobile,
                        emailAddress: props.motherEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'female',
                        contactType: "Customer",
                    });
                }

                if (props.streetAddress1 && props.cityName && props.stateName && props.zipCode) {
                    boLocationList.push({
                        streetAddress1: props.streetAddress1,
                        streetAddress2: props.streetAddress2,
                        cityName: props.cityName,
                        countryId: props.countryId,
                        countryName: props.countryName,
                        stateName: props.stateName,
                        zipCode: props.zipCode,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                        relationshipId: props.relationshipId,
                        isDefault: 1
                    })
                }

                let registrationData = {
                    customerId: props.customerId || 0,
                    registrationFormSettingId: props.registrationFormSettingId,
                    relationshipId: props.relationshipId,
                    fatherFirstName: props.fatherFirstName,
                    fatherLastName: props.fatherLastName,
                    fatherEmail: props.fatherEmail,
                    fatherMobile: props.fatherMobile,

                    motherFirstName: props.motherFirstName,
                    motherLastName: props.motherLastName,
                    motherEmail: props.motherEmail,
                    motherMobile: props.motherMobile,

                    emergencyContactName: props.emergencyContactName,
                    emergencyPhone: props.emergencyPhone,
                    studentList: studentList,
                    amount: Number.isNaN(getTotalGradeFees()) ? 0 : getTotalGradeFees(),
                    questionList: JSON.stringify(props.formSetting.questionList),
                    isOrgMember: 1,
                    registrationType: props.formSetting.registrationType
                }

                let customerPayload = {
                    companyName: props.fatherFirstName + (props.fatherLastName ? ' ' + props.fatherLastName : ''),
                    boContactList: contactList,
                    boLocationList: boLocationList,
                    customerId: props.customerId || 0,
                    relationshipId: props.relationshipId,
                    customerType: 'individual',
                    studentRegistrationList: [registrationData],
                    paymentTermId: formSetting.paymentTermId,
                    isOrgMember: 1,
                    membershipOptionId: props.membershipOption,
                    registrationType: props.formSetting.registrationType
                }
                props.saveRegistrationFound(customerPayload, props)
            }
        };
        props.pushModalToStack(modalData);
    }
    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <span>First Name<span className="required">*</span></span>;
            },
            field: "firstName",
            colId: 'firstName_1',
            width: 130,
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <span>Last Name<span className="required">*</span></span>;
            },
            field: "lastName",
            resizable: true,
            editable: true,
            width: 130,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <span>Grade<span className="required">*</span></span>;
            },
            field: "grade",
            resizable: true,
            editable: true,
            suppressMovable: true,
            width: 100,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return {
                    lastColumnCell: false,
                    items: gradeArr,
                    optionKeyName: 'gradeValidation',
                    valueKeyName: 'gradeValidation',
                    onSelect: (selectedValue) => {
                        if (obj.node.data.grade) {
                            let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
                            totalStudentCountByGrade[obj.node.data.grade] = Number(totalStudentCountByGrade[obj.node.data.grade]) - 1;
                            props.updateState({
                                totalStudentCountByGrade
                            });
                        }
                        obj.node.data.gradefee = null;
                        obj.node.setDataValue(obj.colDef.field, null);
                        const selectedObj = find(gradeArr, { gradeValidation: selectedValue });
                        if (formSetting.gradeLevelValidation) {
                            console.log("formseats = ",(Number((formSetting.gradeValidations || {})[1] || 0) + Number((formSetting.gradeValidations || {})[2] || 0)), "student occupied seats= ",(Number((props.totalStudentCountByGrade || {})[1] || 0) + Number((props.totalStudentCountByGrade || {})[2] || 0)));
                            let isInvalid = false;
                            if ((selectedValue === 1 || selectedValue === 2) && (Number((formSetting.gradeValidations || {})[1] || 0) + Number((formSetting.gradeValidations || {})[2] || 0)) <= (Number((props.totalStudentCountByGrade || {})[1] || 0) + Number((props.totalStudentCountByGrade || {})[2] || 0))) {
                                isInvalid = true;
                            } else if ((selectedValue === 3 || selectedValue === 4 || selectedValue === 5) && (Number((formSetting.gradeValidations || {})[3] || 0) + Number((formSetting.gradeValidations || {})[4] || 0) + Number((formSetting.gradeValidations || {})[5] || 0)) <= (Number((props.totalStudentCountByGrade || {})[3] || 0) + Number((props.totalStudentCountByGrade || {})[4] || 0) + Number((props.totalStudentCountByGrade || {})[5] || 0))) {
                                isInvalid = true;
                            } else if ((selectedValue === 6 || selectedValue === 7 || selectedValue === 8) && (Number((formSetting.gradeValidations || {})[6] || 0) + Number((formSetting.gradeValidations || {})[7] || 0) + Number((formSetting.gradeValidations || {})[8] || 0)) <= (Number((props.totalStudentCountByGrade || {})[6] || 0) + Number((props.totalStudentCountByGrade || {})[7] || 0) + Number((props.totalStudentCountByGrade || {})[8] || 0))) {
                                isInvalid = true;

                            } else if ((selectedValue === 9 || selectedValue === 10 || selectedValue === 11 || selectedValue === 12) && (Number((formSetting.gradeValidations || {})[9] || 0) + Number((formSetting.gradeValidations || {})[10] || 0) + Number((formSetting.gradeValidations || {})[11] || 0) + Number((formSetting.gradeValidations || {})[12] || 0)) <= (Number((props.totalStudentCountByGrade || {})[9] || 0) + Number((props.totalStudentCountByGrade || {})[10] || 0) + Number((props.totalStudentCountByGrade || {})[11] || 0) + Number((props.totalStudentCountByGrade || {})[12] || 0))) {
                                isInvalid = true;

                            }
                            if (isInvalid) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Seats are full for the selected grade. Your student will be on the waitlist. Please fill out the form.'
                                });

                            } else {
                                obj.node.data.invalidGrade = false;
                                obj.node.data.gradefee = selectedObj.gradefee;
                                obj.node.setDataValue(obj.colDef.field, selectedValue);
                                obj.node.setDataValue("gradefee", selectedObj.gradefee);
                                obj.node.data.grade = selectedValue;
                                let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
                                totalStudentCountByGrade[selectedValue] = Number(totalStudentCountByGrade[selectedValue]) + 1;
                                props.updateState({
                                    totalStudentCountByGrade
                                });

                            }

                        }
                    },
                    allowClear: true,
                    onClear: () => {
                        //obj.node.setDataValue(obj.colDef.field, undefined);
                    },
                }
            },
            // valueGetter: (obj) =>{
            //     if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[obj.data.grade] || 0) <= (props.totalStudentCountByGrade || {})[obj.data.grade]) {
            //         return ""
            //     }
            //     let totalfee = 0;
            //     obj.api.forEachNode((rowNode, index) => {
            //         const { data } = rowNode;
            //         if (data.grade) {
            //             totalfee += Number((formSetting.gradeFees || {})[data.grade] || 0);
            //         }
            //       });
            //       props.updateState({ totalfee })
            //     return obj.data.grade;
            // }
        },
        {
            headerComponentFramework: () => {
                return <span>Grade fee</span>;
            },
            width: 80,
            hide: !formSetting.gradeLevelValidation || !Object.keys(formSetting.gradeFees || {}).length,
            field: "gradefee",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <span>DOB<span className="required">*</span></span>;
            },
            field: "dob",
            resizable: true,
            editable: true,
            width: 130,
            cellEditor: 'customDatePicker',
            cellEditorParams: (obj) => {
                return { disabledDate: (date) => (date && date.valueOf() > Date.now()), }
            },
            cellRendererFramework: (params) => {
                return <span>{params.data.dob ? getMomentDateForUIReadOnly({ date: params.data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</span>
            },
        },

        {
            headerComponentFramework: () => {
                return <span>Allergies<span className="required">*</span></span>;
            },
            field: "allergies",
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            // cellEditorParams: (params) => {
            //     return {
            //         value: params.node.data.allergies || "",
            //         isEditorLoaded: props.isEditorLoaded,
            //         onChange: (value) => {
            //             params.node.data[params.colDef.field] = value;
            //             params.node.data.isEditorLoaded = true;
            //             props.updateState({
            //                 isEditorLoaded: true
            //             })
            //         }
            //     }
            // },
            cellRendererFramework: (params) => {
                return ReactHtmlParser(params.data.allergies)
            },
            // suppressKeyboardEvent: (params) => {
            //     const KEY_ENTER = 13;
            //     const keyCode = params.event.keyCode;
            //     const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
            //     params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
            //     if (params.event.target.scrollHeight > 30) {
            //         if (params.event.path[2]) {
            //             params.event.path[2].style.height = 'inherit';
            //             params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            //         }
            //         params.node.setRowHeight(params.event.target.scrollHeight);
            //         params.api && params.api.onRowHeightChanged();
            //     } else {
            //         if (params.event.path[2]) {
            //             params.event.path[2].style.height = 'inherit';
            //             params.event.path[2].style.height = `${30}px`;
            //         }
            //         params.node.setRowHeight(30);
            //         params.api && params.api.onRowHeightChanged();
            //     }
            //     return gridShouldDoNothing;
            // }
        },
        {
            headerComponentFramework: () => {
                return 'Gender';
            },
            field: "gender",
            resizable: true,
            editable: true,
            width: 70,
            suppressMovable: true,
            cellEditor: 'customDropDownEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            cellEditorParams: (obj) => {
                return {
                    lastColumnCell: false,
                    items: ['Male', 'Female', 'Rather Not Say'],
                    onSelect: (selectedValue) => {
                        //obj.node.setDataValue(obj.colDef.field, selectedValue);
                    },
                    allowClear: true,
                    onClear: () => {
                        //obj.node.setDataValue(obj.colDef.field, undefined);
                    },
                }
            }
        },
        {
            headerComponentFramework: () => {
                return 'Action';
            },
            width: 70,
            field: "action",
            resizable: true,
            cellRenderer: 'customActionEditor',
            suppressNavigable: true,
            cellRendererParams: (params) => {
                return {
                    onClick: () => {
                        let totalStudentCountByGrade = props.totalStudentCountByGrade || {};
                        if (!params.data.invalidGrade) {
                            totalStudentCountByGrade[params.data.grade] = Number(totalStudentCountByGrade[params.data.grade]) - 1;
                            props.updateState({
                                totalStudentCountByGrade
                            });
                        }

                        params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                        if (params.api.rowRenderer.lastRenderedRow < 0) {
                            params.api.updateRowData({ add: [{}] });
                        }
                    },
                }
            }

        }
    ]
    const getAddressInfoFragment = (obj, additionInfo) => {
        if (!obj) return '';
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                        }
                    </div>
                    : ''
            }
            {
                obj.countryName
                    ? <div className='billing-address'>
                        {
                            `${obj.countryName || ''}`
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        Phone - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        Email - {additionInfo.email}
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }

    return (
        <div style={{ padding: '0px 0px 200px' }} >
            {/* <Row gutter={24} style={{ background: formSetting.bgColor || '#31c0be', color: formSetting.textColor || '#ffffff', padding: '5px' }}>
                <Col span={2}> </Col>
                <Col span={2}>
                    <div className="brand-logo">
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={14} style={{ alignSelf: 'center' }}>
                    <div style={{ fontSize: '22px' }}> {props.companyInfo.storeName}</div>
                </Col>
                <Col span={6} style={{ alignSelf: 'center' }}>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </Col>
            </Row> */}
            <Row gutter={20}>
            <Col span={2}></Col>
                <Col span={20}>
            <div className='main-container'>
                <div className="modal-header  titr"><div style={{textAlign:'center', padding:'10px 0px'}}>{props.programName || 'Registration'}</div>
                    {formSetting.subHeading ? <div style={{ fontSize: '12px', textAlign: 'center' }}>{formSetting.subHeading}</div> : ''}
                </div>
                {props.status === 'success' && <div style={{ fontSize: '16px', textAlign: 'center' }}><b>Thank you for completing the registation. </b></div>}
                {props.status === 'error' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b>Your payment failed </b></div>}
                {props.status === 'inactive' && <div style={{ fontSize: '16px', textAlign: 'center' }}><b> Registration Closed </b></div>}
                {props.isFormSaved ? <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{props.successMessage || 'Thank you for completing the registation.'} </b></div> : ((formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount)) ?
                    <div style={{ fontSize: '16px', textAlign: 'center' }}><b>Registration has closed. Please contact the support team. </b>
                    
                    </div>
                    :
                    !props.status && <Form layout="vertical" onSubmit={save} >
                        <Row gutter={16}>
                            {formSetting.formDescription ?
                                <Col span={24}>
                                    {formSetting.formDescription ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.formDescription)}</div> : ''}
                                </Col> : ''}
                        </Row>
                        <Row gutter={16}>
                            {formSetting.fatherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Father's First Name {formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}>
                                        <TextBox className='new-fed1'
                                            placeholder='First Name'
                                            type='text'
                                            maxLength={25}
                                            value={props.fatherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ fatherFirstName: e.target.value });
                                            }}
                                            required={formSetting.fatherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Father's Last Name {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Last Name'
                                            type='text'
                                            required={formSetting.fatherLastNameRequired}
                                            maxLength={25}
                                            value={props.fatherLastName}
                                            onChange={(e) => {
                                                props.updateState({ fatherLastName: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                            {formSetting.fatherMobileDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Father's Mobile' {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            type='text'
                                            //value={contact.lastName}
                                            required={formSetting.fatherMobileRequired}
                                            maxLength={15}
                                            value={props.fatherMobile}
                                            onChange={(e) => {
                                                props.updateState({ fatherMobile: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.fatherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span>Father's Email {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.fatherEmail}
                                            onChange={(e) => {
                                                props.updateState({ fatherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.fatherEmail, type: 'fatherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                            }}
                                            required={formSetting.fatherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                            {formSetting.motherFirstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Mother's First Name {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='First Name'
                                            type='text'
                                            maxLength={25}
                                            value={props.motherFirstName}
                                            onChange={(e) => {
                                                props.updateState({ motherFirstName: e.target.value });
                                            }}
                                            required={formSetting.motherFirstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherLastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Mother's Last Name {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Last Name'
                                            type='text'
                                            maxLength={25}
                                            value={props.motherLastName}
                                            onChange={(e) => {
                                                props.updateState({ motherLastName: e.target.value });
                                            }}
                                            required={formSetting.motherLastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>


                        <Row gutter={16}>
                            {formSetting.motherMobileDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Mother's Mobile {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter mobile number"
                                            type='text'
                                            maxLength={15}
                                            value={props.motherMobile}
                                            onChange={(e) => {
                                                props.updateState({ motherMobile: e.target.value });
                                            }}
                                            required={formSetting.motherMobileRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.motherEmailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Mother's Email {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.motherEmail}
                                            onChange={(e) => {
                                                props.updateState({ motherEmail: e.target.value });
                                            }}
                                            onBlur={() => {
                                                if(!props.customerId){
                                                    props.fetchStudentFatherEmail({ email: props.motherEmail, type: 'motherEmail', registrationFormSettingId: props.registrationFormSettingId }, props)
                                                }
                                                
                                            }}
                                            required={formSetting.motherEmailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                            {formSetting.emergencyContactDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Emergency Contact Name {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Enter Number"
                                            type='text'
                                            maxLength={45}
                                            value={props.emergencyContactName}
                                            onChange={(e) => {
                                                props.updateState({ emergencyContactName: e.target.value });
                                            }}
                                            required={formSetting.emergencyContactRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.emergencyPhoneDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Emergency Phone {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter Number'
                                            type='text'
                                            maxLength={15}
                                            value={props.emergencyPhone}
                                            onChange={(e) => {
                                                props.updateState({ emergencyPhone: e.target.value });
                                            }}
                                            required={formSetting.emergencyPhoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                          <Row gutter={16}>
                            {formSetting.firstNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>First Name {formSetting.firstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="First Name"
                                            type='text'
                                            maxLength={45}
                                            value={props.firstName}
                                            onChange={(e) => {
                                                props.updateState({ firstName: e.target.value });
                                            }}
                                            required={formSetting.firstNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.lastNameDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Last Name {formSetting.lastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Last Name'
                                            type='text'
                                            maxLength={15}
                                            value={props.lastName}
                                            onChange={(e) => {
                                                props.updateState({ lastName: e.target.value });
                                            }}
                                            required={formSetting.lastNameRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                            {formSetting.phoneDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Phone {formSetting.phoneRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder="Phone Number"
                                            type='text'
                                            maxLength={45}
                                            value={props.phoneNumber}
                                            onChange={(e) => {
                                                props.updateState({ phoneNumber: e.target.value });
                                            }}
                                            required={formSetting.phoneRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                            {formSetting.genderDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Gender {formSetting.genderRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Dropdown
                                            items={['Male', 'Female', 'Rather Not Say']}
                                            placeholder='Gender'
                                            onSelect={(selectedValue) => {
                                                props.updateState({ gender: selectedValue });
                                            }}
                                            required={formSetting.genderRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                           
                            {formSetting.emailDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span>Email {formSetting.emailRequired ? <span className="required">*</span> : ''}</span>}>
                                        <TextBox className='new-fed1'
                                            placeholder='Enter email'
                                            type='email'
                                            maxLength={45}
                                            value={props.email}
                                            onChange={(e) => {
                                                props.updateState({ email: e.target.value });
                                            }}
                                            required={formSetting.emailRequired}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                                {formSetting.dobDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>DOB {formSetting.dobRequired ? <span className="required">*</span> : ''}</span>}>
                                        <DatePicker
                                            allowClear={false}
                                            format={format}
                                            key='dob'
                                            onChange={(selectedDate) => {
                                                props.updateState({
                                                    dob: selectedDate
                                                })
                                            }}
                                            size={0}
                                        />
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>
                           
                           {formSetting.fatherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency  new-fed' label={<span>father's DOB {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                   <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='fatherDOB'
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                fatherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                               {formSetting.motherDOBDisplay ?
                               <Col span={12}>
                                   <Form.Item className='label-emergency new-fed' label={<span>Mother's DOB {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                       <DatePicker
                                           allowClear={false}
                                           format={format}
                                           key='motherDOB'
                                           onChange={(selectedDate) => {
                                               props.updateState({
                                                motherDOB: selectedDate
                                               })
                                           }}
                                           size={0}
                                       />
                                   </Form.Item>
                               </Col> : ''}
                       </Row>

                        <Row gutter={16}>
                            {formSetting.addressDisplay ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency new-fed' label={<span>Address {formSetting.addressRequired ? <span className="required">*</span> : ''}</span>}>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder='Street Name'
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress1}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress1: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder='Street Contd...'
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.streetAddress2}
                                                    onChange={(e) => {
                                                        props.updateState({ streetAddress2: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder='City'
                                                    type='text'
                                                    maxLength={45}
                                                    value={props.cityName}
                                                    onChange={(e) => {
                                                        props.updateState({ cityName: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Dropdown
                                                    items={props.countries || []}
                                                    optionKeyName='countryId'
                                                    placeholder='Country'
                                                    valueKeyName='countryName'
                                                    onSelect={(selectedValue, option) => {
                                                        props.updateState({ countryId: selectedValue, countryName: option.name });
                                                        props.fetchStates(selectedValue)
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                            <Col span={12}>
                                                {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                                    <Dropdown
                                                        items={props.states || []}
                                                        optionKeyName='stateId'
                                                        placeholder='State'
                                                        valueKeyName='stateName'
                                                        onSelect={(selectedValue, option) => {
                                                            props.updateState({ stateName: option.name });
                                                        }}
                                                        required={true}
                                                    /> : <TextBox
                                                        placeholder='State'
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.stateName}
                                                        onChange={(e) => {
                                                            props.updateState({ stateName: e.target.value });
                                                        }}
                                                        required={true}
                                                    />}
                                            </Col>
                                            <Col span={12}>
                                                <TextBox
                                                    placeholder='Zip/Postal Code'
                                                    type='text'
                                                    maxLength={15}
                                                    value={props.zipCode}
                                                    onChange={(e) => {
                                                        props.updateState({ zipCode: e.target.value });
                                                    }}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col> : ''}
                        </Row>
                        <Row gutter={16}>

                            {formSetting.membershipOptions ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span>Membership Level </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.membershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div><Radio value={membershipOption}>{membershipOption}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row>

                        <Row gutter={16}>

                            {(formSetting.selectedMembershipOptions && formSetting.selectedMembershipOptions.length) ?
                                <Col span={12}>
                                    <Form.Item className='label-emergency  new-fed' label={<span>Membership Level </span>}>
                                        <Radio.Group value={props.membershipOption} onChange={(e) => {
                                            props.updateState({ membershipOption: e.target.value });
                                        }} >
                                            {
                                                (formSetting.selectedMembershipOptions || []).map((membershipOption, i) => {
                                                    return (
                                                        <div key={'opt-'+i}><Radio value={membershipOption.membershipOptionId}>{membershipOption.name} - {`$${membershipOption.termAndFees.fee}/${membershipOption.termAndFees.frequency} for ${membershipOption.termAndFees.duration} ${membershipOption.termAndFees.period}${membershipOption.termAndFees.type? ' - '+membershipOption.termAndFees.type : ''}`}</Radio></div>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> : ''}
                        </Row>
                        {formSetting.collectStudentInfo ?
                            <div>
                                <b className='label-emergency'>Child/Student Details</b>
                                <Row gutter={16}>

                                    <Col span={24}>
                                        <div className='grid-container'>
                                            {/* {(Object.keys(props.totalStudentCountByGrade).length || !formSetting.gradeLevelValidation) && <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={(props.studentList || [])}
                                                //editType='fullRow'
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={(params) => {
                                                    params.api.sizeColumnsToFit();

                                                }}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}

                                                onGridReady={onGridReady}
                                            >
                                            </CustomAgGrid>} */}
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button className="grey-button ant-btn ant-btn-default" onClick={
                                            () => {
                                                let newRowIndex = -1;
                                                gridApi.forEachNode((rowNode, index) => {
                                                    newRowIndex = index;
                                                });
                                                if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                                                    return showToasterMessage({
                                                        messageType: 'error', description: 'Registration has closed. Please contact the support team.' 
                                                    });
                                                }

                                                gridApi.stopEditing();
                                                gridApi.applyTransaction({ add: [{}] });
                                                gridApi.startEditingCell({
                                                    rowIndex: newRowIndex + 1,
                                                    colKey: "firstName_1"
                                                });
                                                gridApi.refreshCells({ force: true });
                                            }}>Add Child/Student</Button>
                                    </Col>
                                </Row>

                                <br />
                            </div> : ''}

                        {
                            (formSetting.questionList || []).map((queObj, i) => {

                                return (
                                    <div key={'que-'+i} style={{ marginBottom: '10px', padding: '10px 10px 10px 10px' }}>
                                        <Row style={{ marginBottom: '10px' }}>
                                            <Col span={2}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</b></Col>
                                       <Col span={2}>
                                       {(queObj.optionList && queObj.optionList.length) ?
                                                <div>
                                                    {queObj.answerType === 'multiChoice' ?
                                                        <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                            queObj.answers = answers;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            props.updateState({ formSetting });
                                                        }}
                                                        >
                                                            {(queObj.optionList || []).map((optObj, j) => {
                                                                return (<div><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                            })
                                                            }

                                                        </Checkbox.Group> :
                                                        <Radio.Group value={queObj.answers} onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            props.updateState({ formSetting });
                                                        }} >
                                                            {(queObj.optionList || []).map((optObj, k) => {
                                                                return (<div key={'qObj-'+k}><Radio value={optObj.optText}>{optObj.optText}</Radio></div>)
                                                            })
                                                            }

                                                        </Radio.Group>
                                                    }
                                                </div> : ''
                                            }
                                       </Col>
                                        </Row>
                                        <Row>
                                            {/* {(queObj.optionList && queObj.optionList.length) ?
                                                <div>
                                                    {queObj.answerType === 'multiChoice' ?
                                                        <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                            queObj.answers = answers;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            props.updateState({ formSetting });
                                                        }}
                                                        >
                                                            {(queObj.optionList || []).map((optObj, j) => {
                                                                return (<div><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                            })
                                                            }

                                                        </Checkbox.Group> :
                                                        <Radio.Group value={queObj.answers} onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            props.updateState({ formSetting });
                                                        }} >
                                                            {(queObj.optionList || []).map((optObj, k) => {
                                                                return (<div><Radio value={optObj.optText}>{optObj.optText}</Radio></div>)
                                                            })
                                                            }

                                                        </Radio.Group>
                                                    }
                                                </div> : ''
                                            } */}
                                        </Row>
                                        {queObj.answerType === 'shortAnswer' ?
                                            <Row>
                                                <Col span={20}>
                                                    <TextArea
                                                        style={{ width: '100%' }}
                                                        placeholder='Enter here'
                                                        type='text'
                                                        value={queObj.answers}
                                                        onChange={(e) => {
                                                            queObj.answers = e.target.value;
                                                            let questionList = formSetting.questionList;
                                                            questionList.splice(i, 1, queObj);
                                                            formSetting.questionList = questionList;
                                                            props.updateState({ formSetting });
                                                        }}
                                                    />
                                                </Col>
                                            </Row> : ''
                                        }
                                    </div>
                                )
                            })
                        }

                        <Row gutter={22}>
                            {formSetting.footerMessage ?
                                <Col span={24}>
                                    {formSetting.footerMessage ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.footerMessage)}</div> : ''}
                                </Col> : ''}
                        </Row>

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        <Row gutter={24} className='regists'>
                            <div className='regist' style={{width:'82%', padding:'15px 0px', 
                            borderTop:'1px solid #e4e4e4', position:'fixed', bottom:'0px', background:'#fff'}}>
                                <Row>
                                <Col span={10}> </Col>

                                <Col span={2}> <Button onClick={() => {
                                    props.popModalFromStack();
                                }}>Close</Button> </Col>
                                <Col span={2}>
                                    {/* <Button className="primary ant-btn ant-btn-primary" onClick={() => {
                        save();
                    }}>Submit</Button> */}
                                    <div>
                                        {formSetting.collectOnlinePayment ? <Button
                                            className="primary ant-btn ant-btn-primary"
                                            htmlType='submit'
                                            key={2}
                                            style={{ color: 'white', height: 'auto' }}
                                            disabled={props.modalType === 'preview'}
                                        >Save {getTotalGradeFees() ? ' ('+(props.companyInfo.currencyCode || '') + ' '+ getTotalGradeFees() + ')': ''}
                                            
                                        </Button> :
                                            <Button
                                            className="primary ant-btn ant-btn-primary"
                                            htmlType='submit'
                                                key={2}
                                            >Save</Button>
                                        }
                                    </div>
                                </Col>
                                <Col span={10} style={{textAlign:'right'}}>
                                    <div>
                                    Powered by Alif App (<a href="https://www.alifapp.com" target={'_blank'}>alifapp.com</a>)
                                    </div>
                                
                                </Col>
                                {/* <Col span={1}></Col> */}


                                </Row>
                            </div>
                        </Row>


                    </Form>
                )}
                {(props.isFormSaved || ((formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount)))) ?  <div style={{display: 'flex', justifyContent: 'center'}}>
                   <div>Powered by Alif App (<a href="https://www.alifapp.com">alifapp.com</a>)</div>
                </div>: null}
            </div>
            </Col>
            </Row>
        </div>
    )
};

export default Register;
