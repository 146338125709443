import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RSVPCompo from '../../../../components/modal/modalBody/RSVP';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { saveOrUpdate } from './action';


class RSVP extends Component {
  constructor(props) {
      super(props);
      this.state = {
          registrationFormSettingId: props.registrationFormSettingId,
          phoneNumber: ''
      }
  }
  componentDidMount() {
   //   const linkProps = queryString.parse(this.props.location.search) || {};
    //  console.log("ddddddddddddddddddddd  ", linkProps);
      //this.props.fetchRelationshipByDomainName({ domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
  }

  componentWillReceiveProps(props) {
     
  }
  render() {
      window.document.title= this.props?.companyInfo?.storeName || '';
      const { common: { isLoading= false } } = this.props;
      const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
      return (
        
        <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={isLoading} >
         <RSVPCompo {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </Spin>
      )
  }
}
const mapStateToProps = (state) => {
  return {
    registrationFormSetting: state.registration.registrationFormSetting,
    countries: state.common.countries,
    states: state.common.states,
    companyInfo: state.common.relationshipData,
    relationshipId: state.common.relationshipData?.relationshipId,
    donationSettings: state.donation.donationSettings,
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  saveOrUpdate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RSVP);

