/* eslint-disable no-control-regex */
export const CONSTANTS = {
  INDIA_COUNTRY_ID: 45,
  BACKEND_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  BASE_URL_S3: 'https://s3.amazonaws.com',
  DISPLAY_DATE_FORMAT: 'ddd, DD MMM YYYY',
  BACKEND_FORMAT_FORMAT_SHORT: 'YYYY-MM-DD',
  EMAIL_REGEX_EXP:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  
};

export const MODAL_ACTIONS = {
  SHOW_MODAL: 'show_modal',
  HIDE_MODAL: 'hide_modal',
  MODAL_FORM_DATA_UPDATE: 'modal_form_data',
  PUSH_STACK_MODAL: 'push_stack_modal',
  POP_STACK_MODAL: 'pop_stack_modal',
  CLEAR_STACK_MODAL: 'clear_stack_modal'
}

export const MODAL_TYPE = {
  CARD_SWIPE : 'Card Swipe',
}

export const COMMON_ACTIONS = {
  RELATIONSHIP_DATA: 'relationship_data',
  SHOW_LOADER: 'show_loader',
  HIDE_LOADER: 'hide_loader',
  NEWS_LETTER_TEMPLATE: 'news_letter_template',
  PAYMENT_MODE_LIST: 'payment_mode_list'
};

export const DONATION_ACTION_LIST = {
  DONATION_SETTING: 'donation_setting',
};

export const FUNDRAISING_ACTION_LIST = {
  CAMPAIGN_LIST: 'campaign_list',
  EVENT_LIST: 'event_list',
  SELECTED_CAMPAIGN: 'selected_campaign',
  DONOR_COUNT: 'donor_count',
  EMAIL_VERIFICATION: 'email_verification',
  RESET_EMAIL_VERIFICATION: 'reset_email_verification',
  PLEDGE_PAYMENT: 'pledge_payment',
  RESET_PLEDGE_PAYMENT: 'reset_pledge_payment',
  SELECTED_PLEDGE: 'selected_pledge',
  IS_EMAIL_VERIFIED: 'is_email_verified',
  IS_EMAIL_SENT: 'is_email_sent',
  SET_EMAIL: 'set_email',
  RESET_PLEDGE_PAYMENT_STATE: 'reset_pledge_payment_state',
  SPONSORSHIP_LIST: 'sponsorship_list',
  SELECTED_SPONSORSHIP: 'select_sponsorship',
};

export const REGISTRATION_ACTION_LIST = {
  TOTAL_STUDENT_COUNT_BY_GRADE: 'total_student_count_by_grade',
  TOTAL_STUDENT_COUNT: "total_student_count",
  REGISTRATION_FORM_SETTING: "registration_form_setting",
  REGISTRATION_FORM_LIST: "registration_form_list",
  RESET_REGISTRATION_FORM_SETTING: "reset_registration_form_setting",
};

export const TX_STATUS = {
  NEW: "new",
  OPEN: "open",
  PAID: "paid",
  COMPLETED: "completed",
  NOT_REQUIRED: "not.required",
  NOT_STARTED: "not.started",
  SPLIT: "Split",
  ACTIVE: "Active",
  UNPAID: "unpaid",
};

export const GRID_ACTIONS = {
  DELETE: "delete",
  SAVE: "save",
  EMAIL: "email",
  DOWNLOAD_PDF: "download_pdf",
  EDIT: "edit",
  CANCEL: "cancle",
};

export const lang = {
  'cell.must.be.10.chars': "Cellphone must be ten characters",  
  'common.error.processing.request': 'Some error occurred'
};