import { DONATION_ACTION_LIST } from '../static/constants';
const initialState = {
	donationSettings: {}
};

const DonationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DONATION_ACTION_LIST.DONATION_SETTING:
      return {
        ...state,
        donationSettings: action.data,
      };
    default:
      return state;
  }
};

export default DonationReducer;