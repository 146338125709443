import { notification } from 'antd';
import moment from 'moment-timezone';
import { CONSTANTS } from '../static/constants';

export const showToasterMessage = (payload = {}) => {
  if(!payload.messageType){
    payload.messageType = 'error';
  }
  notification[payload.messageType || 'error'](
    {
      message: payload.message || (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning')),
      description: payload.description || 'Error',
      ...payload,
      duration: 6
    }
  )
}

export const getMomentDateForUIReadOnly = (data) =>{
  return moment(new Date(data.date || new Date())).format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT);
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fixedDecimalNumber = (number) => {
  return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}

export const formatedNumber = (number) => {
  return (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const fixedDecimalAndFormateNumber = (number) => {
  return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getCurrentDateForBackend = (dt) => {
  let fullDate = moment.utc(new Date(dt)).format("YYYY-MM-DD HH:mm:ss");
  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const validateEmail = (val) => {
  return val && val.toLowerCase().match(CONSTANTS.EMAIL_REGEX_EXP)
}

export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];

    if (typeof x == "string") {
      x = ("" + x).toLowerCase();
    }
    if (typeof y == "string") {
      y = ("" + y).toLowerCase();
    }

    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}