import React, { Fragment, useState, forwardRef } from "react";
import { FormattedMessage } from 'react-intl';
import * as debounce from 'lodash.debounce';
import { Select, Divider } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import * as getValue from 'lodash.get';
import './general.scss';
import { TX_STATUS, CONSTANTS } from '../../static/constants';
import { getMomentDateForUIReadOnly } from '../../utils';
const format = CONSTANTS.DISPLAY_DATE_FORMAT_SHORT;

const { Option } = Select;

export const MultipleSelectDropdown = (props) => {
    const [fetching, updateFetching] = useState(props.onSearch ? true : false);
    const[isLoadTimeOver, updateIsLoadTimeOver] = useState(false);

    const items = props.items || [];
    const selectedValue = props.selectedValue;
    let canAddNew = props.canAddNew || false;
    let canDelete = props.canDelete || false;
    
    const deleteClickHandler = props.deleteClickHandler || (() => {
    });
    const [newTextObj, setNewTextObj] = useState({ textEntered: '', existsInData: false });

    if(!isLoadTimeOver && items.length){
        setTimeout(() => {
            updateIsLoadTimeOver(true);
        }, 2000);
    }

    setTimeout(() => {
        updateFetching(false);
        updateIsLoadTimeOver(true);
    });

    return (
        <Fragment>
            {
                ( isLoadTimeOver) ?
                    <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        mode={'multiple'}
                        showSearch={props.showSearch || true}
                        className={props.className || ' custom-dropdown-width cell-dropdown'}
                        dropdownClassName={props.dropdownCustomStyle}
                        onDeselect={props.onDeSelect ? props.onDeSelect : () => {
                            console.log("====");
                        }}
                        placeholder={props.placeholder}
                        clearIcon={<i className="fa fa-times-circle" onClick={props.onClear} />}
                        allowClear={props.allowClear}
                        disabled={props.disabled}
                        style={props.style}
                        key={`props.selectedValue:${(selectedValue|| [])}`}
                        defaultValue={selectedValue || []}
                        optionFilterProp='name'
                        notFoundContent={fetching ? <Loading3QuartersOutlined className="spinnerIcon" spin /> : "No record(s) found"}
                        onSelect={props.onSelect}
                        onFocus={props.onFocus}
                        onSearch={(searchedText) => {
                            updateFetching(true)
                            props.onSearch ? debounce((searchedText) => {
                                props.onSearch(searchedText)
                            }, 2000)(searchedText) : (searchedText => {
                                setTimeout(() => {
                                    const searchedResults = items.filter(
                                        x => (x[props.valueKeyName] || '').toString().toLowerCase().indexOf(searchedText.toString().toLowerCase()) > -1
                                    );
                                    if (!searchedResults.length) {
                                        setNewTextObj({ textEntered: searchedText, existsInData: false });
                                    } else {
                                        setNewTextObj({ textEntered: searchedText, existsInData: true });
                                    }
                                }, 2000)
                            })(searchedText)
                        }}
                        dropdownRender={dropdown => {
                            return (
                                <Fragment>
                                    {
                                        canAddNew ?
                                            <Fragment>
                                                <div
                                                    style={{ padding: "4px 8px", cursor: "pointer" }}
                                                    onMouseDown={e => e.preventDefault()}
                                                    onClick={() => {
                                                        props.onAddNewClick(newTextObj)
                                                    }}
                                                    className='add-new'
                                                >
                                                    <i className="fa fa-plus" />
                                                    <FormattedMessage id='add' defaultMessage='' />
                                                    {
                                                        newTextObj.existsInData || !newTextObj.textEntered
                                                            ? <FormattedMessage id='new' defaultMessage=' ' />
                                                            : ` ${newTextObj.textEntered}`
                                                    }
                                                </div>
                                                <Divider style={{ margin: "1px 0" }} />
                                            </Fragment>
                                            : ''
                                    }
                                    {dropdown}
                                </Fragment>
                            )
                        }}
                    >
                        {
                            items.map((item) => {
                                const value = props.valueKeyName ? getValue(item, props.valueKeyName, '') : item;
                                const value2 = props.valueKeyName2 ? getValue(item, props.valueKeyName2, '') : '';
                                const lastNameKey = props.lastNameKey ? getValue(item, props.lastNameKey, '') : '';
                                const value3 = props.valueKeyName3 ? getValue(item, props.valueKeyName3, '') : '';
                                const value4 = props.valueKeyName4 ? getValue(item, props.valueKeyName4, '') : '';
                                const value5 = props.valueKeyName5 ? getValue(item, props.valueKeyName5, '') : '';
                                const addressLine1 = props.addressLineKey1 ? getValue(item, props.addressLineKey1, '') : '';
                                const addressLine2 = props.addressLineKey2 ? getValue(item, props.addressLineKey2, '') : '';
                                const addressLine3 = props.addressLineKey3 ? getValue(item, props.addressLineKey3, '') : '';
                                const addressLine4 = props.addressLineKey4 ? getValue(item, props.addressLineKey4, '') : '';
                                const customerName = props.customerName ? getValue(item, props.customerName, '') : '';
                                const date1 = props.dateKeyName1 ? getMomentDateForUIReadOnly({date: new Date(getValue(item, props.dateKeyName1, null)), format}) : '';
                                const date2 = props.dateKeyName2 ? getMomentDateForUIReadOnly({date: new Date(getValue(item, props.dateKeyName2, null)), format}) : '';
                                const key = typeof item === 'object' ? getValue(item, props.optionKeyName, '') : item;
                                return (
                                    <Option key={key} name={value + (value2 || "")} value={key}
                                        className={props.optionClassAddress + " " + props.optionClass || ' custom-dropdown-option'}>
                                        <span className={props.optionClass || ' custom-dropdown-option'}>
                                            {value}
                                            {lastNameKey ? ` ${lastNameKey}` : ''}
                                            {value2 ? ` (${value2})` : ''}
                                            {value3 ? ` (Bal. ${props.currencyCode + " "} ${value3})` : ''}
                                            {value4 ? ` ${" " + value4} ` : ''}
                                            {value5 ? `${" " + value5}` : ''}
                                            {date1 ? ` (${date1})` : ''}
                                            
                                            {addressLine1 ? <br /> : ""}
                                            {addressLine1 ? `${" " + addressLine1}` : ''}
                                            {addressLine2 ? <br /> : ""}
                                            {addressLine2 ? `${" " + addressLine2}` : ''}
                                            {addressLine3 ? <br /> : ""}
                                            {addressLine3 ? `${" " + addressLine3}` : ''}
                                            {addressLine4 ? `${",  " + addressLine4}` : ''}
                                            {date2 ? ` (${date2})` : ''}
                                            {customerName ? <br /> : ""}
                                            {customerName ? `${" " + customerName}` : ''}

                                        </span>
                                        {
                                            canDelete ?
                                                (value === TX_STATUS.OPEN ? <i
                                                    className='fa fa-lock' style={{ float: 'right', marginTop: 5 }} /> : <i
                                                        className='fa fa-trash'
                                                        onClick={e => {
                                                            const payload = {
                                                                id: key,
                                                                text: value,
                                                            };
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            deleteClickHandler(payload);
                                                        }}
                                                        style={{ float: 'right', marginTop: 5 }}
                                                    />)
                                                : ''
                                        }
                                    </Option>
                                )
                            })
                        }
                    </Select>
                    : null


            }
        </Fragment>
    );
}

export const MultipleSelectDropdownRef = forwardRef(MultipleSelectDropdown);
