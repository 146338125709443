import React, {Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button, InputNumber,Input, Checkbox, Progress, DatePicker } from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import { showToasterMessage, getMomentDateForUIReadOnly, sortByKey } from '../../utils';
import moment from 'moment-timezone';
import RegistrationFormComponent from '../../containers/modal/modalBody/registration';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TextArea } = Input;

const RegistrationComponent = (props) => {
    const { donationSettings = {} } = props;
    const insertNumber = (props, val) => {
        if (props.amount) {
            let amount = props.amount.slice(0, props.amountCursor) + val + props.amount.slice(props.amountCursor);
            props.updateState({ amount: amount, amountCursor: props.amountCursor +val.length })
        } else {
            props.updateState({ amount: val, amountCursor: props.amountCursor + val.length })
        }
    }
    return (
        <div className='main-container '>
                <Row style={{ textAlign: 'left' }}>
                    <Col className="dark-ins" span={24}>
                        <div>

                        <span className="medl-hed">
                        Registation Form
                            </span>

                            <ul>
                            {
                                     (sortByKey(Object.values(donationSettings.selectedRegistrations || {}) || [], 'sNo')).map((regObj, i) => {
                                        return (
                                            <li key={(i + 1)*200} className={regObj.registrationFormSettingId === props.selectedRegistration?.registrationFormSettingId ? 'active-prject' : ''} style={{ fontSize: '20px' }} >
                                                <Row>
                                                    <Col span={10}> {regObj.displayName || ''} </Col>
                                                    <Col span={10} style={{ marginLeft: '0px' }}>
                                                        <div>
                                                            Membership Form
                                                        </div>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Button className={regObj.registrationFormSettingId === props.selectedRegistration?.registrationFormSettingId ? 'ant-btn-primary ' : 'ant-btn-primary'} onClick={() => {
                                                            props.updateState({ selectedRegistration: regObj });
                                                            props.pushModalToStack({
                                                                modalBody: <RegistrationFormComponent {...props} registrationData={null} registrationFormSettingId={regObj.registrationFormSettingId} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                title: '',
                                                                width: '100%',
                                                                hideFooter: true,
                                                                isHideCloseIcon: true,
                                                                wrapClassName: 'modal-custom-detailtr'
                                                            })
                                                        }}>
                                                            Become a Member
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    })
                                }
                                {
                                    (Object.values(donationSettings.selectedStudentRegistrations || {}) || []).map((regObj, i) => {
                                        return (
                                            <li key={i + 1} className={regObj.registrationFormSettingId === props.selectedRegistration?.registrationFormSettingId ? 'active-prject' : ''} style={{ fontSize: '20px' }} >
                                                <Row>
                                                    <Col span={10}> {regObj.displayName || ''} </Col>
                                                    <Col span={10} style={{ marginLeft: '0px' }}>
                                                        <div>
                                                            {/* School Form */}
                                                            Register Now
                                                        </div>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Button className={regObj.registrationFormSettingId === props.selectedRegistration?.registrationFormSettingId ? 'ant-btn-primary ' : 'ant-btn-primary'} onClick={() => {
                                                            props.updateState({ selectedRegistration: regObj });
                                                            props.pushModalToStack({
                                                                modalBody: <RegistrationFormComponent {...props} registrationData={null} registrationFormSettingId={regObj.registrationFormSettingId} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                title: '',
                                                                width: '100%',
                                                                hideFooter: true,
                                                                isHideCloseIcon: true,
                                                                wrapClassName: 'modal-custom-detailtr'
                                                            })
                                                        }}>
                                                            Register Now
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>


                    </Col>
                </Row>
                
        </div>
    )

};

export default RegistrationComponent;
