import React from 'react';
import '@ant-design/compatible/assets/index.css';
import ReactHtmlParser from 'react-html-parser';

const NewsLetterPreview = (props) => {
    setTimeout(function () {
        let rsvpButtonRef = document.getElementById("child_4");
        let pladgeButtonRef = document.getElementById("child_8");
        if (!props.isURLUpdated && rsvpButtonRef) {
            //let href = rsvpButtonRef.href + '_' + props.newsLetterTemplate.alpideFormBuilderId;
            rsvpButtonRef.style.display = "none";
            if (pladgeButtonRef) { pladgeButtonRef.style.display = "none"; }
            props.updateState({ isURLUpdated: true });
        }
    }, 1000);

    return (
        <div style={{ padding: '0px 0px 200px' }} >
            <div className="grey-boxlo">
                <div className='middle-conter white-bglo'>
                    <div className='menu boxcnt' id="temmplateDiv">
                        {ReactHtmlParser(props.newsLetterTemplate.templateContent)}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NewsLetterPreview;