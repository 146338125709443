import React, { Fragment, Component } from 'react';
import '@ant-design/compatible/assets/index.css';
import CardSwipe from '../../../../../assets/images/card-swipe.jpg';
import { Input, Button } from "antd";
import * as debounce from 'lodash.debounce';

class PaymentFaildComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      neverExpires: 1,
      cardNumber: ''
    }
    //props.updateState({ ...this.props.data?.formData });
    //console.log("this.props ", this.props);
  }


  render() {
    return (
      <Fragment>
        <div style={{ padding: '16px', textAlign: 'center', }}>
          {/* <button className="done-close" onClick={() =>{ this.props.popModalFromStack(); this.props.popModalFromStack();}}>Done</button> */}
          <br/>
          {this.props.response?.statusMessage || ''} 
        </div>
      </Fragment >
    )
  }

}

export default PaymentFaildComponent;
