import React, {useState} from 'react';
import './general.scss';
export function TextArea(props) {
  let className = props.className || 'input-text';
  if (props.value && props.validator && !props.validator(props.value)) {
    className = props.invalidClassName || 'input-text-error text-red';
  }
  let [isFocus, setIsFocus] = useState(false)
  return (
   <div>
      <textarea
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      rows={props.rows}
      className={className}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      style={props.style}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={()=>{
        setIsFocus(false);
      }}
    />
     {isFocus ? <div style={{textAlign: "right"}}>{(props.maxLength && (props.value || "").length) ? props.value.length +" / " + props.maxLength + " characters": "" }</div> : ''}
     </div>
  )
}