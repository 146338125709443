import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PledgePaymentComponent from "../../components/PledgePayment";
import { doesAccountExist, getPledgePayments, emailVerified, selectPledge, setEmailToState } from './action';
import {  processCardPayment, emailReceipt, fetchRelationshipByDomainName, fetchRelationshipDataByRID } from '../Donation/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchDonationSetting } from '../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../static/constants';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import config from '../../config/environmentConfig';
import CryptoJS from "crypto-js";

class PledgePayment extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(props.location?.search) || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            source: linkProps.source,
            donationSettings: {},
            amountCursor: 0,
            relationshipBillingAddress: {},
            isSettingLoad: false,
            otp: '',
            email:'',
            duration: 'oneTime',
            paymentStartDate: new Date(),
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location?.search) || {};
        const bytes = CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET);
        const load = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.props.fetchDonationSetting(load);
        this.props.fetchRelationshipDataByRID(load);
       // this.props.fetchRelationshipByDomainName({ domainName: window.location.host, source: linkProps.source || ''}, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.donationSettings && props.donationSettings.settingsDonationId && !this.state.isSettingLoad) {
            let donationContent = JSON.parse(props.donationSettings.donationContent);
            this.setState({
                relationshipId: props.donationSettings.relationshipId,
                settingsDonationId: props.donationSettings.settingsDonationId,
                donationSettings: donationContent,
                formHeading: donationContent.formHeading,
                isSettingLoad: true,
                isTransactionFeeApplyByDefault: donationContent.isTransactionFeeApplyByDefault? 1: 0
            });
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
        if (props.pledgePaymentState) {
            this.setState({
                ...props.pledgePaymentState
            })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        const { common: { isLoading = false } } = this.props;
        const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
        return <div>
             <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={isLoading} >
              <PledgePaymentComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
              </Spin>
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.relationshipData,
        relationshipId: state.common.relationshipData?.relationshipId,
        donationSettings: state.donation.donationSettings,
     //   emailVerification: state.fundraising.emailVerification,
        pledgePayments: state.fundraising.pledgePayments,
      //  selectedPledge: state.fundraising.selectedPledge,
       // isEmailVerified: state.fundraising.isEmailVerified,
        pledgePaymentState: state.fundraising.pledgePaymentState,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchRelationshipDataByRID,
    fetchRelationshipByDomainName,
    fetchDonationSetting,
    processCardPayment,
    emailReceipt,
    doesAccountExist,
    getPledgePayments,
    selectPledge,
    emailVerified,
    setEmailToState
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PledgePayment);
