import React from "react";
import { Row, Col, Button, Input, Select } from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
//import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import {  getMomentDateForUIReadOnly} from '../../utils';
//import moment from 'moment-timezone';
import TemplatePreview from '../../containers/modal/modalBody/common/Template';
import RSVPCompo from '../../containers/modal/modalBody/RSVP';
//import * as find from 'lodash.find';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
//const { TextArea } = Input;
//const { Option } = Select;

const EventListingComponent = (props) => {
    const { donationSettings = {} } = props;
    
    return (
        <div className='main-container '>
            {/* {!props.selectedEventData ? */}
                <Row style={{ textAlign: 'left' }}>
                    <Col className="dark-ins" span={24}>
                        <div>

                        <span className="medl-hed">
                            Events
                        </span>

                            <ul>
                                {donationSettings.selectedEvents ?
                                    (props.eventList || []).map((eventData, i) => {
                                        
                                       // const eventData = find(props.eventList || [], { alpideFormBuilderId: eventObj.alpideFormBuilderId }) || {};
                                        return (
                                            <li key={i + 1} style={{ fontSize: '20px' }} >
                                                <Row>
                                                    <Col span={19}>
                                                        <div> <span style={{ color: '#d3d3d3' }} onClick={() => {
                                                            props.pushModalToStack({
                                                                modalBody: <TemplatePreview {...props} campaignData={eventData} alpideFormBuilderId={eventData.alpideFormBuilderId} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                title: ' ',
                                                                width: '100%',
                                                                hideFooter: true,
                                                                textAlign:'center',
                                                                wrapClassName: 'modal-custom-detailtr'
                                                            })
                                                        }}>
                                                            <b> {(donationSettings.selectedEvents[eventData.alpideFormBuilderId] || {}).displayName || ''}
                                                            </b>
                                                        </span>
                                                        </div>
                                                        <div style={{fontSize:'15px'}}> <span>{eventData.eventTitle} </span> is on {eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} {eventData.eventTime || ''} </div>
                                                        <div style={{fontSize:'15px'}}> Hosted By - {eventData.eventHostedBy || ''}</div>
                                                        <div style={{fontSize:'15px'}}> {eventData.eventStreet1 || ''} {eventData.eventStreet2 || ''}  {eventData.eventCity || ''} {eventData.eventState ? ', ' + eventData.eventState : ''} {eventData.eventZipCode ? ', ' + eventData.eventZipCode : ''} </div>
                                                        <div style={{fontSize:'15px'}}> { (eventData.totalAdultAttending + eventData.totalAdultAttending) } People are attending to this event </div>
                                                    </Col>
                                                    <Col span={3} style={{paddingTop:'40px'}}>
                                                        <Button className={eventData.alpideFormBuilderId === props.selectedEventData?.alpideFormBuilderId ? 'ant-btn-primary ' : 'ant-btn-primary'} onClick={() => {
                                                            props.updateState({ selectedEventData: eventData });
                                                            props.pushModalToStack({
                                                                modalBody: <RSVPCompo {...props} selectedEventData={eventData} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                title: 'RSVP',
                                                                 width: '40%',
                                                                hideFooter: true,
                                                                wrapClassName: 'modal-custom-detailtr1'
                                                            })
                                                        }}>
                                                            RSVP
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    }) : ''
                                }
                                </ul>
                            <div className="clearfix"></div>
                        </div>


                    </Col>
                </Row>
                {/* :
                <Row style={{ textAlign: 'left' }}>
                    <Col className="calco" span={24}>
                        <div style={{textAlign: 'center', width: '50%'}}>
                            <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                                <h3 style={{ textAlign: 'center' }}> <b>{props.selectedEventData.templateName}</b></h3>
                            </div>

                            <div className='pay-text'>
                                <Form>
                                    <div className="information pading-rii"  >
                                        <div className=" ">

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    First Name<span className="required">*</span>
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.firstName} placeholder="" onChange={(e) => {
                                                        props.updateState({ firstName: e.target.value });
                                                    }}
                                                        className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    Last Name
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.lastName} placeholder="" onChange={(e) => {
                                                        props.updateState({ lastName: e.target.value })
                                                    }} />
                                                </Col>
                                            </Row>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    Email<span className="required">*</span>
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.emailAddress} placeholder="" onChange={(e) => {
                                                        props.updateState({ emailAddress: e.target.value })
                                                    }}
                                                        className={props.invalidEmailClass}
                                                        validator={(val) => { return validateEmail(val); }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    Phone Number
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.phoneNumber} placeholder="" onChange={(e) => {
                                                        props.updateState({ phoneNumber: e.target.value })
                                                    }} />
                                                </Col>
                                            </Row>

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    Are you attending?
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <Select onChange={(value) => {
                                                        props.updateState({
                                                            attendingStatus: value,
                                                        })
                                                    }}
                                                        className={(props.submittedOnce && !props.attendingStatus) ? 'input-text-error' : ''}
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        optionFilterProp="children"
                                                        value={props.period}>
                                                        <Option value="attending">Attending</Option>
                                                        <Option value="tentative">Tentative</Option>
                                                        <Option value="declined">Declined</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                            {props.attendingStatus === 'attending' && <div>
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right">
                                                        Number of Adult<span className="required">*</span>
                                                    </Col>
                                                    <Col span={14} className="input-currency-control">
                                                        <InputNumber style={{ width: '100%' }} value={props.numberOfAdult} placeholder="" onChange={(val) => {
                                                            props.updateState({ numberOfAdult: val })
                                                        }}
                                                            className={props.invalidNumberOfAdultClass}
                                                            validator={(val) => { return (props.numberOfAdult !== 0 && !props.numberOfAdult); }}
                                                        />
                                                    
                                                    </Col>
                                                </Row>
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right">
                                                        Number of Child
                                                    </Col>
                                                    <Col span={14} className="input-currency-control">
                                                        <InputNumber style={{ width: '100%' }} value={props.numberOfChild} placeholder="" onChange={(val) => {
                                                            props.updateState({ numberOfChild: val })
                                                        }}
                                                        />
                                                       
                                                    </Col>
                                                </Row>
                                            </div>
                                            }
                                            {(props.attendingStatus && props.attendingStatus !== 'attending') &&
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right">
                                                        Notes
                                                    </Col>
                                                    <Col span={14} className="input-currency-control">
                                                        <TextArea
                                                            className='martm'
                                                            placeholder="Enter Remarks"
                                                            value={props.remarks}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                props.updateState({
                                                                    remarks: value,
                                                                });
                                                            }}
                                                            autoSize={{ minRows: 1, maxRows: 4 }}
                                                        ></TextArea>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </div>
                                </Form>
                                <Button className='pay-but3' onClick={() => {
                                    props.updateState({
                                        submittedOnce: true,
                                        invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',
                                        invalidNumberOfAdultClass: (props.attendingStatus === 'attending' && (props.numberOfAdult !== 0 && !props.numberOfAdult)) ? 'input-text-error' : ''

                                    });
                                    if (!props.firstName || !props.emailAddress || !props.attendingStatus || (props.attendingStatus === 'attending' && !props.numberOfAdult)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Required field is missing'
                                        });;
                                    }
                                    let payload = {
                                        relationshipId: props.selectedEventData.relationshipId,
                                        alpideFormBuilderId: props.selectedEventData.alpideFormBuilderId,
                                        firstName: props.firstName,
                                        lastName: props.lastName,
                                        email: props.emailAddress,
                                        phoneNumber: props.phoneNumber,
                                        adultAttending: props.numberOfAdult || 0,
                                        childAttending: props.numberOfChild || 0,
                                        remarks: props.remarks,
                                        isAttending: props.attendingStatus === 'attending' ? 1 : 0,
                                        isTentative: props.attendingStatus === 'tentative' ? 1 : 0,
                                        isDeclined: props.attendingStatus === 'declined' ? 1 : 0,
                                    }
                                    props.saveOrUpdate(payload, props)
                                }}>Submit</Button>
                            </div>
                        </div>

                    </Col>
                    
                      
                </Row>
            } */}
        </div>
    )

};

export default EventListingComponent;
