import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../config/environmentConfig';
import { fetchRelationshipDataByRID, fetchRelationshipByDomainName } from './action';
import { fetchDonationSetting } from '../../actions/commonActions';
import LeftPanelComponent from '../../components/leftPanel';
import { selectCampaign } from '../Fundraising/action';
import { selectSponsorship } from '../Sponsorship/action';
import { resetPaymentPledgeState } from '../PledgePayment/action';
import queryString from 'query-string';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import CryptoJS from "crypto-js";

class LeftPanel extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(window.location.search) || {};
        const bytes = linkProps.token ? CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET) : null;
        const token =bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : {};

        this.state = {
            source: token.source,
            relationshipBillingAddress: {},
            token:linkProps.token 
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(window.location.search) || {};
        const bytes = linkProps.token ? CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET) : null;
        const load =bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : {};
        this.props.fetchDonationSetting(load);
        this.props.fetchRelationshipDataByRID(load);
       // this.props.fetchRelationshipByDomainName({ domainName: window.location.host, source: linkProps.source || ''}, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.donationSettings && props.donationSettings.settingsDonationId) {
            let donationContent = JSON.parse(props.donationSettings.donationContent);
            this.setState({
                donationContent: donationContent,
            })
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: 'RelationshipBillingAddress' }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
            <LeftPanelComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.relationshipData,
        donationSettings: state.donation.donationSettings,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRelationshipDataByRID,
    fetchRelationshipByDomainName,
    fetchDonationSetting,
    selectCampaign,
    resetPaymentPledgeState,
    selectSponsorship
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
