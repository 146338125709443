import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, FUNDRAISING_ACTION_LIST } from '../../static/constants'
import { showToasterMessage } from '../../utils';

export const subscribeUser = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/subscribe/`,
        requestObj,
      )
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Saved successfully.',
          messageType: 'success'
        });
        props &&  props.updateState({ distributionGroupIds: [],
          email: '',
          phoneNumber: '',
          firstName: '',
          lastName: '',
          selectedDgGroups: []
        });
      })
      .catch(err => {
        showToasterMessage({ description: 'Some error occurred' })
      })
  }
}