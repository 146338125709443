import axios from 'axios';
import reduxStore from './store';
import { COMMON_ACTIONS } from  './static/constants';
let callsQueue = []; 
let numberOfAjaxCAllPending= 0;

export const axiosInterceptorConfig = (history) =>{
const { dispatch } = reduxStore;
  /**
   * intercept request
   */
  axios.interceptors.request.use( async(config) => {
    //let serverCallUrl = new URL(config.url);
    numberOfAjaxCAllPending++;
     return config;

  }, function (error) {
    return Promise.reject(error)
  });


  // const getRefreshToken = () =>{
  //   return axios({
  //     method: 'POST',
  //     url:  `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getRefreshToken?refreshToken=${fetchFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)}`,
  //     headers: {}
  // });
  // }

  // let isRefreshingToken = false;

  /**
   * intercepts response
   */
  axios.interceptors.response.use((response)=>{
    numberOfAjaxCAllPending--;
    if(numberOfAjaxCAllPending === 0){
      dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    }
    return response;
  }, function (err) {
    numberOfAjaxCAllPending--;
    if(numberOfAjaxCAllPending === 0){
      dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    }
  return new Promise(function (resolve, reject) {
      if (err.response === undefined && err.config && !err.__isRetryRequest) {
          err.__isRetryRequest = true;
          callsQueue.push(err);
      }
      throw err;
  });
});

}