import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TemplatePreviewComponent from "../../../../../components/modal/modalBody/common/Template";
import { fetchNewsLetter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import queryString from 'query-string';

class Template extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(props.location?.search) || {};
        this.state = {
            relationshipId: linkProps.relationshipId,
            source: linkProps.source,
            alpideFormBuilderId: props.alpideFormBuilderId
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location?.search) || {};
       this.state.alpideFormBuilderId &&  this.props.fetchNewsLetter({ templateId: this.state.alpideFormBuilderId, relationshipId: this.props.companyInfo?.relationshipId}, this.props);
    }

    componentWillReceiveProps(props) {
      
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
              <TemplatePreviewComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.relationshipData,
        relationshipId: state.common.relationshipData?.relationshipId,
        newsLetterTemplate: state.common.newsLetterTemplate,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchNewsLetter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Template);
