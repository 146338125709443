import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MODAL_ACTIONS, MODAL_TYPE } from '../../static/constants';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';
import './modal.scss';

export function StackModal(props) {
  const modalStack = props.modalStack || [];

  return (
    <Fragment>
      {
        modalStack.map((modalObj, i) => <CustomModal key={i} {...modalObj} />)
      }
    </Fragment>
  );
}

function CustomModal(props) {
  const cancelBtnProps = (props.footer || {}).cancel || {};
  const submitBtnProps = (props.footer || {}).submit || {};
  const [formData, updateFormData] = useState(props.formData)
  const dispatch = useDispatch();
  const bodyHtml = getBody(props, formData, updateFormData);
  const defaultTitle = ''

  return (
    <Fragment>
      {
        !bodyHtml
          ? ''
          : (
            <Modal
              centered={false}
              title={props.title || defaultTitle}
              visible={true} 
              maskClosable={props.maskClosable === true? false : true}
              closable={props.isHideCloseIcon === true ? false : true}
              width={props.width}
              keyboard={props.keyboard}
              wrapClassName={props.wrapClassName}
              onCancel={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL }) }}
              footer={props.hideFooter ? null : [
                <Button
                  intlId={cancelBtnProps.intlId || 'confirmation.cancel'}
                  defaultMessage=''
                  type={cancelBtnProps.type || 'default'}
                  key={0}
                  onClick={() => { 
                    props.onCancel ? props.onCancel() : dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL }) 
                  }}
                />,
                <Button
                  intlId={submitBtnProps.intlId || 'confirmation.submit'}
                  defaultMessage=''
                  htmlType='submit'
                  key={1}
                  onClick={() => {
                    document.getElementsByClassName("add-new-input-text").length && document.getElementsByClassName("add-new-input-text")[0].classList.add("input-text-error")
                    if (formData && Object.keys(formData).length) {
                      props.handleSubmit(formData);
                    } else {
                      props.handleSubmit();
                      dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });
                    }
                  }}
                />,
              ]}
            >
              {
                bodyHtml
              }

            </Modal>
          )
      }
    </Fragment >
  );
}

const getBody = (props, formData, updateFormData) => {
  const { modalBody, modalData = {}, handleSubmit } = props;

  let body = <Fragment />;
  if (modalBody) {
    body = modalBody;
  } else {
    const { modalType } = modalData;
    switch (modalType) {
      case MODAL_TYPE.CARD_SWIPE:       {
        body = <CardSwipe {...modalData} formData={formData} updateFormData={updateFormData} handleSubmit={handleSubmit} />
        break;
      }
      default:
        break;
    }
  }
  return body;
}