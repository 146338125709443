import React, { Fragment } from 'react';
import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, REGISTRATION_ACTION_LIST} from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
import { Button } from 'antd';

export const fetchStudentFatherEmail = (payload, props) => {
  // let FY = getFinancialYear(payload.companyInfo);
  let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}` :
    `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        if (res?.data?.customerId) {
          console.log(res.data)
          props.showModal({
            maskClosable: true,
            isClosable: false,
            keyboard: false,
            modalBody: <Fragment>
              <div className="warning-message">
              Email exists. Do you want to use this email?
              </div>
              <br />
              <div className="button-action" style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid #e5e5e5',
                paddingTop: '15px'
              }}>
                <Button className="ant-btn-default"
                  onClick={() => {
                    if(payload.type === 'fatherEmail'){
                      props.updateState({
                        fatherEmail: ''
                      })
                    }else{
                      props.updateState({
                        motherEmail: ''
                      })
                    }
                    
                    props.hideModal();
                  }}> No
                </Button>
                <Button className="ant-btn-primary"
                  onClick={() => {
                    props.updateState({
                      ...res.data,
                      studentList: res.data.studentList.map((e)=>{
                        e.grade = null;
                        return e;
                        //e.gradefee = (props.formSetting || {}).gradeFees[e.grade];
                      })
                    })
                    props.hideModal();
                  }}>  Yes </Button>
              </div>
            </Fragment>,
            hideFooter: true
          })
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}



export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    //dispatch({ type: REGISTRATION_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
      .then(res => {
        dispatch({ type: REGISTRATION_ACTION_LIST.REGISTRATION_FORM_SETTING, data: res.data  });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = REGISTRATION_ACTION_LIST.REGISTRATION_FORM_SETTING;
        }
      })
  }
}

export const saveRegistration = (requestObj, props, amount) => {

  let formData = new FormData();
  formData.append('customer', JSON.stringify(requestObj, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/saveOrUpdateCustomer`,
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
      )
      .then(res => {
        if(requestObj.studentRegistrationList[0] &&  requestObj.studentRegistrationList[0].amount){
          console.log((res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register&formId=${(props.formSetting) || {}.registrationFormSettingId}`);
          //window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register&formId=${(props.formSetting) || {}.registrationFormSettingId}`;
        }
        
        // if(props && props.updateState){
        //   props.updateState({
        //     isFormSaved: true
        //   })
        // }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch(err => {
         showToasterMessage({ description: 'Some error occurred' })
      })
  }
}

export const saveRegistrationFound = (requestObj, props) => {
  //formData.append('customer', JSON.stringify(requestObj, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentRegistration/register`,
      requestObj,
        
      )
      .then(res => {
        if(requestObj.studentRegistrationList[0] &&  requestObj.studentRegistrationList[0].amount){
          window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register?studentRegistrationId=${(res.data || {}).studentRegistrationId}&formId=${props.registrationFormSettingId}`;
        }else{
          if(props && props.updateState){
            props.updateState({
              isFormSaved: true
            })
          }
        }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch(err => {
         showToasterMessage({ description: 'Some error occurred' })
      })
  }
}

export const getTotalStudentcCount = (payload) => {
  return dispatch => {
  //  dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/countStudent?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
      .then(res => {
        dispatch({ type: REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT, data: res.data  });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: 'Some error occurred' })
        } else {
          err.actionToCall = REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT;
        }
      })
  }
}

export const getTotalStudentcCountByGrade = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/countStudentByGrade?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: 'Some error occurred' })
        } else {
          err.actionToCall = REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE;
        }
      })
  }
}