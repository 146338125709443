import { COMMON_ACTIONS } from '../static/constants';
const initialState = {
  isLoading: true,
  relationshipData: {},
  newsLetterTemplate: {},
  paymentModeList: []
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.SHOW_LOADER:
      return { ...state, isLoading: true };
    case COMMON_ACTIONS.HIDE_LOADER:
      return { ...state, isLoading: false };
    case COMMON_ACTIONS.RELATIONSHIP_DATA:
      return {
        ...state,
        relationshipData: action.data,
      };
      case COMMON_ACTIONS.NEWS_LETTER_TEMPLATE:
      return {
        ...state,
        newsLetterTemplate: action.data,
      };
    case COMMON_ACTIONS.PAYMENT_MODE_LIST:
      return { ...state, paymentModeList: action.data };
    default:
      return state;
  }
};

export default CommonReducer;