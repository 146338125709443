import React, { Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button, InputNumber, Input, Checkbox, DatePicker, Carousel } from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import { showToasterMessage, getMomentDateForUIReadOnly, validateEmail } from '../../utils';
import moment from 'moment-timezone';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';
import img2 from '../../assets/images/DonationSlider/slide2.jpg';
import img3 from '../../assets/images/DonationSlider/slide3.jpg';
import img7 from '../../assets/images/DonationSlider/slide7.jpg';
import img8 from '../../assets/images/DonationSlider/slide8.jpg';
import img9 from '../../assets/images/DonationSlider/slide9.jpg';
import img10 from '../../assets/images/DonationSlider/slide10.jpg';
import img11 from '../../assets/images/DonationSlider/slide11.jpg';
import img12 from '../../assets/images/DonationSlider/slide12.jpg';
import img13 from '../../assets/images/DonationSlider/slide13.jpg';
import img16 from '../../assets/images/DonationSlider/slide16.jpg';
import * as find from 'lodash.find';
import OtpInput from 'react-otp-input';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TextArea } = Input;

const PledgePaymentComponent = (props) => {
    const { donationSettings = {} } = props;
    const contentStyle = {
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
               
      };
    const insertNumber = (props, val) => {
        if (props.amount) {
            let amount = props.amount.slice(0, props.amountCursor) + val + props.amount.slice(props.amountCursor);
            props.updateState({ amount: amount, amountCursor: props.amountCursor + val.length })
        } else {
            props.updateState({ amount: val, amountCursor: props.amountCursor + val.length })
        }
    }

    const testOtp = (otp, props) => {
        if ( otp && otp.length === 6 && Number(otp) === Number(props.emailVerification.otp)) {
            props.emailVerified(true);
            props.updateState({ isEmailSent: false });

            let payload = {
                relationshipId: props.relationshipId,
                emailAddress: props.email,
            };
            props.getPledgePayments(payload, props);
        }else{
            return showToasterMessage({ description: 'Incorrect OTP' });
        }
    }

    return (
        <div className='main-container '>
            {!props.isEmailVerified ? <Row style={{ textAlign: 'left' }}>

                <Col className="dark-ins" span={24}>

                    <span className="medl-hed">
                        Pledge Payment
                    </span>
                    {(!props.isEmailSent) ?
                        <div>
                            <br />
                            <Row style={{ fontSize: '20px' }} className="subs">
                                <Col span={24} style={{ textAlign: 'center', paddingBottom:'10px' }}>Enter your email to lookup your pledge</Col>
                                <Col span={2}></Col>
                                <Col span={5} style={{ textAlign: 'right' }}>
                                    Email
                                </Col>
                                <Col span={10}>
                                    <Input style={{ width: '100%', marginBottom:'10px' }} value={props.email} placeholder="Email Address" onChange={(e) => {
                                        props.setEmailToState( e.target.value);
                                    }} />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={10}></Col>
                                <Col span={2}>
                                    <button type='primary' style={{cursor:'pointer'}} className="done-close" onClick={() => {

                                        if (!props.email) {
                                            return showToasterMessage({ description: 'Please Enter Email' });
                                        };
                                        if (props.email && !validateEmail(props.email)) {
                                            return showToasterMessage({
                                                messageType: 'error', description: 'Incorrect Email'
                                            });
                                        }

                                        let payload = {
                                            relationshipId: props.relationshipId,
                                            emailAddress: props.email,
                                        };
                                        props.doesAccountExist(payload, props);
                                    }
                                    }>
                                        Submit</button>
                                </Col>
                            </Row>
                        </div> :
                        ''
                    }

                    {props.isEmailSent ?
                        <div>
                            <br />
                            <Row style={{ fontSize: '20px' }} className="subs">
                                <Col span={24}>
                                    <Row>
                                        <Col span={2}></Col>
                                        <Col span={1}></Col>
                                        <Col span={15} style={{ paddingBottom: '10px' }}>
                                            {/* OTP has been sent to your email {props.email} */}
                                            <p style={{ fontSize: '24px', textAlign: 'left' }}>Account Found. Two-Factor Authentication Required. </p>
                                            <p style={{ fontSize: '19px', textAlign: 'left' }}>We have located your account. To verify your identify, please enter the 6-digit verification code sent to your email  </p>
                                            <p style={{ fontSize: '19px', textAlign: 'left' }}>Didn't receive the code?  <span style={{ color: '#858585' }}>Resend</span>.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={5} style={{ textAlign: 'right' }}>
                                    {/* Enter OTP */}
                                </Col>
                                <Col span={10} className='otpStyle'>

                                    <OtpInput
                                        inputStyle={{ width: '45px' }}
                                        value={props.otp}
                                        onChange={(otp) => {
                                            props.updateState({ otp: otp });
                                            if (otp && otp.length === 6) testOtp(otp, props);
                                        }}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                    />
                                  
                                </Col>
                            </Row>

                            <Row>
                                <Col span={8}></Col>
                                
                                <Col span={2}>
                                    <button type='primary' style={{marginTop:'10px', cursor:'pointer', marginLeft:'12px', paddingLeft:'83px', paddingRight:'83px'}} className="done-close" onClick={() => {
                                       
                                       if (!props.otp) {
                                            return showToasterMessage({ description: 'Please Enter OTP' });
                                        };
                                        if (props.otp && props.otp !== props.emailVerification.otp) {
                                            return showToasterMessage({ description: 'Incorrect OTP' });
                                        }
                                        testOtp(props.otp, props);
                                    }
                                    }>
                                        Contiune</button>
                                </Col>
                            </Row>
                        </div> :
                        ''
                    }

                    <div className="clearfix"></div>
                </Col>
            </Row>
                :

                <Row style={{ textAlign: 'left' }}>
                    <Col className="dark-bluec" span={12} style={{ paddingTop: '15px', paddingLeft: '20px' }} >

                        <div style={{ textAlign: 'center', color: '#fff',background:'#484b67', marginBottom:'15px', padding: '10px 10px 10px 0px', marginRight:'20px', textAlign:'center', borderRadius:'6px' }}><span style={{  fontSize: '20px'  }}> You have pledged  to {(props.pledgePayments|| []).length} {(props.pledgePayments|| []).length > 1 ? 'Fundrasising Compaigns' : 'Fundrasising Compaign'}  </span></div>


                        <ul className="overscrol">
                            {(props.pledgePayments && props.pledgePayments.length) ?
                                (props.pledgePayments).map((pledgeObj, i) => {

                                    return (
                                        <li key={i + 1} className={pledgeObj.pledgeId === props.selectedPledge?.pledgeId ? 'active-prject-ple' : ''} style={{ fontSize: '20px',  padding: '10px 0px 25px 0px', marginBottom:'15px', borderBottom:'solid 1px #484b67 '  }} >
                                            <Row  style={{ marginRight:'20px'}}>
                                            <Col span={1} style={{color:'#b5b5b6'}}>{i+1}  </Col>
                                                <Col span={19} style={{color:'#a0a2c0'}}>
                                                    <Row>
                                           
                                                <Col span={24}>
                                                    <span style={{  paddingTop: '0px', display: 'block' }}>{pledgeObj.campaignName || ''}</span>
                                                </Col>
                                                <Col span={24} style={{ marginLeft: '0px' , fontSize: '16px' }} >
                                                    <span >You have Pledged ${Number(pledgeObj.amount||0).toFixed(2) } on {pledgeObj.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(pledgeObj.dateCreated), format }) : ''}</span>
                                                </Col>
                                                <Col span={24} style={{ marginLeft: '0px' , fontSize: '16px' }} >
                                                    <span >You have paid ${Number(pledgeObj.totalPaymentReceived || 0).toFixed(2)}</span>
                                                </Col>
                                                </Row>
                                                </Col>
                                                
                                                <Col span={4}>
                                                    <Button style={{ marginTop: '23px', marginRight: '10px', paddingLeft:'30px', paddingRight:'30px' }} className='ant-btn-primary' onClick={() => {
                                                        props.selectPledge(pledgeObj)
                                                    }}>
                                                        Pay
                                                    </Button>

                                                </Col>
                                            </Row>
                                        </li>
                                    )
                                }) : <div style={{ textAlign: 'center' }}>No Pledge Found</div>
                            }
                        </ul>

                    </Col>
                    { (props.selectedPledge && props.selectedPledge.pledgeId) ? 
                    <Col span={12} className="calco" style={{ paddingTop: '15px' }}>
                        <Row>
                            <Col span={24} className="top-heade">

                                Select an Amount to Donate

                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={5} className='culb'>
                                <Button className={props.amount === '20' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                    props.updateState({ amount: '20', amountCursor: 2 });
                                }}><span className='doloers'>$</span> 20</Button>
                            </Col>
                            <Col span={5} className='culb'>
                                <Button className={props.amount === '50' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                    props.updateState({ amount: '50', amountCursor: 2 });
                                }}><span className='doloers'>$</span> 50</Button>
                            </Col>
                            <Col span={5} className='culb'>
                                <Button className={props.amount === '100' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                    props.updateState({ amount: '100', amountCursor: 3 });
                                }}> <span className='doloers'>$</span> 100</Button>
                            </Col>
                            <Col span={5} className='culb'>
                                <Button className={props.amount === '200' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                    props.updateState({ amount: '200', amountCursor: 3 });
                                }}><span className='doloers'>$</span> 200</Button>
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                        <Row>
                            <Col span={24} className="top-heade">
                                or enter your amount
                            </Col>
                        </Row>

                        <Row>
                            <Col span={3}></Col>
                            <Col span={18} >
                                <Input
                                    className='mart'
                                    value={props.amount}
                                    onClick={(e) => {
                                        props.updateState({ amountCursor: e.target.selectionStart });
                                    }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                                        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                                            props.updateState({
                                                amount: value,
                                                amountCursor: e.target.selectionStart,
                                            });
                                        }
                                    }}
                                ></Input>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={3}></Col>
                            <Col span={18}>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '1');
                                        //  props.updateState({ amount: (props.amount + '1') });
                                    }}>1</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '2');
                                        // props.updateState({ amount: (props.amount + '2') });
                                    }}>2</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '3');
                                    }}>3</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '4');
                                    }}>4</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '5');
                                    }}>5</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '6');
                                    }}>6</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '7');
                                    }}>7</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '8');
                                    }}>8</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '9');
                                    }}>9</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        if (props.amount && props.amount.indexOf('.') < 0) {
                                            let amount = props.amount.slice(0, props.amountCursor) + '.' + props.amount.slice(props.amountCursor);
                                            props.updateState({ amount: amount, amountCursor: props.amountCursor + 1 })
                                        } else if (!props.amount) {
                                            props.updateState({ amount: '0.', amountCursor: props.amountCursor + 2 })
                                        }
                                    }}><b>.</b></span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '0');
                                    }}>0</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        let num = props.amount;
                                        props.updateState({ amount: num.substring(0, num.length - 1) });
                                    }}>
                                        <div className="back-arow"></div>
                                    </span></Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={2}></Col>
                            <Col span={19}>
                                <Row>
                                    <Col span={8} className='culbb'>
                                        <Button className={props.duration === 'oneTime' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                            props.updateState({ duration: 'oneTime' });
                                        }}>One-Time</Button>
                                    </Col>

                                    {/* <Col span={5} className='culbb'>
                                        <Button className={props.duration === 'daily' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                            props.updateState({ duration: 'daily' });
                                        }}>Daily</Button>
                                    </Col> */}
                                    <Col span={8} className='culbb'>
                                        <Button className={props.duration === 'weekly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                            props.updateState({ duration: 'weekly' });
                                        }}>Weekly</Button>
                                    </Col>
                                    <Col span={8} className='culbb'>
                                        <Button className={props.duration === 'monthly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                            props.updateState({ duration: 'monthly' });
                                        }}> Monthly <span style={{color:'red'}}>* </span>
                                        </Button>
                                    </Col>
                                </Row>
                                {donationSettings.isTransactionFeeApply && donationSettings.transactionFeePercent ?
                                    <div style={{  marginLeft: '21px',paddingBottom:'10px' }} className="new-ched">
                                        <Checkbox checked={(donationSettings.isTransactionFeeRequired === 1 || props.isTransactionFeeApplyByDefault === 1)} onChange={(e) => {
                                            props.updateState({
                                                isTransactionFeeApplyByDefault: e.target.checked ? 1 : 0,
                                            })
                                        }} />  I'd like to help cover the transaction fee of {donationSettings.transactionFeePercent}%
                                    </div> : ''
                                    
                                }
                                {props.duration && props.duration !== 'oneTime' ?
                                    <Row>
                                        <Col span={10} className="noty">
                                            No. of Payment(s)
                                        </Col>
                                        <Col span={14} className="new-ched">
                                            {!props.neverExpires && <div style={{float:'left'}}>
                                                <InputNumber className=' bity' style={{
                                                    width: '150', background: 'initial', fontSize: '16px', borderBottom: 'solid 1px #cecece',
                                                    marginBottom: '15px'
                                                }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                    props.updateState({ noOfPayment: value })
                                                }} /></div>
                                            }
                                           <div style={{float:'left', marginLeft:'10px'}}>
                                            <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                props.updateState({
                                                    neverExpires: e.target.checked ? 1 : 0,
                                                })
                                            }} /> Never Expires
                                            </div> 
                                        </Col>
                                        <Col span={10} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Next Payment Date
                                        </Col>
                                        <Col span={14} className="new-ched">
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                style={{ width: 180 }}
                                                // disabledDate={invalidEndDate}
                                                key={moment(new Date(), format)}
                                                defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        paymentStartDate: selectedDate
                                                    })
                                                }} />
                                        </Col>
                                    </Row> : ''}
                                    {/* {donationSettings.isDisplayNameOrEmail ? */}
                                    <Row>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Name
                                        </Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.firstName} placeholder="First Name" onChange={(e) => {
                                                props.updateState({ firstName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{
                                                width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'
                                            }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
                                                props.updateState({ lastName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Email
                                        </Col>
                                        <Col span={17} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.email} placeholder="Email" onChange={(e) => {
                                                props.updateState({ email: e.target.value })
                                            }} />
                                        </Col>
                                    </Row> 
                                    {/* : ''
                                } */}
                            </Col>
                        </Row>

                        <Row>
                            <Col span={3} style={{ padding: '10px' }}>
                                {/* Memo Text */}
                            </Col>
                            <Col span={18} >
                                <TextArea
                                    rows={2}
                                    value={props.remarks}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        props.updateState({
                                            remarks: value,
                                        });
                                    }}
                                ></TextArea>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={3}></Col>
                            <Col span={18} className='culbs'>
                                <Button onClick={() => {
                                    if (!props.amount || !Number(props.amount)) {
                                        return showToasterMessage({ description: 'Please enter amount' });
                                    }
                                    if (!props.duration) {
                                        return showToasterMessage({ description:"Please select a payment duration"});
                                    }
                                    if (!props.neverExpires && !props.noOfPayment && props.duration !== 'oneTime') {
                                        return showToasterMessage({ description: 'Please enter No. of Payment' });
                                    }
                                
                                //if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.firstName || !props.lastName)) {
                                if (props.duration && props.duration !== 'oneTime' && (!props.firstName || !props.lastName)) {
                                    return showToasterMessage({ description: "Please enter first name or last name" });
                                }
                                // if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.email || !validateEmail(props.email))) {
                                if (props.duration && props.duration !== 'oneTime' && (!props.email || !validateEmail(props.email))) {
                                    return showToasterMessage({ description: "Please enter a valid email" });
                                }
                                    const modalData = {
                                        title: <React.Fragment>
                                            <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                                                {props.selectedPledge.campaignName || 'Donate'}
                                            </div>
                                        </React.Fragment>,
                                        modalBody: <CardSwipe {...props} />,
                                        width: 600,
                                        hideFooter: true
                                    };
                                    props.pushModalToStack(modalData);

                                }}>Donate Now</Button>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col span={12}>
                        <Carousel autoplay>
                            {/* <div><img src={img1} alt="" style={contentStyle}/></div> */}
                            <div><img src={img2} alt="" style={contentStyle}/></div>
                            <div><img src={img3} alt="" style={contentStyle} /> </div>
                            {/* <div><img src={img4} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img5} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img6} alt="" style={contentStyle} /> </div> */}
                            <div><img src={img7} alt="" style={contentStyle} /> </div>
                            <div><img src={img8} alt="" style={contentStyle} /> </div>
                            <div><img src={img9} alt="" style={contentStyle} /> </div>
                            <div><img src={img10} alt="" style={contentStyle} /> </div>
                            <div><img src={img11} alt="" style={contentStyle} /> </div>
                            <div><img src={img12} alt="" style={contentStyle} /> </div>
                            <div><img src={img13} alt="" style={contentStyle} /> </div>
                            {/* <div><img src={img14} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img15} alt="" style={contentStyle} /> </div> */}
                            <div><img src={img16} alt="" style={contentStyle} /> </div>
                        </Carousel>
                    </Col> 
            }
                </Row>
            }
        </div>
    )

};

export default PledgePaymentComponent;
