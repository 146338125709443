import React from 'react';
import { Route, BrowserRouter, Switch, withRouter } from 'react-router-dom';
import { axiosInterceptorConfig } from '../customAxios';
import { AuthContext } from "../context/auth";
import Header from '../components/header/header';
import Donation from '../containers/Donation';
import LeftPanel from '../containers/LeftPanel';
//import DonationWeb from '../containers/DonationWeb';
import Fundraising from '../containers/Fundraising';
import Registration from '../containers/Registration';
import EventListing from '../containers/EventListing';
import Subscribe from '../containers/Subscribe';
import PledgePayment from '../containers/PledgePayment';
import SponsorshipComp from '../containers/Sponsorship';
import { Row, Col, Button } from 'antd';
import queryString from 'query-string';
import CryptoJS from "crypto-js";
import config from '../config/environmentConfig';

function AppRoute(prop) {
    const history = new BrowserRouter().history;
    const linkProps = queryString.parse(window.location.search) || {};

    const bytes = linkProps.token ? CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET) : null;
    const token =bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : {};
    //enable interceptor for auth
    axiosInterceptorConfig(history);

    const routes = [
        {
            path: '/donation',
            component: Donation
        },
        // {
        //     path: '/web-donation',
        //     component: DonationWeb
        // },
        {
            path: '/fundRaising',
            component: Fundraising
        },
        {
            path: '/register-member',
            component: Registration
        },
        {
            path: '/open-events',
            component: EventListing
        },
        {
            path: '/subscribe',
            component: Subscribe
        },
        {
            path: '/pledgePayment',
            component: PledgePayment
        },
        {
            path: '/sponsorship',
            component: SponsorshipComp
        }
    ];

    const routeComponents = routes.map(({ path, component: Component }, key) => {
        return (
            <Route
                exact
                path={path}
                key={key}
                render={(props) =>
                    <Component {...props} />
                }
            />
        )
    });

    return (
        <div className={token.source === 'kiosk' ? 'donation-bg': ''}>
            {/* {linkProps.source === 'kiosk' ? */}
                <Row>
                    <Col span={6}>
                        <LeftPanel></LeftPanel>
                    </Col>
                    <Col span={18}>
                        <AuthContext.Provider value={false}>
                            <Switch>
                                {routeComponents}
                                <Route path='/admin/:id' history={history} props={prop} component={Header} />
                            </Switch>
                        </AuthContext.Provider >
                    </Col>
                </Row>
{/* //                 :
//                 <Row>
//                     <Col span={24}>
// <DonationWeb  history={history} props={prop}></DonationWeb>
// </Col>
//                 </Row>
//             } */}
        </div>
    )
}

export default withRouter(AppRoute);
