import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import SidebarMenu from '../sidebar';
import PartialViewRoute from '../../route/partialViewRoute';
import { showModal, hideModal, pushModalToStack, popModalFromStack  } from '../../actions/commonActions';
import './header.scss';
import { bindActionCreators } from 'redux';
import AlpideLogo from '../../assets/images/alpide-logo.png';
 import LeftPanel from '../../containers/LeftPanel';
 //import NewPage from '../newPage';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null
        // this.onAction = this._onAction.bind(this)
        // this.onActive = this._onActive.bind(this)
        // this.onIdle = this._onIdle.bind(this)
        this.state = {
            collapsed: false,
            showHeaderMenuOptions: true,
            showUserDropdown: false
        };
    }

    componentDidMount() {
    }


    _onAction(e) {
        //console.log('user did something', e)
    }

    _onActive(e) {
        console.log('user is active');

        //console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    toggleHeaderMenuOptions = () => {
        const { showHeaderMenuOptions } = this.state;
        this.setState({
            showHeaderMenuOptions: !showHeaderMenuOptions
        });
    }

    toggleUserDropdown = () => {
        const { showUserDropdown } = this.state;
        this.setState({
            showUserDropdown: !showUserDropdown
        });
    }



    render() {
        const { collapsed } = this.state;
         const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
        return (
            <div>
          {/* <NewPage></NewPage>      */}
                <Row className='header-wrapper'>

                    <Col className='header-left-section' span={12}>
                        <ul className='header-left-functions'>
                            <li onClick={this.toggleCollapsed}
                                span={2}
                                className='header-option menu-option-wrapper'>
                                <div className="menu-icon">
                                    <div className="bar line-1" />
                                    <div className="bar line-2" />
                                    <div className="bar line-3" />
                                </div>
                            </li>
                            <li
                                span={2}
                                className="header-option"
                            >
                                <img src={AlpideLogo} alt="" style={{ width: "100px" }} />
                            </li>
                        </ul>
                    </Col>

                    <Col className='header-right-section' span={12}>
                        <LeftPanel> </LeftPanel> 
                       
                    </Col>
                </Row>

                <div className="user-view-container">
                    {/* <SidebarMenu {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} isCollapsed={collapsed} /> */}
                    <PartialViewRoute
                        {...this.state}
                        history={this.props.history}
                        updateHeaderState={(data) => {
                            this.setState(data);
                        }} isCollapsed={collapsed} />
                </div>
                <div className='header-spin'>
                   
                            <div className='text-middle'>
                                <Spin indicator={antIcon} />
                            </div>
                            </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
