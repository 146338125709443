import React from 'react';
import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS} from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';


export const saveOrUpdate = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rsvp/saveOrUpdate`,
        requestObj,
      )
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Saved successfully.',
          messageType: 'success'
        });
        props && props.popModalFromStack();
        let donationContent = (props && props.donationSettings) ? JSON.parse(props.donationSettings.donationContent || '{}') : {};
        props && props.getEventByIds({ relationshipId: props.donationSettings.relationshipId, alpideFormBuilderIdList: Object.keys(donationContent.selectedEvents || {}) || []}, props);
        props &&  props.updateState({ selectedEventData: null });
      })
      .catch(err => {
        showToasterMessage({ description: 'Some error occurred' })
      })
  }
}