import React, {Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button, InputNumber,Input, Checkbox, Progress, DatePicker } from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import { showToasterMessage, getMomentDateForUIReadOnly, validateEmail, sortByKey } from '../../utils';
import moment from 'moment-timezone';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';
import TemplatePreview from '../../containers/modal/modalBody/common/Template';
import CreatePledge from '../../containers/modal/modalBody/CreatePledge';
import * as find from 'lodash.find';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TextArea } = Input;

const FundraisingComponent = (props) => {
    const { donationSettings = {} } = props;
    const insertNumber = (props, val) => {
        if (props.amount) {
            let amount = props.amount.slice(0, props.amountCursor) + val + props.amount.slice(props.amountCursor);
            props.updateState({ amount: amount, amountCursor: props.amountCursor +val.length })
        } else {
            props.updateState({ amount: val, amountCursor: props.amountCursor + val.length })
        }
    }
    return (
        <div className='main-container '>
            {!props.selectedCampaign ?
                <Row style={{ textAlign: 'left' }}>
                    <Col className="dark-ins" span={24}>
                        <div>

                        <span className="medl-hed">
                            {/* Where do you want to donate? */}
                            Fundraising
                        </span>

                            <ul className="overscrol">
                                { (props.campaignList && props.campaignList.length) ?
                                    (sortByKey(Object.values(donationSettings.selectedFundraising || {}) || [],'sNo')).map((campObj, i) => {

                                        const campaign = find(props.campaignList || [], { fundraisingCampaignId: campObj.fundraisingCampaignId }) || {};
                                        return (
                                            (campaign && campaign.fundraisingCampaignId) ?
                                                <li key={i + 1} style={{ fontSize: '20px' }} >
                                                    <Row>
                                                        <Col span={8}>
                                                            <span style={{ color: '#cecece', paddingTop: '20px', display: 'block' }} onClick={() => {
                                                                if (campaign.formBuilderId) {
                                                                    props.pushModalToStack({
                                                                        modalBody: <TemplatePreview {...props} campaignData={campaign} alpideFormBuilderId={campaign.formBuilderId} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                        title: ' ',
                                                                        // width: '50%',
                                                                        width: '100%',
                                                                        hideFooter: true,
                                                                        wrapClassName: 'modal-custom-detailtr'
                                                                    })
                                                                }
                                                            }}>{campObj.displayName || ''}</span>
                                                        </Col>
                                                        <Col span={10} style={{ marginLeft: '0px' }} >
                                                            <div className="marg-page">
                                                                <span style={{ fontSize: '30px' }}> ${Math.round(Number(campaign.totalReceived || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <span style={{ fontSize: ' 20px' }}>raised</span></span> <span style={{ float: 'right', paddingTop: '15px' }}>of ${Math.round(Number(campaign.goal || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Goal</span>
                                                                <Progress
                                                                    percent={((campaign.totalReceived + (campaign.pledgeMade - campaign.totalPledgeReceived)) / campaign.goal) * 100}
                                                                    //percent={60}
                                                                    successPercent={(campaign.totalReceived / campaign.goal) * 100}
                                                                    // successPercent={30}
                                                                    showInfo={false}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Button style={{ marginTop: '23px', marginRight: '10px' }} className='ant-btn-primary' onClick={() => {
                                                                // props.updateState({ selectedCampaign: campaign });
                                                                props.selectCampaign(campaign);
                                                                //props.resetCampaignDonorCount();
                                                               // props.getCampaignDonorCount(campaign);

                                                                // Object.assign(document.createElement('a'), {
                                                                //     target: '_blank',
                                                                //     href: `${config.UI_BASE_URL}payment-setup?t=${props.relationshipId}_${campaign.paymentGatewayId || 0}_4_${Math.floor((Math.random() * 10000) + 1)}`,
                                                                // }).click();
                                                            }}>
                                                                Donate
                                                            </Button>
                                                            <Button className='ant-btn-primary' onClick={() => {
                                                                props.pushModalToStack({
                                                                    modalBody: <CreatePledge {...props} selectedCampaign={campaign} relationshipId={(props.companyInfo || {}).relationshipId} />,
                                                                    title: 'Pledge for ' + campaign.campaignName,
                                                                    width: '40%',
                                                                    textAlign: 'center',
                                                                    hideFooter: true,
                                                                    wrapClassName: 'modal-custom-detailtr2'
                                                                })
                                                            }}>Pledge</Button>
                                                        </Col>
                                                    </Row>
                                                </li> : ''
                                        )
                                    }) : ''
                                }
                                </ul>
                            <div className="clearfix"></div>
                        </div>


                    </Col>
                </Row>
                :
                <Row style={{ textAlign: 'left',  }}>
                    <Col className="dark-bluec" span={12} style={{  paddingTop:'15px' }} >
                        

                        <div style={{textAlign:'center',color:'#fff'}}><span style={{padding:'15px 15px 0px 15px', fontSize:'20px'}}>{props.selectedCampaign.campaignName}</span></div>
                        <div className="rais-prog" style={{padding:'0px 15px 0px 15px', color:'#a0a2c0'}}>
                            <br />
                            <span style={{fontSize:'30px'}}> $<b>{Math.round(props.selectedCampaign.totalReceived)}</b></span> <span style={{  }}> raised of ${Math.round(props.selectedCampaign.goal)} Goal</span>
                            <Progress
                                percent={((props.selectedCampaign.totalReceived + (props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived)) / props.selectedCampaign.goal) * 100}
                                //percent={60}
                                successPercent={(props.selectedCampaign.totalReceived / props.selectedCampaign.goal) * 100}
                                //successPercent={30}
                                showInfo={false}
                            />
                           
                        </div>
                        <div className="clearfix"></div>
                       
                        <Row>
                        <Col span={24} style={{padding:'30px 0px 20px 0px', textAlign:'center'}}> 
                         <Row>
                            <Col span={12}>
                                <div className="cirt">
                                <b style={{color:'#128371', fontSize:'30px' }}> {props.selectedCampaign.totalDonors || 0}</b>
                            <br/>
                           Total Donors
                           </div>
                            </Col>
                            <Col span={12}>
                            <div className="cirt">
                                <b style={{color:'#128371', fontSize:'30px' }}> {props.selectedCampaign.pledgeCount || 0}</b>
                            <br/>
                            Total Pledgees
                            </div>
                            </Col>
                        </Row>
                        
                        
                         </Col>
                        {/* <Col span={24}>
                        <div style={{  }} className="bg-carve">
                            <Row style={{ textAlign: 'left' }}>
                                <Col span={12}>
                                    <div style={{fontSize:'18px'}}>  Total Received  </div>
                                    <div className="drtys"> <span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}><i className="fa fa-percent"></i> </span> <span style={{padding:'10px 0px 0px 0px', display:'block'}}>{((props.selectedCampaign.totalReceived / props.selectedCampaign.goal) ||  0 * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  </span>
                                    <div style={{clear:'both'}}></div>
                                    </div>
                                    <div className="drtys">  <div style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}><i className="fa fa-usd"></i> </div> <span style={{padding:'10px 0px 0px 0px', display:'block'}}>{(props.selectedCampaign.totalReceived || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    <div style={{clear:'both'}}></div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{fontSize:'18px', paddingTop:'0px'}}>  Projected Total </div>
                                    <div className="drtys"> <span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}><i className="fa fa-percent"></i></span>  <span style={{padding:'10px 0px 0px 0px', display:'block'}}>{(props.selectedCampaign.totalReceived + (props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived) || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    <div style={{clear:'both'}}></div>
                                    </div>
                                    <div className="drtys"> <span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}> <i className="fa fa-usd"></i></span> <span style={{padding:'10px 0px 0px 0px', display:'block'}}>{(((props.selectedCampaign.totalReceived + (props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived)) / props.selectedCampaign.goal) || 0* 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    <div style={{clear:'both'}}></div>
                                    </div>
                                </Col>

                            </Row>
<div style={{background:'#b4cac7', height:'1px', width:'70%', margin:'30px auto 20px'}}></div>
                           <div style={{ margin:'0px 20% 20px'}}>
                            <div style={{padding:'10px 0px', textAlign:'center',fontSize:'18px'}}>  Pledge Details  </div>
                            <Row className="drtys">
                                <Col span={16}><span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}>  <i className="fa fa-heart"></i></span><span style={{padding:'10px 0px 0px 0px', display:'block'}}> Pledge Made </span></Col>
                                <Col span={8} style={{paddingTop:'10px'}}> <i className="fa fa-usd"></i>{props.selectedCampaign.pledgeMade || 0}</Col>
                            </Row>

                            <Row className="drtys">
                                <Col span={16}> <span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}> <i className="fa fa-money"></i></span> <span style={{padding:'10px 0px 0px 0px', display:'block'}}>Pledge Received </span></Col>
                                <Col span={8} style={{paddingTop:'10px'}}> <i className="fa fa-usd"></i>{props.selectedCampaign.totalPledgeReceived || 0}</Col>
                            </Row>

                            <Row className="drtys">
                                <Col span={16}><span style={{float:'left', borderRadius:'8%', border:'solid 1px #91cec4', height:'35px', width:'35px', background:'#fff', textAlign:'center', lineHeight:'35px',color:'#000', margin:'5px 5px 5px 0px'}}>  <i className="fa fa-circle-o-notch"></i></span> <span style={{padding:'10px 0px 0px 0px', display:'block'}}>Outstanding Pledge</span></Col>
                                <Col span={8} style={{paddingTop:'10px'}}> <i className="fa fa-usd"></i>{(props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived) || 0}</Col>
                            </Row>
                            </div>
                        </div>
                        </Col> */}
                        </Row>
                       
                    </Col>
                    <Col span={12} className="calco" style={{  paddingTop:'15px' }}>
                        <Row>
                            <Col span={24} className="top-heade">

                                Select an Amount to Donate

                            </Col>
                        </Row>
                        <Row>
                                <Col span={2}></Col>
                                <Col span={5} className='culb'>
                                    <Button className={props.amount === '20' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ amount: '20', amountCursor: 2 });
                                    }}><span className='doloers'>$</span> 20</Button>
                                </Col>
                                <Col span={5} className='culb'>
                                    <Button className={props.amount === '50' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ amount: '50', amountCursor: 2 });
                                    }}><span className='doloers'>$</span> 50</Button>
                                </Col>
                                <Col span={5} className='culb'>
                                    <Button className={props.amount === '100' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ amount: '100', amountCursor: 3 });
                                    }}> <span className='doloers'>$</span> 100</Button>
                                </Col>
                                <Col span={5} className='culb'>
                                    <Button className={props.amount === '200' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ amount: '200', amountCursor: 3 });
                                    }}><span className='doloers'>$</span> 200</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row>
                                <Col span={24} className="top-heade">
                                    or enter your amount
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3}></Col>
                                <Col span={18} >
                                    <Input
                                        className='mart'
                                        value={props.amount}
                                        onClick={(e) => {
                                            props.updateState({ amountCursor: e.target.selectionStart });
                                        }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                                            if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                                                props.updateState({
                                                    amount: value,
                                                    amountCursor: e.target.selectionStart,
                                                });
                                            }
                                        }}
                                    ></Input>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3}></Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '1');
                                            //  props.updateState({ amount: (props.amount + '1') });
                                        }}>1</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '2');
                                            // props.updateState({ amount: (props.amount + '2') });
                                        }}>2</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '3');
                                        }}>3</span></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '4');
                                        }}>4</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '5');
                                        }}>5</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '6');
                                        }}>6</span></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '7');
                                        }}>7</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '8');
                                        }}>8</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '9');
                                        }}>9</span></Col>
                                    </Row>
                                    <Row>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            if (props.amount && props.amount.indexOf('.') < 0) {
                                                let amount = props.amount.slice(0, props.amountCursor) + '.' + props.amount.slice(props.amountCursor);
                                                props.updateState({ amount: amount, amountCursor: props.amountCursor + 1 })
                                            } else if (!props.amount) {
                                                props.updateState({ amount: '0.', amountCursor: props.amountCursor + 2 })
                                            }
                                        }}><b>.</b></span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            insertNumber(props, '0');
                                        }}>0</span></Col>
                                        <Col span={8} className='cul-text'><span onClick={() => {
                                            let num = props.amount;
                                            props.updateState({ amount: num.substring(0, num.length - 1) });
                                        }}>
                                            <div className="back-arow"></div>
                                        </span></Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={2}></Col>
                                <Col span={19}>
                                    <Row>
                                        <Col span={8} className='culbb'>
                                            <Button className={props.duration === 'oneTime' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                                props.updateState({ duration: 'oneTime' });
                                            }}>One-Time</Button>
                                        </Col>

                                        {/* <Col span={5} className='culbb'>
                                            <Button className={props.duration === 'daily' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                                props.updateState({ duration: 'daily' });
                                            }}>Daily</Button>
                                        </Col> */}
                                        <Col span={8} className='culbb'>
                                            <Button className={props.duration === 'weekly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                                props.updateState({ duration: 'weekly' });
                                            }}>Weekly</Button>
                                        </Col>
                                        <Col span={8} className='culbb'>
                                            <Button className={props.duration === 'monthly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                                props.updateState({ duration: 'monthly' });
                                            }}> Monthly
                                            </Button>
                                        </Col>
                                    </Row>
                                    {donationSettings.isTransactionFeeApply && donationSettings.transactionFeePercent ?
                                    <div style={{ float: 'left', marginLeft: '21px',paddingBottom:'10px' }} className="new-ched">
                                        <Checkbox checked={(donationSettings.isTransactionFeeRequired === 1 || props.isTransactionFeeApplyByDefault === 1)} onChange={(e) => {
                                            props.updateState({
                                                isTransactionFeeApplyByDefault: e.target.checked ? 1 : 0,
                                            })
                                        }} />  I'd like to help cover the transaction fee of {donationSettings.transactionFeePercent}%
                                    </div> : ''
                                    
                                }
                                    {props.duration && props.duration !== 'oneTime' ?
                                        <Row>
                                            <Col span={10} className="noty">
                                                No. of Payment(s)
                                            </Col>
                                            <Col span={14} className="new-ched">
                                                {!props.neverExpires && <div style={{float:'left', textAlign:'left'}}>
                                                    <InputNumber className=' bity' style={{
                                                        width: '70', background: 'initial', fontSize: '16px', borderBottom: 'solid 1px #cecece',
                                                        marginBottom: '15px'
                                                    }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                        props.updateState({ noOfPayment: value })
                                                    }} /></div>
                                                }
                                                <div style={{float:'left', marginLeft:'10px'}}>
                                                <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                    props.updateState({
                                                        neverExpires: e.target.checked ? 1 : 0,
                                                    })
                                                }} /> Never Expires
                                                </div>
                                            </Col>
                                            <Col span={10} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                                Next Payment Date
                                            </Col>
                                            <Col span={14} className="new-ched">
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    style={{ width: 180 }}
                                                    // disabledDate={invalidEndDate}
                                                    key={moment(new Date(), format)}
                                                    defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                    onChange={(selectedDate) => {
                                                        props.updateState({
                                                            paymentStartDate: selectedDate
                                                        })
                                                    }} />
                                            </Col>
                                        </Row> : ''}
                                        {/* {donationSettings.isDisplayNameOrEmail ? */}
                                    <Row>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Name
                                        </Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.firstName} placeholder="First Name" onChange={(e) => {
                                                props.updateState({ firstName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{
                                                width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'
                                            }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
                                                props.updateState({ lastName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Email
                                        </Col>
                                        <Col span={17} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.email} placeholder="Email" onChange={(e) => {
                                                props.updateState({ email: e.target.value })
                                            }} />
                                        </Col>
                                    </Row> 
                                    {/* : ''
                                } */}
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3} style={{ padding: '10px' }}>
                                    {/* Memo Text */}
                                    </Col>
                                <Col span={18} >
                                    <TextArea
                                        rows={2}
                                        value={props.remarks}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            props.updateState({
                                                remarks: value,
                                            });
                                        }}
                                    ></TextArea>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3}></Col>
                                <Col span={18} className='culbs'>
                                    <Button onClick={() => {
                                        if (!props.amount || !Number(props.amount)) {
                                            return showToasterMessage({ description: 'Please enter amount' });
                                        }
                                        if (!props.duration) {
                                            return showToasterMessage({ description:"Please select a payment duration"});
                                        }
                                        if (!props.neverExpires && !props.noOfPayment) {
                                            return showToasterMessage({ description: 'Please enter No. of Payment' });
                                        }
                                    
                                    //if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.firstName || !props.lastName)) {
                                    if (props.duration && props.duration !== 'oneTime' && (!props.firstName || !props.lastName)) {
                                        return showToasterMessage({ description: "Please enter first name or last name" });
                                    }
                                    // if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.email || !validateEmail(props.email))) {
                                    if (props.duration && props.duration !== 'oneTime' && (!props.email || !validateEmail(props.email))) {
                                        return showToasterMessage({ description: "Please enter a valid email" });
                                    }
                                            const modalData = {
                                                title: <React.Fragment>
                                                    <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                                                        {props.selectedCampaign.campaignName || 'Donate'}
                                                    </div>
                                                </React.Fragment>,
                                                modalBody: <CardSwipe {...props} />,
                                                width: 600,
                                                hideFooter: true
                                            };
                                            props.pushModalToStack(modalData);
                                      
                                    }}>Donate Now</Button>
                                </Col>
                            </Row>
                        </Col>
                      
                </Row>
            }
        </div>
    )

};

export default FundraisingComponent;
