import React from 'react';
import { Switch } from 'react-router-dom';
import './index.scss';
import PrivateRoute from './privateRoute';
import Donation from '../containers/Donation';
//import DonationWeb from '../containers/DonationWeb';
import Fundraising from '../containers/Fundraising';
import Registration from '../containers/Registration';
import EventListing from '../containers/EventListing';
import Subscribe from '../containers/Subscribe';
import PledgePayment from '../containers/PledgePayment';
import SponsorshipComp  from '../containers/Sponsorship';

const PartialViewRoute = (props) => {
    const { isCollapsed } = props;
    return (
        <div className={`view-container-wrapper ${isCollapsed ? 'expendedView' : ' normalContainer'}`}>
            <Switch>
                <PrivateRoute {...props}
                    exact
                    path='/donation'
                    component={Donation}
                />
                {/* <PrivateRoute {...props}
                    exact
                    path='/web-donation'
                    component={DonationWeb}
                /> */}
                <PrivateRoute {...props}
                    exact
                    path='/fundRaising'
                    component={Fundraising}
                />
                <PrivateRoute {...props}
                    exact
                    path='/register-member'
                    component={Registration}
                />
                <PrivateRoute {...props}
                    exact
                    path='/open-events'
                    component={EventListing}
                />
                 <PrivateRoute {...props}
                    exact
                    path='/subscribe'
                    component={Subscribe}
                />
                  <PrivateRoute {...props}
                    exact
                    path='/pledgePayment'
                    component={PledgePayment}
                />
                <PrivateRoute {...props}
                    exact
                    path='/sponsorship'
                    component={SponsorshipComp}
                />
            </Switch>
        </div>
    );
};

export default PartialViewRoute;
