import CommonReducer from './CommonReducer';
import DonationReducer from './DonationReducer';
import FundraisingReducer from './FundraisingReducer';
import { ModalReducer, StackModalReducer } from './ModalReducer';
import RegistrationReducer from './RegistrationReducer';

const reducers = {
  common: CommonReducer,
  donation: DonationReducer,
  modal: ModalReducer,
  stackModal: StackModalReducer,
  fundraising:FundraisingReducer,
  registration: RegistrationReducer
}

export default reducers;