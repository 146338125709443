import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export class LogoComponent extends React.Component {
    constructor(props){
        super(props);
        this.state={
            src: props.src
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        return this.state.src !== this.props.src; 
     }

    render() {
        return <div>
            {
                <Avatar shape='square' size={100} icon={<UserOutlined />}
                src={this.state.src} />
            }
        </div>
    }
} 