import { REGISTRATION_ACTION_LIST } from '../static/constants';
const initialState = {
  totalStudentCount: 0,
  totalStudentCountByGrade: {},
  registrationFormSetting: {}
};

const RegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_ACTION_LIST.REGISTRATION_FORM_SETTING:
      return { ...state, registrationFormSetting: action.data };
    case REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE:
      return { ...state, totalStudentCountByGrade: action.data };
    case REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT:
      return { ...state, totalStudentCount: action.data };
    default:
      return state;
  }
};

export default RegistrationReducer;