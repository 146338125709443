import React, { useState, Fragment } from "react";
import { Row, Col, Button, InputNumber,Input, Form, Select, Tooltip } from "antd";
import { TextBox } from "../../../general/TextBox";
//import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { TextArea } from "../../../general/TextAreaComponent";
import { CustomButton } from '../../../general/CustomButton';
import { Dropdown } from '../../../general/Dropdown';
import { showToasterMessage, validateEmail } from '../../../../utils';
import { CONSTANTS } from '../../../../static/constants';
import ReactHtmlParser from 'react-html-parser';
import { LogoComponent } from '../../../general/LogoComponent';
import config from '../../../../config/environmentConfig';
import find from "lodash.find";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Option } = Select;

const RSVPCompo = (props) => {
    const { intl, formSetting = {}, companyInfo } = props;
  

    return (
        <div style={{ padding: '0px 0px 0px' }} >
           <Row style={{ textAlign: 'left' }}>
                    <Col span={24}>
                        <div style={{textAlign: 'center', width: '100%'}}>
                            <div style={{ textAlign: 'center', paddingBottom: '20px', paddingTop: '0px' }}>
                                <h3  style={{ textAlign: 'center' }}> <b>RSVP to {props.selectedEventData.templateName}</b></h3>
                            </div>

                            <div className='pay-text'>
                                <Form>
                                    <div className="information pading-rii"  >
                                        <div className=" ">

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                    First Name<span className="required">*</span>
                                                </Col>
                                                <Col span={10} className="input-currency-control" >
                                                    <Input style={{ width: '100%' }} value={props.firstName} placeholder="" onChange={(e) => {
                                                        props.updateState({ firstName: e.target.value });
                                                    }}
                                                        className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                    Last Name
                                                </Col>
                                                <Col span={10} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.lastName} placeholder="" onChange={(e) => {
                                                        props.updateState({ lastName: e.target.value })
                                                    }} />
                                                </Col>
                                            </Row>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                    Email<span className="required">*</span>
                                                </Col>
                                                <Col span={10} className="input-currency-control">
                                                    <Input style={{ width: '100%' }} value={props.emailAddress} placeholder="Email" onChange={(e) => {
                                                        props.updateState({ emailAddress: e.target.value })
                                                    }}
                                                        className={props.invalidEmailClass}
                                                        validator={(val) => { return validateEmail(val); }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                    <span>Phone Number </span>
                                                <Tooltip placement="top" title='Phone number must be number and 10 digit without space' trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </Col>
                                                <Col span={10} className="input-currency-control ltiy">
                                                <span className="currency-tag" style={{ paddingTop: '3px' }}>
                                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                                </span>
                                                    <TextBox maxLength={10} style={{ width: '100%' }} value={props.phoneNumber} placeholder="" onChange={(e) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (e.target.value === '' || re.test(e.target.value)) {
                                                            props.updateState({
                                                                phoneNumber: e.target.value,
                                                            });
                                                        }
                                                       // props.updateState({ phoneNumber: e.target.value })
                                                    }} />
                                                </Col>
                                            </Row>

                                            <Row className="pb10">
                                                <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                    Are you attending?
                                                </Col>
                                                <Col span={10} className="input-currency-control ntiu">
                                                    <Select onChange={(value) => {
                                                        props.updateState({
                                                            attendingStatus: value,
                                                        })
                                                    }}
                                                        className={(props.submittedOnce && !props.attendingStatus) ? 'input-text-error' : ''}
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        optionFilterProp="children"
                                                        value={props.period}>
                                                        <Option value="attending">Attending</Option>
                                                        <Option value="tentative">Tentative</Option>
                                                        <Option value="declined">Declined</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                            {props.attendingStatus === 'attending' && <div>
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                        Number of Adult<span className="required">*</span>
                                                    </Col>
                                                    <Col span={10} className="input-currency-control ntiu" >
                                                        <InputNumber style={{ width: '100%',fontSize:'15px' }} value={props.numberOfAdult} placeholder="" onChange={(val) => {
                                                            props.updateState({ numberOfAdult: val })
                                                        }}
                                                            className={props.invalidNumberOfAdultClass}
                                                            validator={(val) => { return (props.numberOfAdult !== 0 && !props.numberOfAdult); }}
                                                        />
                                                        {/* <ErrorMsg
                                               validator={() => { return !props.submittedOnce || !!props.emailAddress; }}
                                               message={props.intl.formatMessage(
                                                 {
                                                     id: 'contact.email.message',
                                                     defaultMessage: '.'
                                                 })
                                             }
                                             /> */}
                                                    </Col>
                                                </Row>
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                        Number of Child
                                                    </Col>
                                                    <Col span={10} className="input-currency-control ntiu">
                                                        <InputNumber style={{ width: '100%',fontSize:'15px'   }} value={props.numberOfChild} placeholder="" onChange={(val) => {
                                                            props.updateState({ numberOfChild: val })
                                                        }}
                                                        />
                                                        {/* <ErrorMsg
                                               validator={() => { return !props.submittedOnce || !!props.numberOfChild; }}
                                               message={props.intl.formatMessage(
                                                 {
                                                     id: 'contact.email.message',
                                                     defaultMessage: '.'
                                                 })
                                             }
                                             /> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                            }
                                            {(props.attendingStatus && props.attendingStatus !== 'attending') &&
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right" style={{textAlign:'right', paddingRight:'10px'}}>
                                                        Notes
                                                    </Col>
                                                    <Col span={10} className="input-currency-control " style={{textAlign:'left'}}>
                                                        <TextArea
                                                            className='martm'
                                                            placeholder="Enter Remarks"
                                                            value={props.remarks}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                props.updateState({
                                                                    remarks: value,
                                                                });
                                                            }}
                                                            autoSize={{ minRows: 1, maxRows: 4 }}
                                                        ></TextArea>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </div>
                                </Form>
                                <div style={{borderBottom: '1px solid #f0f0f0', width:'107%', margin:'10px -20px'}}></div>
                                <Button className='ant-btn ant-btn-primary' style={{marginLeft:'-17px'}} onClick={() => {
                                    props.updateState({
                                        submittedOnce: true,
                                        invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',
                                        invalidNumberOfAdultClass: (props.attendingStatus === 'attending' && (props.numberOfAdult !== 0 && !props.numberOfAdult)) ? 'input-text-error' : ''

                                    });
                                    if (!props.firstName || !props.emailAddress || !props.attendingStatus || (props.attendingStatus === 'attending' && !props.numberOfAdult)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Required field is missing'
                                        });;
                                    }
                                    if (props.emailAddress && !validateEmail(props.emailAddress)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Incorrect Email'
                                        });;
                                    }
                                    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10 ) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Phone number must be number and 10 digit without space'
                                        });
                                    }

                                let payload = {
                                    relationshipId: props.selectedEventData.relationshipId,
                                    alpideFormBuilderId: props.selectedEventData.alpideFormBuilderId,
                                    firstName: props.firstName,
                                    lastName: props.lastName,
                                    email: props.emailAddress,
                                    phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? '+' + props.companyInfo.countryCallingCode + props.phoneNumber : props.phoneNumber,
                                    adultAttending: props.attendingStatus === 'attending' ? props.numberOfAdult || 0 : 0,
                                    childAttending: props.attendingStatus === 'attending' ? props.numberOfChild || 0 : 0,
                                    remarks: props.remarks,
                                    isAttending: props.attendingStatus === 'attending' ? 1 : 0,
                                    isTentative: props.attendingStatus === 'tentative' ? 1 : 0,
                                    isDeclined: props.attendingStatus === 'declined' ? 1 : 0,
                                }
                                    props.saveOrUpdate(payload, props);
                                }}>Submit</Button>
                            </div>
                        </div>

                    </Col>
                    
                      
                </Row>
        </div>
    )
};

export default RSVPCompo;
