import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreatePledgeComp from '../../../../components/modal/modalBody/CreatePledge';
import { saveOrUpdate } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchPaymentModes } from '../../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../../static/constants';
import { getAllFundraisingCampaign } from '../../../Registration/action';
import { fetchRelationshipDataByRID } from '../../../Donation/action';

class CreatePledge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      relationshipId: (props.selectedCampaign || {}).relationshipId,
      relationshipBillingAddress: {},
      selectedCampaign: props.selectedCampaign || {},
      phoneNumber: '',
      period: 'oneTimePayment' 
    }
  }

  componentDidMount() {
    const payload = {
      relationshipId: this.state.relationshipId
    };
    this.props.fetchRelationshipDataByRID(payload, this.props);
    this.props.getAllFundraisingCampaign(payload, this.props);
    this.props.fetchPaymentModes(payload, this.props);
  }

  componentWillReceiveProps(props) {
    if (props.companyInfo && props.companyInfo.relationshipId) {
        const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 }) || {};
        this.setState({
            relationshipBillingAddress,
            companyInfo:props.companyInfo
        })
    }
    if (props.allCampaignList || props.paymentModeList) {
      this.setState({
        allCampaignList: props.campaignList,
        paymentModeList: props.paymentModeList,
      })
  }
}

  render() {
    console.log("this.props ::::::::  ", this.props);
    return (
      <CreatePledgeComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.relationshipData,
    relationshipId: state.common.relationshipData?.relationshipId,
    donationSettings: state.donation.donationSettings,
    allCampaignList: state.fundraising.campaignList,
    paymentModeList: state.common.paymentModeList
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  saveOrUpdate,
  fetchRelationshipDataByRID,
  getAllFundraisingCampaign,
  fetchPaymentModes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreatePledge);
