import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../static/constants'
//import { showToasterMessage } from '../../utils';

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.RELATIONSHIP_DATA, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId: data.relationshipId, source :payload.source }
        props.fetchDonationSetting(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        console.log(err, "payload")
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


// export const fetchDonationSetting = (payload) => {
//   return dispatch => {
//       return axios
//         .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/donationSettings/getDonationSettings?relationshipId=${payload.relationshipId}`)
//         .then(res => {
//           const { data } = res
//           dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING, data });
//         })
//         .catch((err) => {
//           if(!err.__isRetryRequest){
//             //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//           }else{
//             err.actionToCall = DONATION_ACTION_LIST.DONATION_SETTING;
//           }
//         })
//     }
// }


export const getAuthNetPaymentUrl = (props, payload, recData) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAuthNetPaymentUrl?relationshipId=${props.relationshipId}&amount=${props.amount}&projectMasterId=${payload.projectMasterId}`)
      .then(res => {
        let paymentUrl = res.data+`&callback_uri=${window.location.origin}/donation?projectMasterId=${payload.projectMasterId}&projectMasterId=${payload.projectMasterId}&alpidePaymentGatewayId=${payload.alpidePaymentGatewayId}&project_name=${payload.projectName}`
        if(props.duration !== 'oneTime'){
          paymentUrl = paymentUrl+`&subscription_name=${recData.subscriptionName}&intervalLength=${recData.intervalLength}&unit=${recData.unit}&start_date=${recData.startDate}&totalOccurrences=${recData.totalOccurrences}`
        }
        console.log(paymentUrl);
        window.location = (paymentUrl);
        //window.open(res.data);
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
        } else {
          
        }
      })
  }
}


export const processCardPayment = (props, payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER});
    return axios
      .post(`${config.API_BASE_URL}/alpide-an/payment/processPayment`, payload, {
        headers: {
          'content-type': 'application/json'
        },
        cors: true,
        contentType: 'application/json',
        secure: true,
      })
      .then(res => {
        cb && cb(res.data);
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER});
        //window.open(res.data);
      })
      .catch((err = {}) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
        }
      })
  }
}


export const emailReceipt = (props, payload, ref) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER});
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailManager/emailPaymentReceipt`, payload)
      .then(res => {
        //window.open(res.data);
        ref && ref.setState({emailSentSuccess: true});
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER});
      })
      .catch((err = {}) => {
      //  ref && ref.setState({emailSentSuccess: true});
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
        }
      })
  }
}