import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, FUNDRAISING_ACTION_LIST, lang } from '../../static/constants'
import { showToasterMessage } from '../../utils';

export const doesAccountExist = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/email/doesAccountExist?relationshipId=${payload.relationshipId}&email=${payload.emailAddress}`)
      .then(res => {
        const { data } = res
        dispatch({ type: FUNDRAISING_ACTION_LIST.EMAIL_VERIFICATION, data });
        dispatch({ type: FUNDRAISING_ACTION_LIST.IS_EMAIL_SENT, data: true });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || ((err.response || {}).data || {}).message});
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


export const getPledgePayments = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/pledge/getPledgeByEmail?relationshipId=${payload.relationshipId}&email=${payload.emailAddress}`)
      .then((res) => {
        const { data } = res
        dispatch({ type: FUNDRAISING_ACTION_LIST.PLEDGE_PAYMENT, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || ((err.response || {}).data || {}).message });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = FUNDRAISING_ACTION_LIST.PLEDGE_PAYMENT;
        }
      })
  };
};

export const selectPledge = (pledge) => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.SELECTED_PLEDGE, data: pledge });
  }
}
export const emailVerified = (isEmailVerified) => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.IS_EMAIL_VERIFIED, data: isEmailVerified });
  }
}

// export const getCampaignDonorCount = (payload) => {
//   return (dispatch) => {
//     return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/getCampaignDonorCount?relationshipId=${payload.relationshipId}&campaignId=${payload.fundraisingCampaignId}`)
//       .then((res) => {
//         const { data } = res
//         dispatch({ type: FUNDRAISING_ACTION_LIST.DONOR_COUNT, data });
//       })
//       .catch((err) => {
//         console.log(err, "payload")
//         if (!err.__isRetryRequest) {
//          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = FUNDRAISING_ACTION_LIST.DONOR_COUNT;
//         }
//       })
//   };
// };

export const resetEmailVarification = () => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.RESET_EMAIL_VERIFICATION });
    dispatch({ type: FUNDRAISING_ACTION_LIST.IS_EMAIL_SENT, data: false });
  }
};
export const resetPaymentPledgeState = () => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.RESET_PLEDGE_PAYMENT_STATE });
  }
};

export const setEmailToState = (email) => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.SET_EMAIL, data: email });
  }
}