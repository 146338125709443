import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, FUNDRAISING_ACTION_LIST } from '../../static/constants'
//import { showToasterMessage } from '../../utils';



export const getSponsorshipByIds = (payload) => {
  return (dispatch) => {
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sponsorship/getSponsorshipByIds?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0&sponsorshipMasterIdInList=${payload.sponsorshipMasterIdInList}`)
      .then((res) => {
        const { data } = res
        dispatch({ type: FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST, data });
      })
      .catch((err) => {
        console.log(err, "payload")
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST;
        }
      })
  };
};

export const selectSponsorship = (sponsorship) => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.SELECTED_SPONSORSHIP, data: sponsorship });
  }
}
