import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Input } from 'antd';
import { showToasterMessage } from '../../../../../utils';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import IdleTimer from 'react-idle-timer'

let timeInterval;
let minCounter = 0;
let secondCounter = 30;
class PaymentSuccessComponent extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.state = {
      askEmail: false,
      isBlink: true,
      minCounter: minCounter,
      secondCounter: secondCounter
    }
    timeInterval = setInterval(() => {
      if (!this.state.secondCounter && !this.state.minCounter) {
        this.props.popModalFromStack();
        clearInterval(timeInterval);
      }
      else if (!this.state.secondCounter) {
        this.setState({
          minCounter: this.state.minCounter - 1,
          secondCounter: 59,
          isBlink: !this.state.isBlink
        });
      } else if (this.state.secondCounter || this.state.minCounter) {
        this.setState({
          minCounter: this.state.minCounter,
          secondCounter: this.state.secondCounter - 1,
          isBlink: !this.state.isBlink
        });
      }
    }, 1000)
    props.updateState({ ...this.props.data?.formData });
  }

  _onAction(e) {
   // console.log('user did something', e);
    this.setState({
      minCounter: minCounter,
      secondCounter: secondCounter,
      isBlink: !this.state.isBlink
    });
  }

  _onActive(e) {
   // console.log('user is active');
    this.setState({
      minCounter: minCounter,
      secondCounter: secondCounter,
      isBlink: !this.state.isBlink
    });
  //  console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    // console.log('user is idle');
    this.props.popModalFromStack();
    // this.props.showModal(
    //     {
    //         title: <div className='ant-modal-title'>Need More Time?</div>,
    //         maskClosable: true,
    //      //   modalBody: <SessionTimeoutComponent {...this.props} />,
    //         hideFooter: true
    //     }
    // );
    //console.log('last active', this.idleTimer.getLastActiveTime())
  }
  render() {
    const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
    return (
      <Fragment>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          // timeout={5000} />
          timeout={1000 * ((minCounter*60)+secondCounter)} />
        <div style={{ padding: '16px', textAlign: 'center', }}>
          Popup will close in  <b><i className={'fa fa-clock-o'}></i> {this.state.minCounter}{this.state.isBlink ? ":" : " "}{this.state.secondCounter}</b>
        </div>
        {!this.state.emailSentSuccess ?
          <div>
            <div className='text-middle'>
              <Spin indicator={antIcon} spinning={this.props.common?.isLoading} />
            </div>
            <div style={{ padding: '16px', textAlign: 'center', }}> Your payment of ${Number(this.props.response?.amount || 0).toFixed(2)} processed successfully</div>
            <div style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => {
                this.setState({ askEmail: true });
              }}>
                <i className="fa fa-envelope" ></i> <u>Email Payment Receipt </u></span>
            </div>
            {this.state.askEmail ?
              <div style={{ padding: '16px', textAlign: 'center' }}>
                <Row>
                  <Col span={6}> Email
                  </Col>
                  <Col span={11}>
                    <Input autoFocus value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                  </Col>
                </Row>
                <Row style={{ padding: '16px', textAlign: 'center' }}>
                  <button type='primary' className="done-close" onClick={() => {
                    if (!this.state.email) { return showToasterMessage({ description: 'Please Enter Email Address' }) }
                    this.props.emailReceipt(this.props, { toEmail: this.state.email, relationshipId: this.props.relationshipId, transactionId: this.props.response.transactionId, paymentSource: this.props.source || 'kiosk', customerPaymentId: this.props.response.customerPaymentId }, this);
                    //this.props.popModalFromStack();
                  }
                  }>
                    Send</button>
                </Row>
              </div> : ''
            }
          </div> :
          <div>
            <div style={{ padding: '16px', textAlign: 'center', }}>
              <br />
              Email is sent sucessfully
              <br /><br />
              <button type='primary' className="done-close" onClick={() => { this.props.popModalFromStack(); this.props.popModalFromStack(); }}>Done</button>
            </div>
          </div>
        }
        {/* <div style={{ padding: '16px', textAlign: 'center', }}>
          <button className="done-close" onClick={() =>{ this.props.popModalFromStack(); this.props.popModalFromStack();}}>Done</button>
          <br/>
          success popup
        </div> */}
      </Fragment >
    )
  }


}
const mapStateToProps = (state) => {
  return {
    common: state.common,
    companyInfo: state.common.relationshipData,
    relationshipId: state.common.relationshipData?.relationshipId
  }
}
export default connect(mapStateToProps)(PaymentSuccessComponent);
