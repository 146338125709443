import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MODAL_ACTIONS, MODAL_TYPE } from '../../static/constants';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';

import './modal.scss';

function CustomModal(props) {

  const cancelBtnProps = (props.footer || {}).cancel || {};
  const submitBtnProps = (props.footer || {}).submit || {};
  const [formData, updateFormData] = useState(props.formData)
  const dispatch = useDispatch();
  const bodyHtml = getBody(props, formData, updateFormData);

  const defaultTitle = '';

  return (
    <Fragment>
      {props.visible ? (<Modal
        title={props.title || defaultTitle}
        visible={props.visible}
        closable={props.isClosable}
        maskClosable={props.maskClosable === true ? false : true}
        width={props.width}
        keyboard={props.keyboard}
        wrapClassName={props.wrapClassName} //custom-modal-content, pass this classname for fixed height modal
        onCancel={() => { dispatch({ type: MODAL_ACTIONS.HIDE_MODAL }) }}
        footer={props.hideFooter ? null : [
          <div key={0} className='customModal-button-group'>
            <Button
              intlId={cancelBtnProps.intlId || 'confirmation.cancel'}
              defaultMessage={cancelBtnProps.defaultMessage || ''}
              type={cancelBtnProps.type || 'default'}
              key={1}
              onClick={() => {
                updateFormData({...formData, textBoxValue: "", submittedOnce: false});
                props.isStack ? props.onCancel((props.modalData || {}).modalType): dispatch({ type: MODAL_ACTIONS.HIDE_MODAL }) }
              }
            />
            <Button
              intlId={submitBtnProps.intlId || 'confirmation.submit'}
              defaultMessage={submitBtnProps.defaultMessage || ''}
              htmlType='submit'
              key={2}
              onClick={() => {
                document.getElementsByClassName("add-new-input-text").length && document.getElementsByClassName("add-new-input-text")[0].classList.add("input-text-error")
                if (props.formData && Object.keys(props.formData).length) {
                  props.handleSubmit({...props.formData});
                  updateFormData({...formData, textBoxValue: "", submittedOnce: false});
                } else {
                  updateFormData({...formData, textBoxValue: "", submittedOnce: false});
                  props.handleSubmit();
                  dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
                }
              }}
            />
          </div>
        ]}
      >
        {
          bodyHtml
        }

      </Modal>) : ''}
    </Fragment >
  );
}

const getBody = (props , formData, updateFormData) => {
  const { modalBody, modalData = {}, handleSubmit } = props;

  let body = <Fragment />;
  if (modalBody) {
    body = modalBody;
  } else {
    const { modalType } = modalData;
    switch (modalType) {
      case MODAL_TYPE.CARD_SWIPE:
          {
        body = <CardSwipe {...modalData} {...props} formData={formData} updateFormData={updateFormData}  handleSubmit={handleSubmit} />
        break;
      }
      default:
        break;
    }
  }
  return body;
}

export default CustomModal;