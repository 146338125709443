import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS} from '../../../../../static/constants';

export const createAddress = (payload, formData) => {
  let actionType = '';

  // Need to update switch for supplier
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/createAddress`, payload)
      .then(res => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type:  actionType, data: res.data , formData});
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      })
      .catch((err) => {
        console.log(err, "funcToCallfuncToCallfuncToCall")
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
        }else {
          err.actionToCall = actionType;
        }
      })
  }
}


export const createCardToken = (payload, cb) => {
  var data = JSON.stringify(payload)
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`https://fts-uat.cardconnect.com:443/cardsecure/api/v1/ccn/tokenize`, data,
      {
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(res => {
      console.log("res::::::::   ", res.data);
      cb && cb(res.data.token);
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // if (!err.__isRetryRequest) {
        // }else {
        //   err.actionToCall = actionType;
        // }
      })
  }
}
   //  var axios = require('axios');
   var data = JSON.stringify({
    "account": "5546232920929857",
    "cvv": "123",
    "expiry": "1023"
  });
  
  var configCall = {
    method: 'post',
    url: 'https://fts-uat.cardconnect.com:443/cardsecure/api/v1/ccn/tokenize',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(configCall)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });