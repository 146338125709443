import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardSwipeCompo from '../../../../../components/modal/modalBody/common/CardSwipe';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createAddress, createCardToken } from './action';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

class CardSwipeContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      countryName: '',
      countryId: 0,
     // selectedCampaign: props.selectedCampaign,
     isSponsorship: props.isSponsorship
    }
}
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries !== this.state.countries && nextProps.countries && !this.state.countryName) {
      let obj = nextProps.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
      if ((obj.countryName === 'United States' || obj.countryName === 'United States (USA)') && !nextProps.states.length) {
        this.props.fetchStates(obj.countryId);
       // fetchDataIfNeeded('fetchStates', 'states', this.props, obj.countryId);
      }
      this.setState({ countryName: obj.countryName || '', countryId: obj.countryId});
    }
  }

  render() {
    
    const { common: { isLoading= false } } = this.props;
    const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
    return (
      <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={isLoading} >
      <CardSwipeCompo updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} />
      </Spin>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createAddress,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  createCardToken
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardSwipeContainer);
