const configuration = {
	API_BASE_URL: 'https://service.alpidedev.com',
AWS_BASE_URL:'https://service.alpidedev.com',
PDF_API_BASE_URL: 'https://doc.alpidedev.com/api/',
INVOICE_SERVICE_BASE_URL: 'https://service.alpidedev.com',
PAYMENT_GATEWAY_BASE_URL: 'https://service.alpidedev.com',
PLAID_BASE_URL: 'https://service.alpidedev.com',
UI_BASE_URL:'https://app.alpidedev.com/',
CALLBACK_URI_MARKTPLACE_AMAZON: 'https://app.alpidedev.com/admin/sales-channels-amazon',
CALLBACK_URI_MARKTPLACE_EBAY: 'https://app.alpidedev.com/admin/sales-channels-ebay',
CALLBACK_URI_AUTH_NET: 'https://app.alpidedev.com/admin/payment-integration',

PAYMENT_SERVER_BASE_URL: 'http://app.alpidedev.com',
ENCRYPT_SECRET:'Unl0ck!!!',
API_VERSION: '',
PDF_API_VERSION: 'v1',

PAYMENT_FORM_ACTION: 'https://accept.authorize.net/payment/payment',

rootContext: 'alpide',
rootContextInvoiceService: 'alpide-invoice',
rootContextPaymentGateway: 'alpide-paypal',
rootContextPlaid: 'alpide-plaid',
rootContextTwilio: 'alpide-tcom',
rootContextSMS: 'alpide-sms',
CC_TOKENIZER_URL: 'https://fts-utt.cardconnect.com/itoke/ajax-tokenizer.html',
SSO_URL: 'https://accounts.alpidedev.com',
ROOT_DOMAIN: 'alpidedev.com',
 WP_DOMAIN: 'https://login.alpidedev.com',	
 BUCKET_NAME: {
  BO_RELATIONSHIP: 'relationship-u',
  BO_SALES_INQUIRY: "inquiry-u",
  BO_SALES_QUOTE: "sales-quote-u",
  BO_SALES_ORDER: 'sales-order-u',
  BO_CUSTOMER_INVOICE: "sales-invoice-u",
  BO_CREDIT_MEMO: "credit-memo-u",
  BO_PURCHASE_REQUEST: "purchase-request-u",
  BO_RFQ: "supplier-rfq-u",
  BO_PURCHASE_ORDER: "purchase-order-u",
  BO_INBOUND_DELIVERY: "inbound-delivery-u",
  BO_SUPPLIER_INVOICE: "purchase-invoice-u",
  BO_DEBIT_MEMO: "debit-memo-u",
  BO_SAMPLE_RESOURCES: "sample-resources-u",
  BO_INVENTORY: "inventory-u"
 }
};

export default configuration;
