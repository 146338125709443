import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Input, Tooltip, DatePicker, InputNumber, Checkbox } from 'antd';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS, PAYMENT_SOURCE_BY_ID } from '../../../../static/constants';
import { validateEmail, showToasterMessage, getCurrentDateForBackend } from '../../../../utils';
//import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { ErrorMsg } from '../../../general/ErrorMessage';
import config from '../../../../config/environmentConfig';
import { LogoComponent } from '../../../general/LogoComponent';

const { TextArea } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    Ph - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    Email - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}
function CreatePledgeComponent(props) {
    return (
        <div className='pay-box-por' style={{ padding: '0px 0px 0px',  }}>
            {/* <Row gutter={24} style={{ background: '#06033A', color: '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }}>
                { <Col span={1}></Col> }
                <Col span={4}>
                    <div className="brand-logo" style={{ marginTop: '10px' }}>
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div style={{ fontSize: '22px', paddingLeft: '20px' }}> {props.companyInfo.storeName}</div>
                </Col>
                <Col span={8} style={{ alignSelf: 'center' }}>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </Col>
            </Row> */}

            <div className="header-action-container pay-ment-box" style={{maxHeight:'600px', overflowY:'scroll'}}>

                <div>

                    {/* <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                        <h3> <b> Welcome </b></h3>
                    </div> */}

                    <div className='pay-text'>
                        <Form layout="vertical" hideRequiredMark>

                            <Row >
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8} style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <span className="">
                                    First Name
                                    </span><span className="required">*</span>
                                </Col>
                                <Col span={12}>

                                    <TextBox
                                        type='text'
                                        className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                        value={props.firstName}
                                        onChange={(e) => {
                                            props.updateState({
                                                firstName: e.target.value,
                                            });
                                        }}
                                        placeholder='First Name'
                                    />
                                    <ErrorMsg
                                        validator={() => { return !props.submittedOnce || props.firstName }}
                                        message='First name is required'/>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8} style={{ textAlign:'right' , paddingRight:'20px'}}>
                                    <span className="">
                                       Last Name
                                    </span>
                                    <span className="required">*</span>
                                </Col>
                                <Col span={12}>

                                    <TextBox
                                        type='text'
                                        className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                        value={props.lastName}
                                        onChange={(e) => {
                                            props.updateState({
                                                lastName: e.target.value,
                                            });
                                        }}
                                        placeholder='Last Name'
                                    />
                                </Col>
                            </Row>
                            <br />

                            <Row >
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8} style={{ textAlign:'right', paddingRight:'20px'}}>
                                            <span className="">
                                            Email
                                            </span>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>
                                            <TextBox
                                                type='text'
                                                className={(props.submittedOnce && !props.emailAddress) ? 'input-text-error' : ''}
                                                value={props.emailAddress}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        emailAddress: e.target.value,
                                                    });
                                                }}
                                                placeholder='Email'
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row className='newregis'>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8} style={{ textAlign:'right', paddingRight:'20px'}}>
                                            <span>Phone Number </span>
                                            <Tooltip placement="top" title='Phone number must be number and 10 digit without space' trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                        </Col>
                                        <Col span={12} className="input-currency-control">
                                            <span className="currency-tag" style={{  paddingTop:'4px' }}>
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                            <TextBox
                                                type='text'
                                                style={{ width: '80%' }}
                                                maxLength={10}
                                                value={props.phoneNumber}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            phoneNumber: e.target.value,
                                                        });
                                                    }
                                                    // props.updateState({
                                                    //     phoneNumber: e.target.value,
                                                    // });
                                                }}
                                                placeholder='Phone Number'
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                          
                            <Row  className='lrtu'>
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8} style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <span className="">
                                    Amount
                                    </span><span className="required">*</span>
                                </Col>
                                <Col span={12} className="input-currency-control">
                                    <span className="currency-tag" style={{  paddingTop:'4px' }}>
                                        {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
                                    </span>
                                    <TextBox
                                        style={{ width: '80%' }}
                                        type='number'
                                        className={(props.submittedOnce && (props.amount <= 0 || !props.amount)) ? 'input-text-error' : ''}
                                        value={props.amount || null}
                                        onChange={(e) => {
                                            props.updateState({
                                                amount: e.target.value,
                                            });
                                        }}
                                        placeholder='Amount'
                                    />
                                    <ErrorMsg
                                        validator={() => { return !props.submittedOnce || props.amount }}
                                        message='This field is required' />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8}style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <label>
                                    Payment Mode</label>
                                    {/* <span className="required">*</span> */}
                                </Col>
                                <Col span={12}>
                                    <Dropdown
                                        // className={(props.submittedOnce && !props.paymentMode) ? 'input-text-error' : ''}
                                        items={props.paymentModeList}
                                        valueKeyName='paymentModeName'
                                        optionKeyName='paymentModeId'
                                        value={props.paymentModeId}
                                        placeholder='This field is required'
                                        onSelect={
                                            (optionValue, option) => {
                                                props.updateState({
                                                    paymentModeId: option.value,
                                                    paymentMode: option.name,
                                                })
                                            }
                                        }
                                    />
                                    {/* <ErrorMsg
      validator={() => { return !props.submittedOnce || props.campaignId }}
      message={props.intl.formatMessage(
        {
          id: 'requiredField.missing.msg',
          defaultMessage: ''
        })
      } /> */}
                                </Col>

                            </Row>
                            <br />

                            <Row >
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8}style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <span className="">
                                    Payment Mode Details
                                    </span>
                                    {/* <span className="required">*</span> */}
                                </Col>
                                <Col span={12}>

                                    <TextBox
                                        type='text'
                                        //className={(props.submittedOnce && !props.paymentModeDetails) ? 'input-text-error' : ''}
                                        value={props.paymentModeDetails}
                                        onChange={(e) => {
                                            props.updateState({
                                                paymentModeDetails: e.target.value,
                                            });
                                        }}
                                        placeholder='Payment Mode Detail'
                                    />
                                    {/* <ErrorMsg
      validator={() => { return !props.submittedOnce || props.paymentModeDetails }}
      message={props.intl.formatMessage(
        {
          id: 'requiredField.missing.msg',
          defaultMessage: ''
        })
      } /> */}
                                </Col>
                            </Row>

                            <br />

                            <Row >
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8}style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <label>
                                    Fundraising Campaign</label>
                                    <span className="required">*</span>
                                </Col>
                                <Col span={12}>
                                    <Dropdown
                                        className={(props.submittedOnce && !props.campaignId) ? 'input-text-error' : ''}
                                        items={props.allCampaignList}
                                        valueKeyName='campaignName'
                                        optionKeyName='fundraisingCampaignId'
                                        value={props.campaignId}
                                        placeholder='Fundraising Campaign'
                                        onSelect={
                                            (optionValue, option) => {
                                                props.updateState({
                                                    campaignId: option.value
                                                })
                                            }
                                        }
                                    />
                                    <ErrorMsg
                                        validator={() => { return !props.submittedOnce || props.campaignId }}
                                        message='This field is required'/>
                                </Col>

                            </Row>
                            <br />

                            <Row >
                                <Col span={1}>
                                </Col>
                                <Col className="text-right" span={8} style={{ textAlign:'right', paddingRight:'20px'}}>
                                    <span className="">
                                    Remarks
                                    </span>
                                    {/* <span className="required">*</span> */}
                                </Col>
                                <Col span={12}>

                                    <TextArea
                                        type='text'
                                        style={{ width: '100%' }}
                                        // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                        value={props.remarks}
                                        onChange={(e) => {
                                            props.updateState({
                                                remarks: e.target.value,
                                            });
                                        }}
                                        placeholder='Remarks'
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row >
                                <Col span={1}>
                                </Col>
                                <Col span={8} className="text-right" style={{ textAlign: 'right', paddingRight: '20px' }}>
                                    Make Recurring
                                    <span className="required">*</span>
                                </Col>
                                <Col span={12}>
                                    <Dropdown onSelect={ (optionValue, option) => { props.updateState({  period: option.value  }) } }
                                        className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        value={props.period}
                                        items={[{ optId: 'oneTimePayment', displayName: 'One Time Payment' }, { optId: 'weekly', displayName: 'Weekly' }, { optId: 'monthly', displayName: 'Monthly' }]}
                                        //items={[{ optId: 'weekly', displayName: 'Weekly' }, { optId: 'monthly', displayName: 'Monthly' }]}
                                        valueKeyName='displayName'
                                        optionKeyName='optId' />
                                    <ErrorMsg
                                        validator={() => { return !props.submittedOnce || props.period }}
                                        message='This field is required' />
                                </Col>
                            </Row>
                                    <div>
                                        {(props.period && props.period !== "oneTimePayment") ? <div>

                                            <br />
                                            <Row >
                                                <Col span={1}> </Col>
                                                <Col span={8} className="text-right" style={{ textAlign:'right', paddingRight:'20px'}}>
                                                    <Tooltip placement="top" title='Payment Start Date'>
                                                        <span className="tooltip-title">
                                                        Payment Start Date
                                                        </span>
                                                    </Tooltip>
                                                </Col>
                                                <Col span={12}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format={format}
                                                        key={moment(new Date(), format)}
                                                        defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                        onChange={(selectedDate) => {
                                                            props.updateState({
                                                                paymentStartDate: selectedDate
                                                            })
                                                        }} />
                                                </Col>
                                            </Row>
                                            <br />
                                        </div> : ''
                                        }

                                        {(props.period && props.period !== "oneTimePayment") ? <div>
                                            <Row >
                                                <Col span={1}> </Col>
                                                <Col span={8} className="text-right" style={{ textAlign:'right', paddingRight:'20px'}}>
                                                No. of Payment(s)
                                                </Col>
                                                <Col span={12} className='tliu'>
                                                    {!props.neverExpires && <div>
                                                        <InputNumber style={{ width: '100%' }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                            props.updateState({ noOfPayment: value })
                                                        }} /></div>
                                                    }
                                                    <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                        props.updateState({
                                                            neverExpires: e.target.checked ? 1 : 0,
                                                        })
                                                    }} /> Never Expires
                                                </Col>
                                            </Row>
                                            <br />
                                        </div> : ''
                                        }
                                    </div>

                        </Form>

                        <div style={{ background: '#e4e4e4', margin: '10px auto', width: '100%', height: '1px' }}></div>
                        <Row>
                            <Col span={9}></Col>
                            <Col span={5}>
                                <button className='ant-btn ant-btn-primary' style={{ margin: '5px 0px 10px' }} onClick={() => {
                                    props.updateState({
                                        submittedOnce: true,
                                        invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',

                                    });
                                    if (!props.firstName || !props.lastName || !props.amount || !props.emailAddress || !props.period) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Required fields are missing'
                                        });;
                                    }
                                    if (props.emailAddress && !validateEmail(props.emailAddress)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Incorrect Email'
                                        });;
                                    }
                                    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Phone number must be number and 10 digit without space'
                                        });;
                                    }
                                    let payload = {
                                        relationshipId: props.relationshipId,
                                        firstName: props.firstName,
                                        lastName: props.lastName,
                                        emailAddress: props.emailAddress,
                                        cellPhone: (props.phoneNumber && props.companyInfo.countryCallingCode) ? '+' + props.companyInfo.countryCallingCode + props.phoneNumber : props.phoneNumber,
                                        amount: props.amount,
                                        paymentModeId: props.paymentModeId,
                                        paymentModeDetails: props.paymentModeDetails,
                                        campaignId: props.campaignId,
                                        pledgeId: props.pledgeId,
                                        version: props.version,
                                        dateCreated: props.dateCreated,
                                        remarks: props.remarks,
                                        campaignId: props.campaignId,
                                        source: 'kiosk',
                                        sourceCode: 7,
                                        totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
                                        startDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
                                        isRecurring: (props.period && props.period !== 'oneTimePayment') ? 1 : 0,
                                        isMonthly: 0,
                                        isWeekly: 0,
                                        isYearly: 0
                                    }
                            if (props.period === 'weekly') {
                                payload.isWeekly = 1;
                            } else if (props.period === 'monthly') {
                                payload.isMonthly = 1;
                            }else if (props.period === 'yearly'){
                                payload.isYearly = 1;
                            }
                            props.saveOrUpdate(payload, props);
                        }}>Submit</button>
                        </Col>
                        </Row>
                    </div>
                </div>


            </div>

            <div style={{ clear: 'both' }}></div>
            {/* <div className='pay-fot'>
                <Row gutter={24} style={{ background: '#e9e9e9', color: '#333', padding: '10px 0px', marginLeft: '0px', marginRight: '0px', marginTop: '15px', position: 'fixed', width: '100%', bottom: '0px' }}>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>All Rights Reserved - {props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        <i class="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}></i>
                        <i class="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}></i>
                        <i class="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}></i>
                        <i class="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}></i>
                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div> */}
        </div>
    );

}

export default CreatePledgeComponent;
