import React, {Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button, InputNumber,Input, Tooltip, Checkbox} from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import { showToasterMessage, getMomentDateForUIReadOnly, validateEmail } from '../../utils';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';
import { Dropdown } from '../general/Dropdown';
import { TextBox } from "../general/TextBox";
import * as find from 'lodash.find';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TextArea } = Input;

const SponsorshipComponent = (props) => {
    const { donationSettings = {} } = props;
    const getTotalTicketPrice = () => {
        let total = 0;
        if (props.selectedTickets) {
            (Object.keys(props.selectedTickets || {}) || []).map((ticketId, i) => {
                let ticketData = props.selectedTickets[ticketId] || {}
                total += (Number(ticketData.qtyToPurchase) * Number(ticketData.price))
            })
        }
        return total.toFixed(2);
    }
    const getTotalTickets = (list) => {
        let totalTicket = 0;
        if (list && list.length) {
            (list || []).map((ticketObj, i) => {
                totalTicket += Number(ticketObj.qtyMax);
            })
        }
        return totalTicket;
    }


    const getAddressInfoFragment = (locationId) => {
        if (!locationId) return '';

        let obj = find(props.companyInfo.boLocationList || [], { locationId: locationId }) || {};
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'> {obj.streetAddress1}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2} 
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <span className='billing-address'> 
                        {
                            ` ${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                        }
                    </span>
                    : ''
            }


        </Fragment>

        return fragmentObj;
    }

    return (
        <div className='main-container '>
            {!props.selectedSponsorship ?
                <Row style={{ textAlign: 'left' }}>
                    <Col className="dark-ins" span={24}>
                        <div>

                            <span className="medl-hed">
                                {/* Where do you want to donate? */}
                                Sponsorship
                            </span>

                            <ul className="overscrol">
                                {(props.sponsorshipList && props.sponsorshipList.length) ?
                                    (Object.values(donationSettings.selectedSponsorships || {}) || []).map((sponsObj, i) => {

                                        const sponsorship = find(props.sponsorshipList || [], { giveSponsorshipMasterId: sponsObj.giveSponsorshipMasterId }) || {};
                                        return (
                                            (sponsorship.giveSponsorshipMasterId) ?
                                                <li key={i + 1} style={{ fontSize: '18px' }} >
                                                    <Row>
                                                        <Col span={21} style={{ paddingLeft: '0px' }}>
                                                            <h2> <span style={{ paddingTop: '20px', display: 'block', color: '#fff' }} >{sponsObj.displayName || ''}</span></h2>

                                                            <div className="marg-page" style={{ paddingLeft: '0px' }} >
                                                                {sponsorship.description || ''}
                                                            </div>
                                                            <div style={{ magrin: '0px', padding: '0px', }} className="spons-box"> Start Date  <span > {getMomentDateForUIReadOnly({ date: new Date(sponsorship.saleStartDate), format })}</span></div>
                                                            <div style={{ magrin: '0px', padding: '0px' }} className="spons-box"> End Date  <span > {getMomentDateForUIReadOnly({ date: new Date(sponsorship.saleEndDate), format })}</span></div>

                                                            This sponsorship has {(sponsorship.ticketItemDetailsList || []).length} type of tickets<br />
                                                            Total Tickets - {getTotalTickets(sponsorship.ticketItemDetailsList)}
                                                            <br />
                                                            Address - {getAddressInfoFragment(sponsorship.locationId)}

                                                        </Col>
                                                        {/* <Col span={10} style={{ marginLeft: '0px' }} >
                                                            <div className="marg-page">
                                                                {sponsorship.description || ''}
                                                            </div>
                                                        </Col> */}
                                                        <Col span={3}>
                                                            <Button style={{ marginTop: '123px', marginRight: '10px', verticalAlign: 'center' }} className='ant-btn-primary' onClick={() => {
                                                                props.selectSponsorship(sponsorship);
                                                            }}>
                                                                Buy Ticket
                                                            </Button>

                                                        </Col>
                                                    </Row>
                                                </li> : ''
                                        )
                                    }) : ''
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>


                    </Col>
                </Row>
                :
                <Row style={{ textAlign: 'left',  }}>
                    <Col className="dark-bluec" span={12} style={{  paddingTop:'15px' }} >
                        <div style={{textAlign:'center',color:'#fff'}}><span style={{padding:'15px 15px 0px 15px', fontSize:'20px'}}>{props.selectedSponsorship.name}</span></div>
                        <div style={{textAlign:'center',color:'#fff', paddingBottom:'0px' , borderBottom:'solid 0px #363749'}}><span style={{padding:'15px 15px 0px 15px', fontSize:'10px'}}>{getMomentDateForUIReadOnly({ date: new Date(props.selectedSponsorship.saleStartDate), format })} - {getMomentDateForUIReadOnly({ date: new Date(props.selectedSponsorship.saleEndDate), format })}</span></div>
                        <div className="rais-prog" style={{ padding: '0px 15px 0px 15px', color: '#a0a2c0' }}>
                            <br />
                            {(props.selectedSponsorship.ticketItemDetailsList || []).map((ticketObj, i) => {
                                return (<div key={i}>
                                    <Row style={{ color:'#a0a2c0', fontSize:'18px', paddingTop: '20px',  borderTop:'solid 1px #363749'}}>
                                        <Col span={14}>
                                            <div> <h2 style={{color:'#fff'}}>{ticketObj.ticketName}</h2></div>
                                            <div style={{padding:'0px', margin:'0px'}}  className="spons-boxs">Tickets <span>{Number(ticketObj.price || 0).toFixed(2)}</span></div>
                                            {/* <div>{ticketObj.price || 0}</div> */}
                                            <div>Sales end on {getMomentDateForUIReadOnly({ date: new Date(props.selectedSponsorship.saleEndDate), format })}</div>
                                        </Col>
                                        {/* <Col span={6}>
                                            <InputNumber
                                                value={((props.selectedTickets || {})[ticketObj.giveTicketItemDetailsId] || {}).qtyToPurchase }
                                                max={ticketObj.qty}
                                                onChange={(value) => {
                                                    
                                                    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                                                    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                                                        let selectedTickets = props.selectedTickets || {};
                                                        selectedTickets[ticketObj.giveTicketItemDetailsId] = {...ticketObj , qtyToPurchase: value };
                                                        props.updateState({
                                                            selectedTickets
                                                        });
                                                    }
                                                }}
                                            ></InputNumber>
                                        </Col> */}
                                        <Col span={10}  className="inptext">
                                            <div style={{ position:'relative', height:'30px', overflow:'hidden'}}>
                                        <InputNumber 
                                                value={((props.selectedTickets || {})[ticketObj.giveTicketItemDetailsId] || {}).qtyToPurchase }
                                                max={ticketObj.qty}
                                                min={0}
                                                onChange={(value) => {
                                                    
                                                    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                                                    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                                                        let selectedTickets = props.selectedTickets || {};
                                                        selectedTickets[ticketObj.giveTicketItemDetailsId] = {...ticketObj , qtyToPurchase: value };
                                                        props.updateState({
                                                            selectedTickets
                                                        });
                                                    }
                                                }}
                                            ></InputNumber>
                                         {ticketObj.qtyMax ?  <span style={{ position: 'absolute', left:' 68px', fontSize:'14px', top:'0px', height:'32px', lineHeight:'30px',  background:'#bababa', color:'#000', padding:'0px 2px 0px 5px', width:'70%' }} >{ticketObj.qtySold || 0} sold of {ticketObj.qtyMax} tickets</span> : ''}
                                           </div>
                                        </Col>
                                    </Row>
                                </div>)
                            })
                            }

                        </div>
                        <div className="clearfix"></div>
                       
                    
                       
                    </Col>
                    <Col span={12} className="calcosp" style={{  paddingTop:'15px' }}>
                        <Row>
                            <Col span={24} className="top-heade">
                               Order Summary
                            </Col>
                        </Row>
                        <div style={{background:'#f6f6f8', padding:'10px 15px', marginBottom:'15px'}}>
                            <div className="sponscr">
                            {(Object.keys(props.selectedTickets || {}) || []).map((ticketId, i) => {
                                let ticketData = props.selectedTickets[ticketId] || {};
                                return (<Row key={'ticket'+i} style={{ padding:'10px 0px'}}>
                                    <Col span={16} >{ticketData.qtyToPurchase} X {ticketData.ticketName}</Col>
                                    <Col span={8} style={{textAlign:'right', paddingRight:'15px'}}>{Number(ticketData.qtyToPurchase * ticketData.price).toFixed(2)}</Col>
                                </Row>)
                            })
                            }
                            </div>
                            <Row style={{ borderTop: '1px solid #e5e5e5', margin: '10px 0px', padding:'10px 0px 20px'}}>
                                <Col span={16}> <b>Total</b></Col>
                                <Col span={8} style={{textAlign:'right', paddingRight:'15px'}}><b>{getTotalTicketPrice()}</b></Col>
                            </Row>
                        </div>
<div style={{position:'absolute', bottom:'0px', borderTop:'solid 1px #666',   paddingTop:'15px', background:' #eff8ff'}}>
   <div style={{ paddingRight:"15px", paddingBottom:'10px'}}>
                        <Row >
                            <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                Name <span className="required">*</span>
                            </Col>
                            <Col span={8} className="new-ched">
                                <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.firstName} placeholder="First Name" onChange={(e) => {
                                    props.updateState({ firstName: e.target.value })
                                }} />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={8} className="new-ched">
                                <Input style={{
                                    width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'
                                }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
                                    props.updateState({ lastName: e.target.value })
                                }} />
                            </Col>
                            <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                Email <span className="required">*</span>
                            </Col>
                            <Col span={17} className="new-ched">
                                <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.email} placeholder="Email" onChange={(e) => {
                                    props.updateState({ email: e.target.value })
                                }} />
                            </Col>
                            <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                <span>Phone Number </span>
                                <Tooltip placement="top" title='Phone number must be number and 10 digit without space' trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </Col>
                            <Col span={17} className="new-ched input-currency-control">
                            <span className="currency-tag" style={{ padding: '4px 0px  0px',  background:'#e4e4e4', border:'solid 0px #000', color:'#000', fontSize:'14px', }}>
                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                </span>
                                <Input style={{ paddingLeft:'45px' }} maxLength={10} value={props.phoneNumber} placeholder="Phone Number" onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        props.updateState({
                                            phoneNumber: e.target.value,
                                        });
                                    }
                                }} />
                            </Col>
                                    <Col span={7}></Col>
                                    <Col span={17}>{donationSettings.isTransactionFeeApply && donationSettings.transactionFeePercent ?
                                        <div style={{ float: 'left', marginLeft: '21px',paddingBottom:'10px' }} className="new-ched">
                                            <Checkbox checked={(donationSettings.isTransactionFeeRequired === 1 || props.isTransactionFeeApplyByDefault === 1)} onChange={(e) => {
                                                props.updateState({
                                                    isTransactionFeeApplyByDefault: e.target.checked ? 1 : 0,
                                                })
                                            }} />  I'd like to help cover the transaction fee of {donationSettings.transactionFeePercent}%
                                        </div> : ''

                                    }</Col>
                                </Row>

                        <Row>
                            <Col span={3}></Col>
                            <Col span={18} className='culbs'>
                                <Button onClick={() => {
                                    let totalAmount = getTotalTicketPrice();
                                    if (!totalAmount) {
                                        return showToasterMessage({ description: 'Please enter amount' });
                                    }
                                    if (!props.firstName || !props.lastName) {
                                        return showToasterMessage({ description: 'Please enter First Name and Last Name' });
                                    }
                                    if (!props.email || !validateEmail(props.email)) {
                                        return showToasterMessage({ description: 'Please enter valid email' });
                                    }
                                    const modalData = {
                                        title: <React.Fragment>
                                            <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                                                {props.selectedSponsorship.name}
                                            </div>
                                        </React.Fragment>,
                                        modalBody: <CardSwipe {...props} isSponsorship={true} amount={totalAmount}/>,
                                        width: 600,
                                        hideFooter: true
                                    };
                                    props.pushModalToStack(modalData);

                                }}>Pay {getTotalTicketPrice()? '$'+getTotalTicketPrice() : ''}</Button>
                            </Col>
                            </Row>
                            </div>
                            </div>
                        </Col>
                      
                </Row>
            }
        </div>
    )

};

export default SponsorshipComponent;
