import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventListingComponent from "../../components/EventListing";
import { fetchRelationshipDataByRID, fetchRelationshipByDomainName, getEventByIds } from './action';
import { getAuthNetPaymentUrl, processCardPayment, emailReceipt } from '../Donation/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchDonationSetting } from '../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../static/constants';
import config from '../../config/environmentConfig';
import CryptoJS from "crypto-js";

class EventListing extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(props.location?.search) || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            source: linkProps.source,
            donationSettings: {},
            amount: 0,
            neverExpires: 1,
            duration: 'oneTime',
            paymentStartDate: new Date(),
            amountCursor: 0,
            relationshipBillingAddress: {},
            isSettingLoad: false
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location?.search) || {};

        const bytes = CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET);
        const load = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.props.fetchDonationSetting(load);
        this.props.fetchRelationshipDataByRID(load);
        //this.props.fetchRelationshipByDomainName({ domainName: window.location.host, source: linkProps.source || ''}, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.donationSettings && props.donationSettings.settingsDonationId && !this.state.isSettingLoad) {
            let donationContent = JSON.parse(props.donationSettings.donationContent);
            this.setState({
                relationshipId: props.donationSettings.relationshipId,
                settingsDonationId: props.donationSettings.settingsDonationId,
                donationSettings: donationContent,
                formHeading: donationContent.formHeading,
                isSettingLoad: true
            });
            this.props.getEventByIds({ relationshipId: props.donationSettings.relationshipId, alpideFormBuilderIdList: Object.keys(donationContent.selectedEvents || {}) || []}, this.props);
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
             {Object.keys(this.props.donationSettings).length ? <EventListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> : <div></div>} 
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.relationshipData,
        relationshipId: state.common.relationshipData?.relationshipId,
        donationSettings: state.donation.donationSettings,
        eventList: state.fundraising.eventList
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchRelationshipDataByRID,
    fetchRelationshipByDomainName,
    fetchDonationSetting,
    getAuthNetPaymentUrl,
    processCardPayment,
    emailReceipt,
    getEventByIds
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventListing);
