import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, FUNDRAISING_ACTION_LIST } from '../../static/constants'
//import { showToasterMessage } from '../../utils';

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.RELATIONSHIP_DATA, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId: data.relationshipId, source :payload.source }
        props.fetchDonationSetting(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        console.log(err, "payload")
        // const load = { relationshipId: 17, source :payload.source }
        // props.fetchDonationSetting(load);
        // props.fetchRelationshipDataByRID(load);
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


export const getAllFundraisingCampaign = (payload) => {
  return (dispatch) => {
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/getFundraisingCampaign?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0`)
      .then((res) => {
        const { data } = res
        dispatch({ type: FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST, data });
      })
      .catch((err) => {
        console.log(err, "payload")
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST;
        }
      })
  };
};