import axios from 'axios';
import config from '../config/environmentConfig';
import { MODAL_ACTIONS, DONATION_ACTION_LIST, COMMON_ACTIONS, CONSTANTS } from '../static/constants';

export const showModal = (data) => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.SHOW_MODAL, data });
  }
}
export const hideModal = () => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
  }
}

export const pushModalToStack = (modalData) => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.PUSH_STACK_MODAL, data: modalData });
  }
}

export const popModalFromStack = () => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });
  }
}

export const clearModalStack = () => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.CLEAR_STACK_MODAL });
  }
}

export const setModalFormData = (formData) => {
  return dispatch => {
    dispatch({ type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE, data: { formData } })
  }
}

export const fetchDonationSetting = (payload) => {
  return dispatch => {
    let uri = '/donationSettings/getDonationSettings';
    if (payload.source === 'kiosk') {
      uri = '/donationSettings/getDonationSettings';
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let source = payload.source === 'webDonation' ? 'webDonation' : 'kiosk';
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/donationSettings/getDonationSettings?relationshipId=${payload.relationshipId}&source=${source}`)
      .then(res => {
        const { data } = res
        dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING, data });

        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      })
      .catch((err) => {

        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = DONATION_ACTION_LIST.DONATION_SETTING;
        }
        })
    }
}

export const fetchCountries = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/country/getCountries`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.COUNTRIES_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const fetchPaymentTerms = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentTerm/getAllPaymentTerms?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.PAYMENT_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST;
        }
      })
  }
}

export const fetchStates = (countryId) => {
  if (Number(countryId) === CONSTANTS.INDIA_COUNTRY_ID) {
    countryId = 1;
  }

  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/states/getStates?countryId=${countryId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: res.data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: [] });
      })
  }
}


export const fetchPaymentModes = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/paymentMode/getAllPaymentModes?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.PAYMENT_MODE_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_MODE_LIST;
        }
      })
  }
}