import React, { Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button } from "antd";
import AlifLogo from '../../assets/images/alif-logo.png';
import config from '../../config/environmentConfig';
import { CONSTANTS } from '../../static/constants';
import { LogoComponent } from '../general/LogoComponent';
import { useHistory } from "react-router-dom";

const LeftPanel = (props) => {
    const history = useHistory();
    const getAddressInfoFragment = (obj={}, additionInfo) => {
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1 + ' '}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            <span> {` ${obj.cityName || ''} ${obj.stateName ? ', ' + obj.stateName : ''} ${obj.zipCode ? ', ' + obj.zipCode : ''}`}</span>
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.phoneNumber
                    ? <div className='billing-address'>
                        Ph - {additionInfo.phoneNumber}
                    </div> :
                    ''
            }
            {/* {
                additionInfo.faxNumber
                    ? <div className='billing-address'>
                        Fax - {additionInfo.faxNumber}
                    </div> :
                    ''
            } */}
            {
                additionInfo.website
                    ? <div className='billing-address'>
                        Website - {additionInfo.website}
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }
    return (
        <div className="dark-blue" >

            <div className='main-container '>
                <Row gutter={23} style={{ textAlign: 'center' }}>
                    <Col span={23} style={{ padding: '12px' }} >
                        <div className="brand-logo" style={{ cursor: 'pointer' }} onClick={() => {
                            props.updateState({ selectedMenu: '' });
                            history.replace({
                                pathname: '/donate',
                                //state: { source: props.source },
                               // search: props.source ? '?source=' + props.source : ''
                               state: { source: props.source, token: props.token },
                               search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                            });
                        }}>
                            {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                        </div>
                    </Col>
                    <Col span={23}  >
                        {/* <div className="but-spane"> <b>{props.companyInfo.storeName} </b></div> */}
                    </Col>
                    <div className='text-middle'>
                    {props.donationContent?.isShowDonationMenu ?
                        <Col span={23}  >
                            <Button className={props.selectedMenu === 'Donate' ? 'left-menu-active' : ''} onClick={() => {
                                props.updateState({ selectedMenu: 'Donate' });
                                history.replace({
                                    pathname: '/donation',
                                    //state: { source: props.source },
                                   // search: props.source ? '?source=' + props.source : ''
                                   state: { source: props.source, token: props.token },
                                   search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                                });
                            }}>Donate</Button>
                        </Col>
                        : ''}
                    {/* {props.donationContent?.isShowFeePaymentMenu ? <Col span={23} >
                        <Button className={props.selectedMenu === 'fee-payment' ? 'left-menu-active' : ''} onClick={() => {
                            props.updateState({ selectedMenu: 'fee-payment' });
                        }}>Fee Payment</Button>
                    </Col>
                        : ''} */}
                    {props.donationContent?.isShowOpenEventMenu ? <Col span={23} >
                        <Button className={props.selectedMenu === 'open-events' ? 'left-menu-active' : ''} onClick={() => {
                            props.updateState({ selectedMenu: 'open-events' });
                            history.replace({
                                pathname: '/open-events',
                                //state: { source: props.source },
                               // search: props.source ? '?source=' + props.source : ''
                               state: { source: props.source, token: props.token },
                               search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                            });
                        }}>Event</Button>
                    </Col>
                        : ''}
                    {props.donationContent?.isShowRegistrationMenu ? <Col span={23} >
                        <Button className={props.selectedMenu === 'register' ? 'left-menu-active' : ''} onClick={() => {
                            props.updateState({ selectedMenu: 'register' });
                            history.replace({
                                pathname: '/register-member',
                                //state: { source: props.source },
                               // search: props.source ? '?source=' + props.source : ''
                               state: { source: props.source, token: props.token },
                               search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                            });
                        }}>Registration</Button>
                    </Col>
                        : ''}
                    {props.donationContent?.isShowFundRaising ? <Col span={23} >
                        <Button className={props.selectedMenu === 'fundRaising' ? 'left-menu-active' : ''} onClick={() => {
                            props.updateState({ selectedMenu: 'fundRaising', selectedCampaign: null });
                                history.replace({
                                    pathname: '/fundRaising',
                                    //state: { source: props.source },
                                   // search: props.source ? '?source=' + props.source : ''
                                   state: { source: props.source, token: props.token },
                                   search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                                });
                                props.selectCampaign();
                        }}>Fundraising</Button>
                    </Col>
                        : ''}
                        {props.donationContent?.isShowSubscribe ? <Col span={23} >
                            <Button className={props.selectedMenu === 'subscribe' ? 'left-menu-active' : ''} onClick={() => {
                                props.updateState({ selectedMenu: 'subscribe' });
                                history.replace({
                                    pathname: '/subscribe',
                                    //state: { source: props.source },
                                   // search: props.source ? '?source=' + props.source : ''
                                   state: { source: props.source, token: props.token },
                                   search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                                });
                            }}>Subscribe</Button>
                        </Col>
                            : ''}
                        {props.donationContent?.isShowPledgePaymentMenu ? <Col span={23} >
                            <Button className={props.selectedMenu === 'pledgePayment' ? 'left-menu-active' : ''} onClick={() => {
                                props.updateState({ selectedMenu: 'pledgePayment' });
                                history.replace({
                                    pathname: '/pledgePayment',
                                    //state: { source: props.source },
                                   // search: props.source ? '?source=' + props.source : ''
                                   state: { source: props.source, token: props.token },
                                   search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                                });
                                //  props.selectPledge();
                                //  props.emailVerified(false);
                                  props.resetPaymentPledgeState();
                            }}>Pledge Payment</Button>
                        </Col>
                            : ''}
                            {props.donationContent?.isShowSponsorshipMenu ? <Col span={23} >
                        <Button className={props.selectedMenu === 'sponsorship' ? 'left-menu-active' : ''} onClick={() => {
                            props.updateState({ selectedMenu: 'sponsorship', selectedSponsorship: null });
                                history.replace({
                                    pathname: '/sponsorship',
                                    //state: { source: props.source },
                                   // search: props.source ? '?source=' + props.source : ''
                                   state: { source: props.source, token: props.token },
                                   search: props.token  ? '?token=' + encodeURIComponent(props.token) : ''
                                });
                                props.selectSponsorship();
                        }}>Sponsorship</Button>
                    </Col>
                        : ''}
                    <Col span={23} style={{ padding: '12px' }}  >

                    </Col>
                    </div>
                </Row>

            </div>
            <div className="buttom-logo"> <div>
            <div className="but-spane"> <b>{props.companyInfo.storeName} </b></div>
                <div style={{ color: 'white', textAlign: 'center' }}>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </div>
                {/* <img src={AlpideLogo} alt="" style={{ width: "100px" }} /></div> */}
                <div className="butt-lm"><img src={AlifLogo} alt="" /> AlifApp - One Masjid One Software <br />
                    <span>info@alifapp.com </span> | 980-856-9966<br />
                    <span> www.alifapp.com</span>
                </div>
                {/* <div className="copyright-footer">Powered by AlifApp (alifapp.com)</div> */}
            </div>
            </div>
        </div>

    )

};

export default LeftPanel;
