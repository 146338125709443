import React from 'react';
import { Route, withRouter } from 'react-router-dom';

export function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  props.history.renderedLocation = props.location.pathname
  return (
    <Route
      {...rest}
      render={() =>
        <Component {...rest} />
      }
    />
  );
}

export default withRouter(PrivateRoute);