import { MODAL_ACTIONS } from './../static/constants';

const initialState = {
  title: '',
  visible: false,
  modalBody: '',
  modalBodyType: 'Modal',
  formData: {},
  footer: {
    cancel: {
      intlId: 'confirmation.cancel',
      defaultMessage: '',
      type: 'default',
    },
    submit: {
      intlId: 'confirmation.submit',
      defaultMessage: '',
    }
  },
  handleSubmit: () => { }
}

const stackModalsInitialState = {
  modalStack: []
};

export const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ACTIONS.SHOW_MODAL: {
      return { ...state, ...action.data, visible: true };
    }
    case MODAL_ACTIONS.HIDE_MODAL: {
      return { ...initialState };
    }
    case MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE: {
      return { ...state, ...action.data }
    }
    default:
      return state;
  }
};

export const StackModalReducer = (state = stackModalsInitialState, action) => {
  switch (action.type) {
    case MODAL_ACTIONS.PUSH_STACK_MODAL: {
      state.modalStack.push(action.data);
      state.modalStack = [...(state.modalStack)];
      return { ...state }
    }
    case MODAL_ACTIONS.POP_STACK_MODAL: {
      state.modalStack.pop();
      state.modalStack = [...(state.modalStack)];
      stackModalsInitialState.modalStack = state.modalStack;
      return { ...state }
    }
    case MODAL_ACTIONS.CLEAR_STACK_MODAL: {
      return { ...stackModalsInitialState }
    }
    default:
      return stackModalsInitialState;
  }
}