import { FUNDRAISING_ACTION_LIST } from '../static/constants';
const initialState = {
  campaignList: [],
  eventList: [],
  selectedCampaign: null,
  donorCount: 0,
  pledgePayments: [],
  pledgePaymentState: {
    selectedPledge: {},
    isEmailVerified: false,
    isEmailSent: false,
    emailVerification: {},
    email: '',
    otp: ''
  },
  selectedSponsorship: null,
  sponsorshipList: []
};

const DonationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.data,
      };
    case FUNDRAISING_ACTION_LIST.EVENT_LIST:
      return {
        ...state,
        eventList: action.data,
      };
    case FUNDRAISING_ACTION_LIST.SELECTED_CAMPAIGN:
      return { ...state, selectedCampaign: action.data };
    case FUNDRAISING_ACTION_LIST.DONOR_COUNT:
      return {
        ...state,
        donorCount: action.data,
      };
    case FUNDRAISING_ACTION_LIST.PLEDGE_PAYMENT:
      return { ...state, pledgePayments: action.data };
    case FUNDRAISING_ACTION_LIST.RESET_PLEDGE_PAYMENT:
      return { ...state, pledgePayments: initialState.pledgePayments };
    case FUNDRAISING_ACTION_LIST.EMAIL_VERIFICATION:
      return { ...state, pledgePaymentState: {...state.pledgePaymentState, emailVerification: action.data }};
    case FUNDRAISING_ACTION_LIST.RESET_EMAIL_VERIFICATION:
      return { ...state, pledgePaymentState: { ...state.pledgePaymentState, emailVerification: {} } };
    case FUNDRAISING_ACTION_LIST.SELECTED_PLEDGE:
      return { ...state, pledgePaymentState: { ...state.pledgePaymentState, selectedPledge: action.data } };
    case FUNDRAISING_ACTION_LIST.IS_EMAIL_VERIFIED:
      return { ...state, pledgePaymentState: { ...state.pledgePaymentState, isEmailVerified: action.data } };
    case FUNDRAISING_ACTION_LIST.IS_EMAIL_SENT:
      return { ...state, pledgePaymentState: { ...state.pledgePaymentState, isEmailSent: action.data } };
    case FUNDRAISING_ACTION_LIST.SET_EMAIL:
      return { ...state, pledgePaymentState: { ...state.pledgePaymentState, email: action.data } };
    case FUNDRAISING_ACTION_LIST.RESET_PLEDGE_PAYMENT_STATE:
      return { ...state, pledgePaymentState: initialState.pledgePaymentState };
    case FUNDRAISING_ACTION_LIST.SELECTED_SPONSORSHIP:
      return { ...state, selectedSponsorship: action.data };
    case FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST:
      return {
        ...state,
        sponsorshipList: action.data,
      };  
    default:
      return state;
  }
};

export default DonationReducer;