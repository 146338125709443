import React from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col, Button, Carousel, InputNumber,Input, Checkbox, DatePicker } from "antd";
//import { useHistory } from "react-router-dom";
import '../../assets/stylesheets/style.scss';
import CardSwipe from '../../containers/modal/modalBody/common/CardSwipe';
//import img1 from '../../assets/images/DonationSlider/slide1.jpg';
import img2 from '../../assets/images/DonationSlider/slide2.jpg';
import img3 from '../../assets/images/DonationSlider/slide3.jpg';
//import img4 from '../../assets/images/DonationSlider/slide4.jpg';
//import img5 from '../../assets/images/DonationSlider/slide5.jpg';
//import img6 from '../../assets/images/DonationSlider/slide6.jpg';
import img7 from '../../assets/images/DonationSlider/slide7.jpg';
import img8 from '../../assets/images/DonationSlider/slide8.jpg';
import img9 from '../../assets/images/DonationSlider/slide9.jpg';
import img10 from '../../assets/images/DonationSlider/slide10.jpg';
import img11 from '../../assets/images/DonationSlider/slide11.jpg';
import img12 from '../../assets/images/DonationSlider/slide12.jpg';
import img13 from '../../assets/images/DonationSlider/slide13.jpg';
//import img14 from '../../assets/images/DonationSlider/slide14.jpg';
//import img15 from '../../assets/images/DonationSlider/slide15.jpg';
import img16 from '../../assets/images/DonationSlider/slide16.jpg';
import { showToasterMessage, getMomentDateForUIReadOnly, validateEmail, sortByKey } from '../../utils';
import { CONSTANTS } from '../../static/constants';
import moment from 'moment-timezone';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TextArea } = Input;

const DonationComponent = (props) => {
    const { donationSettings = {} } = props;
    const contentStyle = {
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
               
      };
    const insertNumber = (props, val) => {
        if (props.amount) {
            let amount = props.amount.slice(0, props.amountCursor) + val + props.amount.slice(props.amountCursor);
            props.updateState({ amount: amount, amountCursor: props.amountCursor +val.length })
        } else {
            props.updateState({ amount: val, amountCursor: props.amountCursor + val.length })
        }
    }

    const proccessPayment = () => {
        
        let payload = {
            subscriptionName: props.selectedProject.projectName,
            projectMasterId: props.selectedProject.projectMasterId,
            projectName: props.selectedProject.projectName,
            startDate: getMomentDateForUIReadOnly({ date: props.paymentStartDate || new Date, format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
            totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
            alpidePaymentGatewayId: props.selectedProject.paymentGatewayId,
            remarks: props.remarks,
        }

        if (props.duration === 'weekly') {
            payload.intervalLength = 7;
            //payload.unit = 'days';
          } else if (props.duration === 'monthly') {
            payload.intervalLength = 30;
            //payload.unit = 'months';
          } else if (props.duration === 'yearly') {
            payload.intervalLength = 365;
           // payload.unit = 'months';
          }
        props.getAuthNetPaymentUrl(props, payload)
       
    }

    return (
        <div className='main-container '>
            <Row style={{ textAlign: 'left' }}>
                <Col className="dark-ins" span={12}>
                <span className="medl-hed">
                            Where do you want to donate?
                        </span>
                    <ul>
                        
                        {
                             (sortByKey(Object.values(donationSettings.selectedProjects || {}) || [], 'sNo')).map((project, i) => {
                                return (
                                    <li key={i + 1} className={project.projectId === props.selectedProject?.projectId ? 'active-prject' : ''} style={{ fontSize: '20px' }} onClick={() => {
                                        props.updateState({
                                            selectedProject: project,
                                            amount: 0,
                                            neverExpires: 1,
                                            duration: 'oneTime',
                                            paymentStartDate: new Date(),
                                            amountCursor: 0,
                                            firstName: '',
                                            lastName: '',
                                        })
                                    }}>
                                        {/* <Radio checked={project.projectId === props.selectedProject?.projectId} /> */}
                                        {project.displayName || ''}
                                        {/* <Divider></Divider> */}
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="clearfix"></div>
                </Col>
                { (props.selectedProject && props.selectedProject.projectId) ? 
                <Col span={12} className="calco">
                    <Row>
                        <Col span={24} className="top-heade">
                            
                            Select an Amount to Donate
                                
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2}></Col>
                        <Col span={5}  className='culb'>
                            <Button className={props.amount === '20' ? 'ant-btn-primary' : 'ant-btn-default'}  onClick={() => {
                                props.updateState({ amount: '20', amountCursor: 2 });
                            }}><span className='doloers'>$</span> 20</Button>
                        </Col>
                        <Col span={5} className='culb'>
                            <Button className={props.amount === '50' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                               props.updateState({ amount: '50', amountCursor: 2 });
                            }}><span className='doloers'>$</span> 50</Button>
                        </Col>
                        <Col span={5} className='culb'>
                            <Button className={props.amount === '100' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                props.updateState({ amount: '100', amountCursor: 3 });
                            }}> <span className='doloers'>$</span> 100</Button>
                        </Col>
                        <Col span={5} className='culb'>
                            <Button className={props.amount === '200' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                props.updateState({ amount: '200', amountCursor: 3 });
                            }}><span className='doloers'>$</span> 200</Button>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                    <Row>
                        <Col span={24} className="top-heade">
                            or enter your amount
                    </Col>
                    </Row>

                    <Row>
                        <Col span={3}></Col>
                            <Col span={18} >
                                <Input
                                    className='mart'
                                    value={props.amount}
                                    disabled
                                    onClick={(e) => {
                                        props.updateState({ amountCursor: e.target.selectionStart });
                                    }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                                        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
                                            props.updateState({
                                                amount: value,
                                                amountCursor: e.target.selectionStart,
                                            });
                                        }
                                    }}
                                ></Input>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={3}></Col>
                            <Col span={18}>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '1');
                                        //  props.updateState({ amount: (props.amount + '1') });
                                    }}>1</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '2');
                                        // props.updateState({ amount: (props.amount + '2') });
                                    }}>2</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '3');
                                    }}>3</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '4');
                                    }}>4</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '5');
                                    }}>5</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '6');
                                    }}>6</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '7');
                                    }}>7</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '8');
                                    }}>8</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '9');
                                    }}>9</span></Col>
                                </Row>
                                <Row>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        if (props.amount && props.amount.indexOf('.') < 0) {
                                            let amount = props.amount.slice(0, props.amountCursor) + '.' + props.amount.slice(props.amountCursor);
                                            props.updateState({ amount: amount, amountCursor: props.amountCursor + 1 })
                                        } else if (!props.amount) {
                                            props.updateState({ amount: '0.', amountCursor: props.amountCursor + 2 })
                                        }
                                    }}><b>.</b></span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        insertNumber(props, '0');
                                    }}>0</span></Col>
                                    <Col span={8} className='cul-text'><span onClick={() => {
                                        let num = props.amount;
                                        props.updateState({ amount: num.substring(0, num.length - 1) });
                                    }}>
                                        <div className="back-arow"></div>
                                    </span></Col>
                                </Row>
                            </Col>
                    </Row>

                    <Row>
                        <Col span={2}></Col>
                        <Col span={19}>
                            <Row>
                                <Col span={8} className='culbb'>
                                    <Button className={props.duration === 'oneTime' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ duration: 'oneTime' });
                                    }}>One-Time</Button>
                                </Col>

                                {/* <Col span={5} className='culbb'>
                                    <Button className={props.duration === 'daily' ? 'ant-btn-primary' : 'ant-btn-default'}  onClick={() => {
                                        props.updateState({ duration: 'daily' });
                                    }}>Daily</Button>
                                </Col> */}
                                <Col span={8} className='culbb'>
                                    <Button className={props.duration === 'weekly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ duration: 'weekly' });
                                    }}>Weekly</Button>
                                </Col>
                                <Col span={8} className='culbb'>
                                    <Button className={props.duration === 'monthly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
                                        props.updateState({ duration: 'monthly' });
                                    }}> Monthly
                                    </Button>
                                </Col>
                                </Row>
                                {donationSettings.isTransactionFeeApply && donationSettings.transactionFeePercent ?
                                    <div style={{ float: 'left', marginLeft: '21px',paddingBottom:'10px' }} className="new-ched">
                                        <Checkbox checked={(donationSettings.isTransactionFeeRequired === 1 || props.isTransactionFeeApplyByDefault === 1)} onChange={(e) => {
                                            props.updateState({
                                                isTransactionFeeApplyByDefault: e.target.checked ? 1 : 0,
                                            })
                                        }} />  I'd like to help cover the transaction fee of {donationSettings.transactionFeePercent}%
                                    </div> : ''
                                    
                                }
                                <div style={{clear:'both'}}></div>
                                {props.duration && props.duration !== 'oneTime' ?
                                    <Row style={{paddingTop:'10px'}}>
                                        <Col span={10} className="noty">
                                            No. of Payment(s)
                                </Col>
                                        <Col span={14} className="new-ched">
                                            {!props.neverExpires && <div style={{float:'left'}}>
                                                <InputNumber className=' bity' style={{
                                                    width: '150', background: 'initial', fontSize: '16px', borderBottom: 'solid 1px #cecece',
                                                    marginBottom: '15px'
                                                }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                    props.updateState({ noOfPayment: value })
                                                }} /></div>
                                            }
                                            <div style={{ float: 'left', marginLeft: '10px' }}>
                                                <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                    props.updateState({
                                                        neverExpires: e.target.checked ? 1 : 0,
                                                    })
                                                }} /> Never Expires
                                            </div>
                                </Col>
                                        <Col span={10} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Next Payment Date
                                </Col>
                                        <Col span={14} className="new-ched">
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                style={{ width: 180 }}
                                                // disabledDate={invalidEndDate}
                                                key={moment(new Date(), format)}
                                                defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        paymentStartDate: selectedDate
                                                    })
                                                }} />
                                        </Col>

                                       
                                    </Row> : ''}

                                {/* {donationSettings.isDisplayNameOrEmail ? */}
                                    <Row>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Name
                                        </Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.firstName} placeholder="First Name" onChange={(e) => {
                                                props.updateState({ firstName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8} className="new-ched">
                                            <Input style={{
                                                width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'
                                            }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
                                                props.updateState({ lastName: e.target.value })
                                            }} />
                                        </Col>
                                        <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
                                            Email
                                        </Col>
                                        <Col span={17} className="new-ched">
                                            <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px' }} value={props.email} placeholder="Email" onChange={(e) => {
                                                props.updateState({ email: e.target.value })
                                            }} />
                                        </Col>
                                    </Row> 
                                {/* } */}
                            </Col>
                        </Row>

                        <Row>
                            {/* <Col span={4} style={{padding: '10px'}}>Memo Text</Col> */}
                            <Col span={3}></Col>
                            <Col span={18} >
                                <TextArea
                                className='martm'
                                placeholder="Enter Remarks"
                                    value={props.remarks}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        props.updateState({
                                            remarks: value,
                                        });
                                    }}
                                    autoSize={{ minRows: 1, maxRows: 2 }}
                                ></TextArea>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={3}></Col>
                            <Col span={18} className='culbs'>
                                <Button onClick={() => {
                                     if (!props.amount || !Number(props.amount)) {
                                        return showToasterMessage({ description: 'Please enter amount' });
                                    }
                                    if (!props.duration) {
                                        return showToasterMessage({ description:"Please select a payment duration"});
                                    }
                                    if (!props.neverExpires && !props.noOfPayment) {
                                        return showToasterMessage({ description: 'Please enter No. of Payment' });
                                    }
                                    //if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.firstName || !props.lastName)) {
                                    if (props.duration && props.duration !== 'oneTime' && (!props.firstName || !props.lastName)) {
                                        return showToasterMessage({ description: "Please enter first name or last name" });
                                    }
                                    // if (donationSettings.isDisplayNameOrEmail && donationSettings.isRequiredNameOrEmail && (!props.email || !validateEmail(props.email))) {
                                    if (props.duration && props.duration !== 'oneTime' && (!props.email || !validateEmail(props.email))) {
                                        return showToasterMessage({ description: "Please enter a valid email" });
                                    }
                                    if (props.source === 'kiosk') {
                                        const modalData = {
                                            title: <React.Fragment>
                                                <div className='ant-modal-title' style={{ textAlign: 'center' }}>
                                                    {props.selectedProject.displayName || 'Donate'}
                                                </div>
                                            </React.Fragment>,
                                            modalBody: <CardSwipe {...props} />,
                                            width: 600,
                                            hideFooter: true
                                        };
                                        props.pushModalToStack(modalData);
                                    } else {
                                        proccessPayment();
                                    }
                                }}>Donate Now</Button>
                            </Col>
                        </Row>
                </Col>
                :
                    <Col span={12}>
                        <Carousel autoplay>
                            {/* <div><img src={img1} alt="" style={contentStyle}/></div> */}
                            <div><img src={img2} alt="" style={contentStyle}/></div>
                            <div><img src={img3} alt="" style={contentStyle} /> </div>
                            {/* <div><img src={img4} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img5} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img6} alt="" style={contentStyle} /> </div> */}
                            <div><img src={img7} alt="" style={contentStyle} /> </div>
                            <div><img src={img8} alt="" style={contentStyle} /> </div>
                            <div><img src={img9} alt="" style={contentStyle} /> </div>
                            <div><img src={img10} alt="" style={contentStyle} /> </div>
                            <div><img src={img11} alt="" style={contentStyle} /> </div>
                            <div><img src={img12} alt="" style={contentStyle} /> </div>
                            <div><img src={img13} alt="" style={contentStyle} /> </div>
                            {/* <div><img src={img14} alt="" style={contentStyle} /> </div> */}
                            {/* <div><img src={img15} alt="" style={contentStyle} /> </div> */}
                            <div><img src={img16} alt="" style={contentStyle} /> </div>
                        </Carousel>
                    </Col> 
}
            </Row>
        </div>
    )

};

export default DonationComponent;
