const configuration = {
  API_BASE_URL: 'http://localhost:8080',
 API_VERSION: '',
 AWS_BASE_URL:'http://localhost:8080',
 PDF_API_BASE_URL: 'http://localhost:8000/api/',
 PDF_API_VERSION: 'v1',
 REDIRECT_URI_FOR_AMAZON: 'https://uat.alpide.com/app/admin/sales-channels',
 rootContext: 'alpide',
 rootContextInvoiceService: 'alpide-invoice',
 INVOICE_SERVICE_BASE_URL: 'http://localhost:8080',
 rootContextPaymentGateway: 'alpide-paypal',
 PAYMENT_GATEWAY_BASE_URL: 'http://localhost:8080',
 rootContextPlaid: 'alpide-plaid',
 rootContextMWS: 'alpide-mws',
 MWS_BASE_URL: 'http://localhost:8080',
 PLAID_BASE_URL: 'http://localhost:8080',
 UI_BASE_URL: 'http://localhost:3000/app/',
 CALLBACK_URI_AUTH_NET: 'http://localhost:3000/app/admin/payment-integration',
 PAYMENT_SERVER_BASE_URL: 'https://uat-app.alpide.com',
 CALLBACK_URI_MARKTPLACE: 'http://localhost:3000/app/admin/sales-channels',
 ENCRYPT_SECRET: 'Unl0ck!!!',
 BUCKET_NAME: {
  BO_RELATIONSHIP: 'relationship-p',
  BO_SALES_INQUIRY: "inquiry-p",
  BO_SALES_QUOTE: "sales-quote-p",
  BO_SALES_ORDER: 'sales-order-p',
  BO_CUSTOMER_INVOICE: "sales-invoice-p",
  BO_CREDIT_MEMO: "credit-memo-p",
  BO_PURCHASE_REQUEST: "purchase-request-p",
  BO_RFQ: "supplier-rfq-p",
  BO_PURCHASE_ORDER: "purchase-order-p",
  BO_INBOUND_DELIVERY: "inbound-delivery-p",
  BO_SUPPLIER_INVOICE: "purchase-invoice-p",
  BO_DEBIT_MEMO: "debit-memo-p",
  BO_SAMPLE_RESOURCES: "sample-resources-p",
 }
};

export default configuration;
