import React from 'react';
import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, lang} from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';


export const saveOrUpdate = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/pledge/saveOrUpdate`,
        requestObj,
      )
      .then(res => {
        showToasterMessage({
          description: 'Saved successfully.',
          messageType: 'success'
        });
        props && props.popModalFromStack();
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}