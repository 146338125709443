import React from "react";
import { Row, Col, Input, Tooltip } from "antd";
import './index.scss';
import '../../assets/stylesheets/style.scss';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS } from '../../static/constants';
import { showToasterMessage, validateEmail } from '../../utils';
import filter from "lodash.filter";
import { MultipleSelectDropdown } from '../general/MultipleSelectDropdown';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
//const { TextArea } = Input;
//const { Option } = Select;

const EventListingComponent = (props) => {
    const { donationSettings = {} } = props;

    return (
        <div className='main-container '>
            <Row style={{ textAlign: 'left' }}>
                <Col className="dark-ins" span={24}>

                    <span className="medl-hed">
                        Subscribe
                    </span>
                    {donationSettings.selectedSubscription ? <div>
                        <Row style={{ fontSize: '20px', padding:'20px 0px 0px' }} className="subs ptuy">
                            <Col span={24}>
                                {ReactHtmlParser(donationSettings.subscribeHeaderMessage)}
                            </Col>
                        </Row>
                        <Row style={{ fontSize: '20px' }} className="subs">
                            <Col span={2}></Col>
                            <Col span={5} style={{textAlign:'right'}}>
                                Name
                            </Col>
                            <Col span={5}>
                                <Input style={{ width: '100%' }} value={props.firstName} placeholder="First Name" onChange={(e) => {
                                    props.updateState({ firstName: e.target.value })
                                }} />
                            </Col>
                            <Col span={5}>
                                <Input style={{ width: '100%' }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
                                    props.updateState({ lastName: e.target.value })
                                }} />
                            </Col>
                        </Row>

                      

                        <Row style={{ fontSize: '20px' }} className="subs">
                            <Col span={2}></Col>
                            <Col span={5} style={{textAlign:'right'}}>
                                Email
                            </Col>
                            <Col span={10}>
                                <Input style={{ width: '100%' }} value={props.email} placeholder="Email Address" onChange={(e) => {
                                    props.updateState({ email: e.target.value })
                                }} />
                            </Col>
                        </Row>

                        <Row style={{ fontSize: '20px', paddingBottom: ' 8px' }} className="subs newregis">
                            <Col span={2}></Col>
                            <Col span={5} style={{ textAlign: 'right' }}>
                                <span>Phone Number </span>
                                <Tooltip placement="top" title='Phone number must be number and 10 digit without space' trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </Col>
                            <Col span={10} className="input-currency-control">
                                <span className="currency-tag" style={{ paddingTop: '6px', marginLeft: '25px', height: '37px', background:'#545457', border:'solid 0px #000', color:'#d5d5d5', fontSize:'14px',  left:'1px', top:'1px' }}>
                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                </span>
                                <Input style={{ width: '100%' }} maxLength={10} value={props.phoneNumber} placeholder="Phone Number" onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        props.updateState({
                                            phoneNumber: e.target.value,
                                        });
                                    }
                                }} />
                            </Col>
                        </Row>
                        <Row style={{ fontSize: '20px' }} className="subs">
                            <Col span={2}></Col>
                            <Col span={5} style={{textAlign:'right'}}>
                                Subscribe me for
                            </Col>
                            <Col span={10} className="dropsleft">
                                <MultipleSelectDropdown
                                    style={{ paddingLeft: '0px' }}
                                    items={Object.values(donationSettings.selectedSubscription) || []}
                                    placeholder="Please Select"
                                    mode='multiple'
                                    onDeSelect={(value) => {
                                        let { selectedDgGroups } = props
                                        selectedDgGroups = filter(selectedDgGroups, (distributionGroupId) => {
                                            return distributionGroupId !== value;
                                        });
                                        props.updateState({ selectedDgGroups });
                                    }}
                                    valueKeyName='displayName'
                                    selectedValue={props.selectedDgGroups || []}
                                    optionKeyName='distributionGroupId'
                                    onSelect={(distributionGroupId) => {
                                        let selectedDgGroups = props.selectedDgGroups || [];
                                        if (selectedDgGroups.indexOf(distributionGroupId) === -1) {
                                            selectedDgGroups.push(distributionGroupId);
                                        } else {
                                            selectedDgGroups = selectedDgGroups.filter((el) => {
                                                return el !== distributionGroupId
                                            });
                                        }
                                        props.updateState({
                                            selectedDgGroups
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        
                        <br />
                        <Row>
                            <Col span={10}></Col>
                            <Col span={2}>
                                <button type='primary' className="done-close" onClick={() => {
                                    if (!props.selectedDgGroups || !props.selectedDgGroups.length) {
                                        return showToasterMessage({ description: 'Please select atleat one subcription' });
                                    }
                                    if (!props.email) {
                                        return showToasterMessage({ description: 'Please Enter Email' });
                                    };
                                    if (!props.firstName) {
                                        return showToasterMessage({ description: 'Please Enter Name' });
                                    }
                                    if (props.email && !validateEmail(props.email)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Incorrect Email'
                                        });;
                                    }
                                    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10 ) {
                                        return showToasterMessage({
                                            messageType: 'error', description: 'Phone number must be number and 10 digit without space'
                                        });
                                    }
                                    let payload = {
                                        relationshipId: props.relationshipId,
                                        distributionGroupIds: props.selectedDgGroups,
                                        emailAddress: props.email,
                                        firstName: props.firstName,
                                        lastName: props.lastName,
                                        cellPhone: (props.phoneNumber && props.companyInfo.countryCallingCode) ? '+' + props.companyInfo.countryCallingCode + props.phoneNumber : props.phoneNumber,
                                    };
                                    props.subscribeUser(payload, props);
                                }
                                }>

                                    Submit</button>
                            </Col>
                        </Row>
                    </div> : ''
                    }
                    <div className="clearfix"></div>
                </Col>
            </Row>

        </div>
    )

};

export default EventListingComponent;
