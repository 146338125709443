import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterComponent from '../../../../components/modal/modalBody/Register';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates, fetchPaymentTerms } from '../../../../actions/commonActions';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../../static/constants';
import { fetchRegistrationFormSetting, saveRegistration, getTotalStudentcCount, getTotalStudentcCountByGrade, fetchStudentFatherEmail, saveRegistrationFound} from './action';
import { fetchRelationshipDataByRID, fetchRelationshipByDomainName } from '../../../Donation/action';


class Register extends Component {
  constructor(props) {
      super(props);
      console.log("props props props ", props);
      const linkProps = queryString.parse(props.location?.search) || {};
      this.state = {
          formSetting: {},
          status: linkProps.status,
          relationshipId: linkProps.relationshipId,
          relationshipBillingAddress: {},
          registrationFormSettingId: props.registrationFormSettingId
      }
  }
  componentDidMount() {
      const linkProps = queryString.parse(this.props.location.search) || {};
      console.log("ddddddddddddddddddddd  ", linkProps);
      //this.props.fetchRelationshipByDomainName({ domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
     this.state.registrationFormSettingId &&  this.props.fetchRegistrationFormSetting({relationshipId: this.props.relationshipId, registrationFormSettingId: this.state.registrationFormSettingId}, this.props);
  }

  componentWillReceiveProps(props) {
      if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
          this.setState({
              successMessage: props.registrationFormSetting.successMessage,
              relationshipId: props.registrationFormSetting.relationshipId,
              registrationFormSettingId: props.registrationFormSetting.registrationFormSettingId,
              formSetting: JSON.parse(props.registrationFormSetting.formSetting),
              programName: props.registrationFormSetting.programName,
              status: props.registrationFormSetting.isFormActive ? undefined: 'inactive',
              registrationType: props.registrationFormSetting.registrationType,
          })
      }
      if (props.companyInfo && props.companyInfo.relationshipId) {
          const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
          const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
          this.setState({
              relationshipBillingAddress,
          })
      }
  }
  render() {
      window.document.title= this.props?.companyInfo?.storeName || '';
      const { common: { isLoading= false } } = this.props;
      const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
      return (
        
        <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={isLoading} >
        {Object.keys(this.props.registrationFormSetting).length ? <RegisterComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> : <div></div>}
        </Spin>
      )
  }
}
const mapStateToProps = (state) => {
  return {
      registrationFormSetting: state.registration.registrationFormSetting,
      countries: state.common.countries, 
      states: state.common.states,
      companyInfo: state.common.relationshipData,
      totalStudentCount: state.registration.totalStudentCount,
      totalStudentCountByGrade: state.registration.totalStudentCountByGrade,
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveRegistration,
  fetchRegistrationFormSetting,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCountries,
  fetchStates,
  fetchPaymentTerms,
  fetchRelationshipDataByRID,
  getTotalStudentcCount,
  getTotalStudentcCountByGrade,
  fetchRelationshipByDomainName,
  fetchStudentFatherEmail,
  saveRegistrationFound
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);

