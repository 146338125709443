let environment = (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV).toString().trim().toLowerCase();
switch (environment) {
  // case 'production':
  //   environment = 'production';
  //   break;
  case 'qa2':
    environment = 'qa2';
    break;
  case 'uat':
    environment = 'uat';
    break;
  case 'qa':
    environment = 'qa';
    break;
  case 'prod-us':
    environment = 'production-us';
    break;
  case 'prod-in':
    environment = 'production-in';
    break;
  default:
    break;
}

const appConfig = window.configuration ? window.configuration : require('./' + environment + '.js');

export default window.configuration ? appConfig : appConfig.default;